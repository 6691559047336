import Planned from './Planned';
import Realtime from './Realtime';
import TabsMenu from 'src/components/Menus/TabsMenu';
import { createUseStyles } from 'react-jss';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    position: 'sticky',
    top: '-20px',
    zIndex: theme.zIndex.tabsWrapper,
    '& > div': {
      width: '100%',
      backgroundColor: theme.colors.white,
    },
  },
}));

const Attendance = () => {

  const { t } = useTranslation();
  const classes = useStyles({});
  
  const [viewMode, setViewMode] = useState("realtime");

  const tabsItems: any = [
    {
      name: t('attendance_realtime'),
      value: 'realtime',
      isEnabled: true,
    },
    {
      name: t('attendance_planned'),
      value: 'planned',
      isEnabled: true,
    },
  ];
  
  return (
    <>
      <div className={classes.tabsWrapper}>
        <TabsMenu items={tabsItems} selected={viewMode} onSelect={setViewMode}/>
      </div>
      {
        viewMode === "realtime" ? (
          <Realtime/>
        ) : viewMode === "planned" ? (
          <Planned/>
        ) : null
      }
    </>
  );
};

export default Attendance;