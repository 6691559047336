import HttpService from './http.service';

const getUserData = (language?: string) => {
  return HttpService.get('v2', 'user', {customLanguage: language || undefined}, {});
};

const getUserBearerData = (bearer: string) => {
  return HttpService.getBearer('v2', 'user', bearer, {}, {});
};

const changeUserData = (payload: any) => {
  return HttpService.post('v2', 'user', payload, {}, {});
};

const changeEmail = (payload: any) => {
  return HttpService.post('user/change-email', payload, {}, {});
};

const deleteChangeEmail = () => {
  return HttpService.remove('v2', 'user/change-email', {});
};

const changePassword = (payload: any) => {
  return HttpService.post('v2', 'user/change-password', payload, {}, {});
};

const uploadFile = (uploadURL: any, payload: any) => {
  return HttpService.post('v2', uploadURL, payload, {'Content-Type': 'application/json'}, {});
};

const getNotificationData = () => {
  return HttpService.get('v2', 'notification', {}, {});
};

const setNotificationFcmToken = (fcmToken: any) => {
  return HttpService.post('v2', `notification/fcmToken`, { fcmToken : fcmToken }, {}, {});
};

const getUserSetting = (key?: string) => {
  return HttpService.get('v2', `user/setting${key ? `?key=${key}` : ""}`, {}, {});
};

const setUserSetting = (payload: any) => {
  return HttpService.post('v2', 'user/setting', payload, {}, {});
};

const deleteUserSetting = (key: string) => {
  return HttpService.remove('v2', `user/setting/${key}`, {});
};

export {
  getUserData,
  getUserBearerData,
  changeUserData,
  changeEmail,
  deleteChangeEmail,
  changePassword,
  uploadFile,
  getNotificationData,
  setNotificationFcmToken,
  getUserSetting,
  setUserSetting,
  deleteUserSetting
};