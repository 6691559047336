import HttpService from '../http.service';

const listClubs = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get('v2', `school-club${query ? `${query}`: ""}`, {}, {});
};

export {
  listClubs
};