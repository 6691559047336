import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  zipState: any;
  zipSelected: any;
  zipCurrentIndex: any;
};

const initialState: ArrayModel = {
  zipState: "none",
  zipSelected: [],
  zipCurrentIndex: 1,
};

const slice = createSlice({
    name: 'photoarchive',
    initialState: initialState,
    reducers: {
      setPhotoArchiveZipState(state, action: PayloadAction<Model>){
        state.zipState = action.payload;
      },
      setPhotoArchiveZipSelected(state, action: PayloadAction<Model>){
        state.zipSelected = action.payload;
      },
      setPhotoArchiveZipCurrentIndex(state, action: PayloadAction<Model>){
        state.zipCurrentIndex = action.payload;
      },
    }
});

export const photoarchiveSlice = slice;