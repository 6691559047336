import HttpService from '../http.service';

const listCategories = (schoolID: any) => {
  return HttpService.get('v2', `stock/category?schoolID=${schoolID}`, {}, {});
};

const createCategory = (payload: any) => {
  return HttpService.post('v2', `stock/category`, payload, {}, {});
};

const editCategory = (categoryID: any, payload: any) => {
  return HttpService.put('v2', `stock/category/${categoryID}`, payload, {}, {});
};

const deleteCategory = (categoryID: any) => {
  return HttpService.remove('v2', `stock/category/${categoryID}`, {});
};

const listTags = (schoolID: any) => {
  return HttpService.get('v2', `stock/tag?schoolID=${schoolID}`, {}, {});
};

const createTag = (payload: any) => {
  return HttpService.post('v2', `stock/tag`, payload, {}, {});
};

const editTag = (tagID: any, payload: any) => {
  return HttpService.put('v2', `stock/tag/${tagID}`, payload, {}, {});
};

const deleteTag = (tagID: any) => {
  return HttpService.remove('v2', `stock/tag/${tagID}`, {});
};

const listItems = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get('v2', `stock/item${query ? `${query}`: ""}`, {}, {});
};

const createItem = (payload: any) => {
  return HttpService.post('v2', `stock/item`, payload, {}, {});
};

const editItem = (itemID: any, payload: any) => {
  return HttpService.put('v2', `stock/item/${itemID}`, payload, {}, {});
};

const deleteItem = (itemID: any) => {
  return HttpService.remove('v2', `stock/item/${itemID}`, {});
};

const uploadFile = (uploadURL: any, payload: any) => {
  return HttpService.post('v2', uploadURL, payload, {'Content-Type': 'application/json'}, {});
};

const listOrders = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get('v2', `stock/order${query ? `${query}`: ""}`, {}, {});
};

const editOrder = (orderID: any, payload: any) => {
  return HttpService.post('v2', `stock/order/history/${orderID}`, payload, {}, {});
};

export {
  listCategories,
  createCategory,
  editCategory,
  deleteCategory,
  listTags,
  createTag,
  editTag,
  deleteTag,
  listItems,
  createItem,
  editItem,
  deleteItem,
  uploadFile,
  listOrders,
  editOrder
};