import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import htmlParse from 'html-react-parser';
import React, { useRef } from 'react';
import ScrollMenu from 'src/components/Menus/ScrollMenu';
import SVG from '../../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { Select as SelectUnstyled } from '@mui/base/Select';
import { useState } from '../../../../utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  width: any;
  allowClear: any;
};

const useStyles = createUseStyles((theme: any) => ({
  select: {
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    height: "42px",
    minHeight: "42px",
    backgroundColor: theme.colors.white,
    padding: "0px 10px 0px 10px",
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[325],
    flexDirection: "row",
    width: "100%",
    outline: 'none',
    maxWidth: '100%',
    '&:hover': {
      cursor: "pointer",
    },
    '&:focus': {
      borderColor: theme.colors.primaryBlue[500],
    },
    '&.disabled': {
      cursor: 'auto',
      backgroundColor: theme.colors.grey[75],
      color: theme.colors.grey[560],
      '& svg': {
        color: theme.colors.grey[560],
      },
    },
    '&.readonly': {
      cursor: 'auto',
      '& svg': {
        display: 'none',
      },
    },
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginLeft: "auto",
      marginRight: "0px",
      flex: (props: Props) => {
        if(props.allowClear) return '0 0 60px';
        else return '0 0 30px';
      },
      gap: '4px',
      minWidth: (props: Props) => {
        if(props.allowClear) return '60px';
        else return '30px';
      },
      '& > svg': {
        color: theme.colors.primaryBlue[500],
        transition: 'transform 0.25s',
        width: '24px',
        height: '24px',
      },
      '& > div': {
        height: '16px',
        transform: 'scale(0.8)',
        marginTop: '-2px',
        '& > svg': {
          width: '20px',
          height: '20px',
          color: theme.colors.primaryBlue[500],
        },
      },
    },
    '&.base--expanded': {
      '& > span': {
        '& > svg': {
          transform: 'rotate(-180deg)',
        },
      }, 
    },
  },
  selectedItem: {
    display: 'flex',
    alignItems: "center",
    flex: '1 1 auto',
    maxWidth: (props: Props) => {
      if(props.allowClear) return 'calc(100% - 60px)';
      else return 'calc(100% - 30px)';
    },
    '& > span': {
      display: 'flex',
      gap: '4px',
      fontSize: "0.75rem",
      fontWeight: "bold",
      marginBottom: "0",
      paddingRight: "10px",
      maxWidth: '100%',
    },
    '&:only-child': {
      maxWidth: '100%',
      '& > span': {
        paddingRight: '0',
      },
    }, 
  },
  scrollMenu: {
    display: 'flex',
    gap: '4px',
    fontSize: "0.75rem",
    fontWeight: "bold",
    marginBottom: "0",
    overflow: 'hidden',
    paddingLeft: '5px',
    maxWidth: 'calc(100% - 5px)',
  },
  scrollButton: {
    width: '30px',
    height: '30px',
    minWidth: '30px',
    minHeight: '30px',
    maxWidth: '30px',
    maxHeight: '30px',
  },
  popper: {
    borderRadius: "12px",
    backgroundColor: theme.colors.white,
    boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
    zIndex: theme.zIndex.popper,
    fontSize: "14px",
    overflowY: "auto",
    maxHeight: '230px',
    outline: 'none',
    width: (props: Props) => props.width,
    '& > ul': {
      padding: '0',
      outline: 'none',
    },
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    fontStyle: 'normal',
    borderRadius: '12px',
    width: 'fit-content',
    padding: '0 8px',
    height: '20px',
    fontSize: '10px',
    lineHeight: '10px',
    color: theme.colors.black,
    backgroundColor: theme.colors.chip,
    whiteSpace: 'nowrap',
    '& > svg': {
      width: '16px',
      minWidth: '16px',
      maxWidth: '16px',
      height: '16px',
      minHeight: '16px',
      maxHeight: '16px',
    },
    '& > span': {
      display: 'block',
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: '100%',
    },
  },
  value : {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    fontWeight: "600",
    color: theme.colors.black,
    maxWidth: 'calc(100% - 5px)',
    paddingLeft: '5px',
    '& > svg': {
      width: '16px',
      minWidth: '16px',
      maxWidth: '16px',
      height: '16px',
      minHeight: '16px',
      maxHeight: '16px',
    },
    '& > span': {
      display: 'block',
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: '100%',
      '& > em': {
        display: 'inline-flex',
        height: 'fit-content',
        fontStyle: 'unset',
        fontSize: '10px',
        padding: '3px 5px',
        borderRadius: '6px',
        backgroundColor: theme.colors.grey[200],
        color: theme.colors.black,
      },
    },
  },
  placeholder : {
    display: 'flex',
    alignItems: 'center',
    fontWeight: "400",
    color: theme.colors.grey[560],
    fontSize: '14px',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    '& > svg': {
      width: '20px',
      height: '20px',
      marginRight: '8px',
      color: 'inherit',
    },
  },
  image: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    minWidth: '24px',
    '& > div': {
      width: '24px',
      height: '24px',
      borderRadius: '100%',
      position: 'relative',
    },
  },
  imageChip: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '14px',
    height: '14px',
    minWidth: '14px',
    '& > div': {
      width: '14px',
      height: '14px',
      borderRadius: '6px',
      position: 'relative',
    },
  },
}));

type RenderSelectType = {
  id: any;
  items: any;
  children: any;
  isMultiple?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  selectedItem: any;
  setSelected: any;
  inputLabel?: any;
  inputIcon?: any;
  defaultTitle: any;
  defaultSelected: any;
  useTranslate: any;
  allowClear: boolean;
  isOpen: boolean;
  classNamePopper: any;
  dataCy?: any;
};

const RenderSelect: React.FunctionComponent<RenderSelectType> = ({id, items, children, isMultiple = false, disabled = false, readonly = false, selectedItem, setSelected, inputLabel, inputIcon, defaultTitle = "name", defaultSelected, useTranslate = false, allowClear = true, isOpen = false, classNamePopper, dataCy}) => {

  const selectRef: any = useRef(null);
  const [width, setWidth] = useState(0);
  const { t } = useTranslation();

  const selectedItemIndex = (items && Array.isArray(items)) ? items.map((item: any) => { return JSON.stringify(item); }).findIndex((item: any) => item === JSON.stringify(selectedItem)) : null;

  const updateListbox = () => {
    setWidth(selectRef.current.clientWidth + 2);
    setTimeout(() => {
      const el: any = document.getElementById(`${dataCy}i${selectedItemIndex}`);
      if(el) {
        el.scrollIntoView();
      }
    }, 100);
  };

  const classes = useStyles({
    width: width,
    allowClear: allowClear,
  });

  const renderValue = () => {
    return (
      <>
        <div className={classes.selectedItem} data-cy={isCypress() ? `${dataCy}SelectSelectedItemWrapper` : null}>
          <span>
            {
              selectedItem !== null ? (
                <>
                  {
                    defaultSelected ? (
                      <span className={classes.value} data-cy={isCypress() ? `${dataCy}SelectSelectedItem` : null}>
                        {
                          inputIcon ? (
                            <SVG src={inputIcon}/>
                          ) : null
                        }
                        <span data-cy={isCypress() ? `${dataCy}SelectSelectedItemText` : null}>
                          {htmlParse(items.filter((item: any) => item[defaultSelected] === selectedItem).length === 1 ? (useTranslate ? t(items.find((item: any) => item[defaultSelected] === selectedItem)[defaultTitle]) : items.find((item: any) => item[defaultSelected] === selectedItem)[defaultTitle]) : "")}
                        </span>
                      </span>
                    ) : (
                      <span className={classes.value} data-cy={isCypress() ? `${dataCy}SelectSelectedItem` : null}>
                        {
                          selectedItem.icon ? (
                            <SVG src={selectedItem.icon}/>
                          ) : inputIcon ? (
                            <SVG src={inputIcon}/>
                          ) : null
                        }
                        {
                          selectedItem.image ? (
                            <AuthenticatedImage className={classes.image} thumbLink={selectedItem.image}/>
                          ) : null
                        }
                        <span data-cy={isCypress() ? `${dataCy}SelectSelectedItemText` : null}>
                          {htmlParse(selectedItem[defaultTitle] ? (useTranslate ? t(selectedItem[defaultTitle]) : selectedItem[defaultTitle]) : "")}
                        </span>
                      </span>
                    )
                  }
                </>
              ) : (
                <span className={classes.placeholder} data-cy={isCypress() ? `${dataCy}SelectSelectedItemPlaceholder` : null}>
                  {
                    inputIcon ? (
                      <SVG src={inputIcon}/>
                    ) : null
                  }
                  {inputLabel ? inputLabel : t('select')}
                </span>
              )
            }
          </span>
        </div>
        {
          !readonly ? (
            <span data-cy={isCypress() ? `${dataCy}SelectClearWrapper` : null}>
              {
                (selectedItem !== null && allowClear) ? (
                  <div onClick={handleClear} data-cy={isCypress() ? `${dataCy}SelectClear` : null}>
                    <SVG src="close"/>
                  </div>
                ) : null
              }
              <SVG src="chevron-down"/>
            </span>
          ) : null
        }
      </>
    );
  };

  const renderMultipleValue = () => {
    return (
      <>
        <div className={classes.selectedItem} data-cy={isCypress() ? `${dataCy}SelectSelectedItemWrapper` : null}>
          <ScrollMenu className={classes.scrollMenu} classNameButton={classes.scrollButton} layout="horizontal" useArrows={false}>
            {
              (selectedItem && selectedItem.length) > 0 ? (
                <>
                  {
                    selectedItem.map((item: any, key: any) => (
                      <span className={classes.chip} key={`k_${key}`} data-cy={isCypress() ? `${dataCy}SelectSelectedItem${key}` : null}>
                        {
                          item.icon ? (
                            <SVG src={item.icon}/>
                          ) : null
                        }
                        {
                          item.image ? (
                            <AuthenticatedImage className={classes.imageChip} thumbLink={item.image}/>
                          ) : null
                        }
                        <span data-cy={isCypress() ? `${dataCy}SelectSelectedItem${key}Text` : null}>
                          {htmlParse(item[defaultTitle] ? (useTranslate ? t(item[defaultTitle]) : item[defaultTitle]) : "")}
                        </span>
                      </span>
                    ))
                  }
                </>
              ) : (
                <span className={classes.placeholder} data-cy={isCypress() ? `${dataCy}SelectSelectedItemPlaceholder` : null}>
                  {
                    inputIcon ? (
                      <SVG src={inputIcon}/>
                    ) : null
                  }
                  {inputLabel ? inputLabel : t('select')}
                </span>
              )
            }  
          </ScrollMenu>
        </div>
        <span data-cy={isCypress() ? `${dataCy}SelectClearWrapper` : null}>
          {
            (selectedItem && selectedItem.length && allowClear) > 0 ? (
              <div onClick={handleClear} data-cy={isCypress() ? `${dataCy}SelectClear` : null}>
                <SVG src="close"/>
              </div>
            ) : null
          }
          <SVG src="chevron-down"/>
        </span>
      </>
    );
  };

  const handleClear = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSelected(isMultiple ? [] : null);
  };

  const setValue = (e: any, value: any) => {
    if(e) {
      e.stopPropagation();
      e.preventDefault();
      setSelected(value);
    }
  };

  const slotProps = {
    popup: {
      className: `${classes.popper} ${classNamePopper}`,
      disablePortal: false,
      'data-cy': isCypress() ? `${dataCy}SelectPopper` : null,
      open: disabled ? false : isOpen,
      keepMounted: false,
    }
  };

  return isMultiple ? (
    <SelectUnstyled listboxId={id} slotProps={slotProps} multiple={true} listboxOpen={disabled ? false : isOpen} className={`${classes.select} ${disabled ? "disabled" : null} ${readonly ? "readonly" : null}`} disabled={disabled || readonly} value={selectedItem === null ? [] : selectedItem} onChange={setValue} onListboxOpenChange={updateListbox} renderValue={renderMultipleValue} ref={selectRef} data-cy={isCypress() ? `${dataCy}Select` : null}>
      {children}
    </SelectUnstyled> 
  ) : (
    <SelectUnstyled listboxId={id} slotProps={slotProps} listboxOpen={disabled ? false : isOpen} className={`${classes.select} ${disabled ? "disabled" : null} ${readonly ? "readonly" : null}`} disabled={disabled || readonly} value={selectedItem} onChange={setValue} onListboxOpenChange={updateListbox} renderValue={renderValue} ref={selectRef} data-cy={isCypress() ? `${dataCy}Select` : null}>
      {children}
    </SelectUnstyled>
  );
}

export default RenderSelect;