import DataTable from 'src/components/Layouts/DataTable';
import React, { forwardRef, useMemo } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import Tooltip from 'src/components/Layouts/Tooltip';
import useStyles from './style';
import { setChildrenModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

type TableType = {
  elements: any;
  id: any;
  title: any;
  isLoading: boolean;
};

const Table: React.FunctionComponent<TableType> = ({ elements, id, title, isLoading }) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);

  const getChildData = useMemo(() => (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const SpanElement = forwardRef<HTMLSpanElement, { children: any }>((props, ref) => {
    const { ...rest } = props;
    return (
      <span ref={ref} {...rest}>
        {props.children}
      </span>
    );
  });

  const IconElement = forwardRef<HTMLSpanElement, { src: any, iconstyle: any, tooltip: any }>((props, ref) => {
    const { ...rest } = props;
    return (
      <span ref={ref} {...rest} className={`icon ${props.iconstyle ? props.iconstyle : "default"} ${props.tooltip === "true" ? "tooltip" : ""}`}>
        <SVG src={props.src}/>
      </span>
    );
  });
  
  const processTooltip = (tooltip: any) => {
    return tooltip.replaceAll("\\n", "\n");
  };

  const processCell = (cell: any, type: any) => {
    const cellData =  (
      <div className={`cellWrapper ${type}`}>
        {
          cell.cellElements.map((el: any, key: any) => {
            if(el.type === "span") {
              return el.tooltip ? (
                <Tooltip key={`k_${key}`} title={processTooltip(el.tooltip)} multiline={true} maxWidth={400}>
                  <SpanElement>{el.value}</SpanElement>
                </Tooltip>
              ) : (
                <SpanElement key={`k_${key}`}>{el.value}</SpanElement>
              );
            } else if (el.type === "icon") {
              return el.tooltip ? (
                <Tooltip key={`k_${key}`} title={processTooltip(el.tooltip)} multiline={true} maxWidth={400}>
                  <IconElement src={el.value} iconstyle={el.style} tooltip={el.tooltip ? "true" : "false"}/>
                </Tooltip>
              ) : (
                <IconElement key={`k_${key}`} src={el.value} iconstyle={el.style} tooltip={el.tooltip ? "true" : "false"}/>
              );
            }
            return el.value;
          })
        }
    </div>);
    if(cell.cellPropertyModal) {
      const handleModal = (e: any, props: any, params: any) => {
        e.preventDefault();
        e.stopPropagation();
        const modalData = params.cellPropertyModal;
        if(modalData.type === "children") {
          const title = props.column.headerName;
          const children = modalData.data.map((item: any) => { return getChildData(item); });
          const handleCloseModal = () => {
            const settings = {
              isOpen: false,
              modalTitle: null,
              modalOnClose: null,
              defaultChildren: null,
              mode: null,
              displayMode: null,
              isAllowArchived: null,
            };
            dispatch(setChildrenModal(settings));
          };
          const settings = {
            isOpen: true,
            modalTitle: title,
            modalOnClose: handleCloseModal,
            defaultChildren: children,
            mode: "detail",
            displayMode: "simple",
            isAllowArchived: children.filter((item: any) => item.isArchived).length === 0,
          };
          dispatch(setChildrenModal(settings));
        }
      };
      cell = {...cell, onClick: handleModal};
    }
    const newCellData = {...cellData, params: {...cell}};
    return newCellData;
  };

  const processReportData = (elements: any) => {

    const headerRow = elements.find((row: any) => row.type === "header");
    const dataRows = elements.filter((row: any) => row.type === "body");
    const footerRow = elements.find((row: any) => row.type === "footer");

    const columns = headerRow.cells.map((cell: any, key: any) => ({
      field: cell.columnName,
      headerName: cell.cellElements.map((el: any) => el.value).join(" "),
      sortable: cell.sortable,
      filterable: cell.filterable,
      disableColumnMenu: cell.columnName === "row_title",
      hideable: cell.columnName !== "row_title",
      display: 'flex',
      headerAlign: key === 0 ? 'left' : 'right',
      align: key === 0 ? 'left' : 'right',
      renderHeader: () => processCell(cell, "header"),
      renderCell: (params: any) => params.value,
    }));

    const data = dataRows.map((row: any, index: any) => {
      const rowData: any = { ...row, id: index + 1 };
      delete rowData["cells"];
      row.cells.forEach((cell: any) => {
        rowData[cell.columnName] = processCell(cell, "body");
      });
      return rowData;
    });

    if (footerRow) {
      const footerData: any = { id: data.length + 1 };
      footerRow.cells.forEach((cell: any) => {
        footerData[cell.columnName] = processCell(cell, "footer");
      });
      data.push(footerData);
    }
    return { columns, data };
  };

  const { columns, data } = processReportData(elements);
  
  return (
    <div className={classes.tableWrapper} id={id}>
      <span className={classes.title}>
        {title}
      </span>
      <DataTable className={classes.table} columns={columns} data={data} page={0} visibleRows={[8, 16]} useAutoHeight={true} rowHeight={32} isColumnFilterable={false} isColumnMenuEnabled={true} isColumnSortable={true} isColumnSelectable={true} isFooterEnabled={false} isToolbarEnabled={false} isDensitySelectable={false} noResultsText={t('no_data_found')} isLoading={isLoading}/>
    </div>
  );
};

export default Table;