import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import htmlParse from 'html-react-parser';
import React from 'react';
import SVG from '../../../Images/SvgRenderer';
import { createCypressDataText, isCypress } from 'src/utils/useCypress';
import { createUseStyles } from 'react-jss';
import { Option as OptionUnstyled } from '@mui/base/Option';

const useStyles = createUseStyles((theme: any) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    cursor: 'pointer',
    padding: '12px 12px',
    width: 'calc(100% - 24px)',
    transition: 'all 0.25s',
    gap: '5px',
    '& > span': {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '100%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '4px',
      '& > em': {
        display: 'inline-flex',
        height: 'fit-content',
        fontStyle: 'unset',
        fontSize: '10px',
        padding: '3px 5px',
        borderRadius: '6px',
        backgroundColor: theme.colors.grey[200],
        color: theme.colors.black,
      },
    },
    '& > svg': {
      width: '16px',
      minWidth: '16px',
      maxWidth: '16px',
      height: '16px',
      minHeight: '16px',
      maxHeight: '16px',
    },
    '&:hover': {
      color: '#5A5C7B',
      backgroundColor: theme.colors.grey[125],
    },
    '&.selected': {
      backgroundColor: theme.colors.grey[250],
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',  
    }
  },
  image: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    minWidth: '24px',
    '& > div': {
      width: '24px',
      height: '24px',
      borderRadius: '100%',
      position: 'relative',
    },
  },
}));

type RenderOptionType = {
  id: any;
  children: any;
  selected: any;
  value: any;
  item: any;
  defaultSelected: any;
  className?: any;
  isMultiple?: boolean;
  dataCy?: any;
};

const RenderOption: React.FunctionComponent<RenderOptionType> = ({id, selected, children, value, item, defaultSelected, className, isMultiple, dataCy}) => {
  
  const classes = useStyles();

  return isMultiple ? (
    <OptionUnstyled id={id} className={`${classes.item} ${className ? className : ''} ${selected ? ((defaultSelected ? (selected && selected.includes(value[defaultSelected])) : (selected && selected.includes(value))) ? 'selected' : '') : ''}`} value={value} data-cy={isCypress() ? `${dataCy}Option${createCypressDataText(item.toLowerCase())}` : null}>
      {
        value.icon ? (
          <SVG src={value.icon}/>
        ) : null
      }
      {
        value.image ? (
          <AuthenticatedImage className={classes.image} thumbLink={value.image}/>
        ) : null
      }
      <span>{htmlParse(children ? children : "")}</span>
    </OptionUnstyled> 
  ) : (
    <OptionUnstyled id={id} className={`${classes.item} ${className ? className : ''} ${selected ? ((defaultSelected ? JSON.stringify(selected) === JSON.stringify(value[defaultSelected]) : JSON.stringify(selected) === JSON.stringify(value)) ? 'selected' : '') : ''}`} value={value} data-cy={isCypress() ? `${dataCy}Option${createCypressDataText(item.toLowerCase())}` : null}>
      {
        value.icon ? (
          <SVG src={value.icon}/>
        ) : null
      }
      {
        value.image ? (
          <AuthenticatedImage className={classes.image} thumbLink={value.image}/>
        ) : null
      }
      <span>{htmlParse(children ? children : "")}</span>
    </OptionUnstyled> 
  );
}

export default RenderOption;