import CloseButton from 'src/components/Buttons/CloseButton';
import Input from 'src/components/Forms/Input';
import Modal from '../../../../utils/modal';
import NormalButton from '../../../Buttons/NormalButton';
import React from 'react';
import RenderContent from '../RenderContent';
import SVG from '../../../Images/SvgRenderer';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../../utils/useCypress';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  modalRoot: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    outline: 'none',
  },
  modalWrapper: {
    display: "flex",
    alignItems: "center",
  },
  modalControlWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  modalHeader: {
    display: "flex",
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    position: 'relative',
    zIndex: '1',
  },
  modalTopWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
  },
  modalSearch: {
    flex: '1 1 auto',
    padding: '0 24px 24px 24px',
    width: 'calc(100% - 48px)',
    '& > form': {
      '& > div': {
        '& > span': {
          backgroundColor: 'transparent',
        },
        '& > input': {
          minHeight: '40px',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 12px 12px 12px',
    },
  },
  modalBody: {
    maxHeight: 'calc(100vh - 310px)',
    overflow: 'auto',
  },
  modalFooter: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    gap: '10px',
    padding: "24px",
    backgroundColor: theme.colors.white,
  },
  modalSchoolsCount: {
    backgroundColor: theme.colors.grey[250],
    borderRadius: "20px",
    marginLeft: "10px",
    padding: "2px 5px",
    display: "flex",
    alignItems: "center",
    '& svg': {
      color: theme.colors.primaryBlue[500],
      width: '20px',
      height: '19px',
    },
    '& p': {
      marginBottom: "0",
      marginLeft: "5px",
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
      fontSize: '16px',
    }
  },
  modalCloseButton: {
    padding: '10px',
    '& svg': {
      color: theme.colors.primaryBlue[500],
    }
  },
  modalCount: {
    backgroundColor: theme.colors.white,
    color: '#EB4D63 !important',
    borderRadius: '100%',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px !important',
    lineHeight: '12px',
    fontWeight: '600',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderTopWidth: '24px',
    borderTopStyle: 'solid',
    borderTopColor: theme.colors.white,
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
}));

type RenderInModalType = {
  state: any;
  setState: any;
};

const RenderInModal: React.FunctionComponent<RenderInModalType> = ({
  state,
  setState,
}) => {

  const classes = useStyles();
  const { t } = useTranslation();
  let delayDebounceFn: any;

  const handleClose = (e: any) => {
    e.stopPropagation();
    if(state.modalOnClose) state.modalOnClose();  
  };
    
  const handleClear = () => {
    setState("selectedSchools", []);    
  };
  
  const handleSave = (e: any)  => {
    e.stopPropagation();
    if(state.modalOnSave) state.modalOnSave(state.selectedSchools);
    if(state.modalCloseOnSave) state.modalOnClose();   
  };

  const handleSearchChange = (name: any, value: any, e: any) => {
    e.stopPropagation();
    clearTimeout(delayDebounceFn);
    delayDebounceFn = setTimeout(() => {
      setState(name, value.toLowerCase());
    }, value.length === 0 ? 1 : 1000);
  };

  return (
    <Modal 
      open={state.isModalOpen}
      onClose={state.modalOnClose}
    >
      <div className={classes.modalRoot} data-cy={"selectSchoolsModal"}>
        <div className={classes.modalHeader}>
          <div className={classes.modalControlWrapper}>
            <div className={classes.modalWrapper}>
              <p data-clarity-unmask="true">{t(state.modalTitle)}</p>
              {
                state.modalAllowSchoolsCount ? (
                  <span className={classes.modalSchoolsCount}>
                    <SVG src="class"/>
                    <p data-cy={isCypress() ? "selectSchoolsCount" : null} data-clarity-unmask="true">
                      {state.selectedSchools.length}
                    </p>
                  </span>
                ) : null
              }
            </div>
            {
              state.modalAllowClose ? (
                <CloseButton className={classes.modalCloseButton} onClick={handleClose} dataCy="timesButton"/>
              ) : null
            }
          </div>
          <div className={`${classes.modalTopWrapper} ${state.customClasses ? (state.customClasses.topWrapper ? state.customClasses.topWrapper : "") : ""}`}>
            {
              state.isAllowSearch ? (
                <div className={`${classes.modalSearch} ${state.customClasses ? (state.customClasses.search ? state.customClasses.search : "") : ""}`}>
                  <Input name="search" placeholder={t('search') + "..."} prepend={<SVG src="search"/>} onKeyDown={handleSearchChange} onChange={handleSearchChange} onKeyUp={handleSearchChange} dataCy='schoolsSearch' dataClarityUnmask="true"/>                                                                                                                                                                                                                 
                </div>
              ) : null
            }  
          </div>
        </div>
        <div className={classes.modalBody}>
          {
            !state.isLoadedAll ? (
              <div className={classes.loading}>
                <CircularProgress className={classes.spinner}/>
              </div>
            ) : (
              <RenderContent state={state} setState={setState}/>
            )
          }
        </div>
        { 
          state.isMultipleSelect ? (
            <div className={classes.modalFooter}>
              {
                state.modalAllowCancel ? (
                  <NormalButton buttonType="secondary" onClick={handleClose} dataCy="cancelButton">
                    {t("cancel")}
                  </NormalButton>
                ) : null
              }
              {
                state.modalAllowClear && state.selectedSchools.length > 0 ? (
                  <NormalButton buttonType="clear" startIcon={<span className={classes.modalCount}>{state.selectedSchools.length}</span>} onClick={handleClear} dataCy="clearButton">
                    {t("clear_selection")}
                  </NormalButton>
                ) : null
              }
              {
                state.selectedSchools.length > 0 && state.modalAllowNoSchool ? (
                  <NormalButton buttonType={JSON.stringify(state.defaultSelectedClasses) === JSON.stringify(state.selectedSchools) ? "disabled" : "primary"} disabled={JSON.stringify(state.defaultSelectedClasses) === JSON.stringify(state.selectedSchools)} onClick={handleSave} dataCy="doneButton">
                    {t("done")}
                  </NormalButton>
                ) : null
              }
              {
                state.selectedSchools.length > 0 && !state.modalAllowNoSchool ? (
                  <NormalButton buttonType="primary" onClick={handleSave} dataCy="selectButton">
                    {t("select")}
                  </NormalButton>
                ) : null
              }
              {
                state.selectedSchools.length === 0 && state.modalAllowNoSchool ? (
                  <NormalButton buttonType={JSON.stringify(state.defaultSelectedClasses) === JSON.stringify(state.selectedSchools) ? "disabled" : "primary"} disabled={JSON.stringify(state.defaultSelectedClasses) === JSON.stringify(state.selectedSchools)} onClick={handleSave} dataCy="doneButton">
                    {t("done")}
                  </NormalButton>
                ) : null
              }
              {
                state.selectedSchools.length === 0 && !state.modalAllowNoSchool ? (
                  <NormalButton buttonType="disabled" dataCy="selectButton">
                    {t("select")}
                  </NormalButton>
                ) : null
              }
            </div>
          ) : (
            <div className={classes.modalFooter}>
            {
              state.modalAllowClose ? (
                <NormalButton buttonType="secondary" onClick={handleClose} dataCy="closeButton">
                  {t("close")}
                </NormalButton>
              ) : null
            }
            </div>
          )
        }
      </div>
    </Modal>
  ); 
}

export default RenderInModal;