import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import Currency from 'react-currency-formatter';
import DateFormat from 'src/utils/dateFormat';
import moment from 'src/utils/moment';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { Avatar, AvatarGroup } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { removeDuplicatesJSON } from 'src/utils/useFunctions';
import { setClubDetailModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';
import { useTranslation } from 'react-i18next';
import { weekdays } from 'src/constants/weekdays';

interface Props {
  userRole: any;
  visibleForParent: any;
};

const useStyles = createUseStyles((theme: any) => ({
  clubCard: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.08)",
    maxWidth: '100%',
    position: 'relative',
    backgroundColor: theme.colors.white,
    width: '100%',
    gap: '8px',
    borderBottomWidth: (props: Props) => {
      if(props.userRole === "director") return '3px';
      else return '';
    },
    borderBottomStyle: (props: Props) => {
      if(props.userRole === "director") return 'solid';
      else return '';
    },
    borderBottomColor: (props: Props) => {
      if(props.userRole === "director") {
        if(props.visibleForParent) return theme.colors.systemGreen[500];
        else return theme.colors.systemRed[500];
      }
      else return '';
    },
  },
  headerBlock: {
    display: 'block',
    width: '100%',
    height: '130px',
    minHeight: '130px',
    borderRadius: '24px 24px 0 0',
    '& > div': {
      width: '100%',
      height: '100%',
      backgroundPosition: 'unset',
    },
  }, 
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '4px',
    width: 'calc(100% - 40px)',
    padding: '20px',
  },
  detailBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '16px',
    width: 'calc(100% - 40px)',
    padding: '0 20px 20px',
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: 'calc(100% - 40px)',
    padding: '20px',
    '& > button': {
      '&:disabled': {
        visibility: 'hidden',
      },
    },
  },
  clubDateTime: {
    fontSize: '12px',
    display: 'inline-flex',
    fontWeight: '400',
    color: theme.colors.grey[600],
  },
  clubName: {
    fontSize: '16px',
    display: 'block',
    fontWeight: '600',
    color: theme.colors.black,
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    '& > span': {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      '&:first-of-type': {
        fontWeight: '600',
        color: theme.colors.grey[555],
        '& > svg': {
          width: '24px',
          height: '24px',
        },
      },
      '&:last-of-type': {
        '& > em': {
          fontStyle: 'initial',
          color: theme.colors.grey[555],
          backgroundColor: theme.colors.grey[375],
          padding: '4px 12px',
          borderRadius: '24px',
        },
      },
    }, 
  },
  childrenListWrapper: {
    position: 'relative',
    padding: '0 0.5rem 0 1rem',
    width: "auto",
    marginLeft: '0',
    display: "inline-flex",
    alignItems: 'center',
    cursor: "pointer",
    order: '1',
  },
  childrenCount: {
    '& > div:first-of-type:not(:only-child)': {
      zIndex: '2',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      right: '0px',
      top: '-10px',
      width: '22px',
      height: '22px',
      backgroundColor: '#D4D8EC',
      color: theme.colors.white,
      fontSize: '10px',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      borderRadius: '100%',
      letterSpacing: '0px',
    },
    '& > div:not(:first-of-type):not(:only-child):not(:last-of-type)': {
      marginLeft: '-16px !important',
    },
  },
  childrenCountFix: {
    '& > div:not(:only-child):not(:last-of-type)': {
      marginLeft: '-16px !important',
    },
  },
  childWrapper: {
    border: '0 !important',
    backgroundColor: 'transparent',
    width: '38px',
    height: '38px',
    position: 'relative',
    overflow: 'visible',
    '& > em': {
      position: 'absolute',
      bottom: '-0px',
      right: '-0px',
      borderRadius: '100%',
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > svg': {
        width: '10px',
        height: '10px',
      },
      '&.state1': {
        backgroundColor: theme.colors.systemOrange[200],
        color: theme.colors.systemOrange[500],
      },
      '&.state2': {
        backgroundColor: theme.colors.systemGreen[200],
        color: theme.colors.systemGreen[500],
      },
      '&.state3': {
        backgroundColor: theme.colors.systemRed[200],
        color: theme.colors.systemRed[500],
      },
    },
  },
  childPhoto: {
    width: '32px',
    height: '32px',
    minWidth: '32px',
    minHeight: '32px',
    maxWidth: '32px',
    maxHeight: '32px', 
    borderRadius: '100%',
    backgroundColor: theme.colors.white,
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    '& > div': {
      borderWidth: '0px',
    },
  },
  teacherPhoto: {
    width: '36px',
    height: '36px',
    minWidth: '36px',
    minHeight: '36px',
    maxWidth: '36px',
    maxHeight: '36px', 
    borderRadius: '100%',
    backgroundColor: theme.colors.white,
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    '& > div': {
      borderWidth: '0px',
    },
  },
}));

type ItemsType = {
  data: any;
};

const ClubCard: React.FunctionComponent<ItemsType> = ({data}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);

  const classes = useStyles({
    userRole: getUserRole(userData.userObject.roleType),
    visibleForParent: data.visibleForParent,
  });

  const currencyID = data.currencyID;
  const getCurrency = currencyID ? (configurationData.currencies.filter((currency: any) => currency.currencyID === currencyID).length === 0 ? [] : configurationData.currencies.find((currency: any) => currency.currencyID === currencyID)) : [];
  
  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const getUserData = (userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  };

  const getStateIcon = (stateID: any) => {
    const state = [
      "clock",
      "checkmark",
      "close"
    ];
    return state[stateID - 1];
  };

  const clubChildren = data.children.map((item: any) => { return {childID: item.childID, state: item.state, stateText: item.stateText }}).filter((item: any) => getChildData(item.childID).length !== 0);

  const stateOrder: any = { 2: 1, 1: 2, 3: 3 };

  clubChildren.sort((a: any, b: any) => {
    if (a.state === b.state) {
      return a.clubApplicationID - b.clubApplicationID;
    }
    return stateOrder[a.state] - stateOrder[b.state];
  });

  const clubChildrenForApplications = useMemo(() => { return (Object.keys(data).length > 0 ? (data.children !== undefined ? (data.children.length > 0 ? getUserRole(userData.userObject.roleType) === "parent" ? data.children.filter((item: any) => item.state !== 3 && getChildData(item.childID).length !== 0) : data.children.filter((item: any) => getChildData(item.childID).length !== 0) : []) : []) : []); }, [data, getChildData, userData.userObject.roleType]);

  const applicationChildren = clubChildrenForApplications.map((item: any) => { return item.childID; });

  const availableChildren = dataData.children.filter((item: any) => !item.isArchived && item.isInAnyActiveClass).map((item: any) => { 
    if(applicationChildren.indexOf(item.childID) === -1 && item.schoolsID.indexOf(data.schoolID) !== -1) {
      return { ...item, image: item.photo.thumbLink};
    } else {
      return null;
    }
  }).filter((item: any) => item !== null);

  const handleViewDetail = () => {
    const settings = {
      isOpen: true,
      clubID: data.clubID,
    };
    dispatch(setClubDetailModal(settings));
  };

  const handleViewApplications = () => {
    const settings = {
      isOpen: true,
      clubID: data.clubID,
      view: 'applications'
    };
    dispatch(setClubDetailModal(settings));
  };

  const handleAddApplication = () => {
    const settings = {
      isOpen: true,
      clubID: data.clubID,
      view: 'addApplication'
    };
    dispatch(setClubDetailModal(settings));
  };

  return (
    <div className={classes.clubCard} data-cy={isCypress() ? 'clubCard' + data.clubID : null}>
      <AuthenticatedImage className={classes.headerBlock} thumbLink={data.photo.thumbLink} dataCy="headerPhoto"/>
      <div className={classes.infoBlock}>
        <span className={classes.clubDateTime}>{t(weekdays[data.dayInWeek].toLowerCase())} {data.timeFrom} - {data.timeTo}</span>
        <span className={classes.clubName}>{data.name}</span>
      </div>
      <div className={classes.detailBlock}>
        <div className={classes.row}>
          <span>
            <SVG src="user-circle-outlined"/>
            {t('club_teacher')}
          </span>
          <span>
            {
              data.teacherID ? (
                <>
                  <AuthenticatedImage className={classes.teacherPhoto} thumbLink={getUserData(data.teacherID).photo.thumbLink} dataCy="teacherPhoto"/>
                  {getUserData(data.teacherID).displayName}
                </>
              ) : (
                <>
                  <img className={classes.teacherPhoto} src="/resources/images/user.svg" data-cy="teacherPhoto"/>
                  <em>{t('club_no_teacher').toLowerCase()}</em>
                </>
              )
            }
          </span>
        </div>
        <div className={classes.row}>
          <span>
            <SVG src="calendar"/>
            {t('club_date')}
          </span>
          <span>
            {DateFormat(moment(data.dateFrom), "default", languageData, t)}
            &nbsp;-&nbsp;
            {DateFormat(moment(data.dateTo), "default", languageData, t)}
          </span>
        </div>
        <div className={classes.row}>
          <span>
            <SVG src="price-tag-outlined"/>
            {t('club_price')}
          </span>
          <span>
            <Currency quantity={parseFloat(data.priceTotal)} currency={getCurrency.iso}/> 
          </span>
        </div>
        <div className={classes.row}>
          <span>
            <SVG src="users"/>
            {t('club_capacity')}
          </span>
          <span>
            {t('club_capacity_info', {available: data.childrenMax - data.countAcceptedApplications < 0 ? 0 : data.childrenMax - data.countAcceptedApplications, total: data.childrenMax})}
          </span>
        </div>
        <div className={classes.row}>
          <span>
            <SVG src="info-circle-outlined"/>
            {t('club_applications')}
          </span>
          <span>
            {
              clubChildren.length === 0 ? (
                <em>{t('club_no_application').toLowerCase()}</em>
              ) : (
                <div className={classes.childrenListWrapper} onClick={handleViewApplications} data-cy={isCypress() ? "clubCardChildrenList" : null}>
                  <AvatarGroup total={clubChildren.length} className={clubChildren.length <= 3 ? classes.childrenCountFix : classes.childrenCount}>
                  {
                    removeDuplicatesJSON(clubChildren).slice(0, 3).map((child: any, key: any) => (
                      <Avatar className={classes.childWrapper} key={`k_${key}`}>
                        <AuthenticatedImage className={classes.childPhoto} thumbLink={getChildData(child.childID).photo.thumbLink} dataCy={`childPhoto_${child.childID}`}/>
                        <em className={`state${child.state}`}>
                          <SVG src={getStateIcon(child.state)}/>
                        </em>
                      </Avatar>
                    ))
                  }
                  </AvatarGroup>
                </div>
              )
            }
          </span>
        </div>
      </div>
      <div className={classes.buttonBlock}>
        <NormalButton buttonType='secondary' onClick={handleViewDetail}>
          {t('club_detail')}
        </NormalButton>
        <NormalButton buttonType='primary' disabled={!data.enableEnrollment || getUserRole(userData.userObject.roleType) !== "parent" || availableChildren.length === 0} onClick={handleAddApplication}>
          {t('club_application_add')}
        </NormalButton>
      </div>
    </div>
  );
}

export default ClubCard;