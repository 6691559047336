import Button from '@mui/material/Button';
import ChildInfoItem from '../../Items/ChildInfoItem';
import ChildrenSelect from '../../Selects/ChildrenSelect';
import React, { useMemo } from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useState } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  childrenWrapper: {
    display: 'flex',
    flexDirection: "row",
    gap: '5px',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    boxShadow: theme.shadows[2],
    padding: '13px 12px',
    alignItems: 'flex-start',
    maxWidth: 'calc(100% - 24px)',
    overflow: 'hidden',
    height: 'fit-content',
    position: 'relative',
    width: '100%',
    '&:empty': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: "0px",
    }, 
  },
  childWrapper: {
    width: '90px',
    height: '100%',
  },
  childMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '90px',
    height: '100%',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
  },
  childMore: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',  
    width: '100%',
    height: '100%',
    borderRadius: '0',
    backgroundColor: theme.colors.primaryBlue[500],
    color: theme.colors.white,
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: 'all 0.25s',
    '&.active': {
      backgroundColor: theme.colors.primaryBlue[600],
      color: theme.colors.white,
    },
    '&:hover': {
      backgroundColor: theme.colors.primaryBlue[600],
      color: theme.colors.white,
    },
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
}));

type ChildrenListSelectType = {
  currentChild: any;
  childrenData: any;
  setCurrentChild: any;
  useDispatch?: any;
  width?: any,
  isDisabled?: any;
  isAllowArchived?: any;
};

const ChildrenListSelect: React.FunctionComponent<ChildrenListSelectType> = ({currentChild = null, childrenData = null, setCurrentChild = null, useDispatch = false, width = null, isDisabled = false, isAllowArchived = false}) => {
  
  const classes = useStyles();
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const curChild = useMemo(() => currentChild, [currentChild]);
  const children = useMemo(() => childrenData.map((item: any) => { return { childID: item.childID, classID: item.classID, schoolID: item.schoolID }; }), [childrenData]);
  const curChildIndex = useMemo(() => (curChild === null || curChild === undefined) ? 0 : children.findIndex((child: any) => child.childID === curChild.childID), [children, curChild]);
  const childrenCount: any = useMemo(() => Math.floor((width === null || width === undefined) ? browserData.width : width / 95) - 1, [browserData.width, width]);
  const childrenVisible: any = useMemo(() => (curChild === null || curChild === undefined) === null ? [] : children.slice(Math.max(Math.min(curChildIndex - childrenCount / 2, children.length - childrenCount), 0), Math.max(Math.min(curChildIndex - childrenCount / 2, children.length - childrenCount), 0) + childrenCount), [children, childrenCount, curChild, curChildIndex]);
  const [isChildrenSelectOpen, setIsChildrenSelectOpen] = useState(false);
  const dispatch = useAppDispatch();

  const getChildData = useMemo(() => (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const setCurChild = (child: any) => {
    if(useDispatch) {
      dispatch(setCurrentChild(child));
    } else {
      setCurrentChild(child);
    }
  };

  const setCurrentChildFromModal = (e: any, data: any) => {
    e.stopPropagation();
    setCurChild(getChildData(data.child.childID));
  };

  const setCurrentChildFromList = (child: any) => {
    setCurChild(getChildData(child.childID));
  };

  const handleOpenChildrenSelect = () => {
    setIsChildrenSelectOpen(true); 
  };

  const handleCloseChildrenSelect = () => {
    setIsChildrenSelectOpen(false); 
  };

  return curChild ? (
    <div className={classes.childrenWrapper}>
      {
        childrenVisible.length > 0 ? childrenVisible.map((child: any, key: any) => (
          <div className={classes.childWrapper} key={`k_${key}`}>
            <ChildInfoItem childID={child.childID} isSelected={child.childID === curChild.childID} isDisabled={child.childID === curChild.childID ? false : isDisabled} mode="select" isVisibleArchived={true} isVisibleClass={false} onClick={() => setCurrentChildFromList(child)} disableClick={child.childID === curChild.childID ? true : false}/>
          </div>
        )) : null
      }
      {
        children.length > childrenVisible.slice.length ? (
          <div className={classes.childMoreWrapper}>
            <Button className={`${classes.childMore} ${isChildrenSelectOpen && 'active'}`} onClick={handleOpenChildrenSelect}>
              <SVG src="more"/>
            </Button>
          </div>
        ) : null
      }
      {
        isChildrenSelectOpen ? (
          <ChildrenSelect
            isInModal={true}
            isModalOpen={true}
            modalTitle="children"
            modalOnClose={handleCloseChildrenSelect}
            onClickChild={setCurrentChildFromModal}
            selectedChildren={[{childID: curChild.childID}]}
            defaultChildren={children}
            isSelectAll={false}
            isSelectInAllClass={true}
            isMultipleSelect={false}
            isAllowArchived={isAllowArchived}
            isAllowArchivedToggle={children.filter((item: any) => getChildData(item.childID).isArchived).length !== 0}
            isAllowSearch={true}
            mode="select"
            isDisableClick={isDisabled}
            isAllowOnlyOneChildAtOnce={true}
            isShowChildrenArchived={true}
            modalAllowNoChild={true}
            modalAllowChildrenCount={false}
            modalAllowCancel={true}
            modalAllowClose={true}
          />
        ) : null
      }
    </div>
  ) : null;
}

export default ChildrenListSelect;