import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import Clamp from 'react-multiline-clamp';
import htmlParse from 'html-react-parser';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import Tooltip from 'src/components/Layouts/Tooltip';
import { convertToCapitalFirstLetter, getPinterestMetricValue, hasObjectAnyValue, trimCharacter } from 'src/utils/useFunctions';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { useTranslation } from 'react-i18next';

interface Props {
  color: any;
  width: any;
  height: any;
};

const useStyles = createUseStyles((theme: any) => ({
  pinterestItemCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pinterestItemCard: {
    display: 'flex',
    flexDirection: 'column',
    width: (props: Props) => `${props.width}px`,
    height: (props: Props) => `${props.height}px`,
    backgroundSize: 'contain',
    backgroundColor: (props: Props) => props.color,
    position: 'relative',
    overflow: 'hidden',
    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.08)",
    '&:only-child': {
      borderRadius: '14px',
    },
    '&:not(:only-child)': {
      borderRadius: '14px 14px 0 0',
    },
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxWidth: 'unset',
    cursor: 'pointer',
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundImage: 'linear-gradient(to bottom,rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 90%)',
    },
  },
  title: {
    position: 'absolute',
    bottom: '8px',
    left: '8px',
    right: '8px',
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.alwaysWhite,
    fontSize: '14px',
    fontWeight: 'bold',
    maxWidth: '100%',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    fontSize: '16px',
    borderRadius: '0 0 14px 14px',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    padding: '8px 16px',
    width: (props: Props) => `calc(${props.width}px - 32px)`,
    '& > p': {
      fontSize: '14px',
    },
  },
  stats: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    '& > span': {
      display: 'flex',
      gap: '4px',
      alignItems: 'center',
      '& > svg': {
        width: '16px',
        height: '16px',
      },
      fontSize: '12px',
    },
  },
}));

type ItemsType = {
  item: any;
  columnSize: any;
  onClick: any;
};

const PinterestItemCard: React.FunctionComponent<ItemsType> = ({ item, columnSize, onClick }) => {

  const { t } = useTranslation();

  const id = item.id;

  const creativeType = item.creative_type;
  const images: any = item.media.images;
  const lastKey: any = images ? Object.keys(images).pop() : [];
  const lastImage = images ? images[lastKey] : { width: null, height: null, url: null };
  const { width: pinWidth, height: pinHeight, url: image } = lastImage;

  const stats: any = creativeType === "IDEA" ? {
    comment: getPinterestMetricValue(item, 'comment'),
    impression: getPinterestMetricValue(item, 'impression'),
    pin_click: getPinterestMetricValue(item, 'pin_click'),
    profile_visit: getPinterestMetricValue(item, 'profile_visit'),
    reaction: getPinterestMetricValue(item, 'reaction'),
    save: getPinterestMetricValue(item, 'save'),
    video_views: getPinterestMetricValue(item, 'video_views'),
  } : null;

  const color = item.dominant_color;
  const width = parseInt(pinWidth) > parseInt(columnSize) ? parseInt(columnSize) : parseInt(pinWidth);
  const height = parseInt(pinWidth) > parseInt(columnSize) ? parseInt(pinHeight) / (parseInt(pinWidth) / parseInt(columnSize)) : parseInt(pinWidth);

  const classes = useStyles({
    color: color,
    width: width,
    height: height,
  });

  const handleOnClick = () => {
    onClick(id);
  };

  return (
    <div className={classes.pinterestItemCardWrapper}>
      <div className={classes.pinterestItemCard} data-cy={isCypress() ? 'pinterestItemCard' + id : null}>
        <AuthenticatedImage className={classes.image} thumbLink={image} onClick={handleOnClick}/>
        <Clamp lines={1}>
          <span className={classes.title} onClick={handleOnClick}>{item.title ? htmlParse(convertToCapitalFirstLetter(item.title)) : ''}</span>
        </Clamp>
      </div>
      {
        (trimCharacter(item.description, " ").length > 0 || hasObjectAnyValue(stats)) ? (
          <div className={classes.detail}>
            {
              creativeType === "IDEA" && hasObjectAnyValue(stats) ? (
                <div className={classes.stats}>
                  {
                    stats.video_views ? (
                      <Tooltip title={t('pinterest_video_views')}>
                        <span>
                          <SVG src="eye"/> {stats.video_views}
                        </span>
                      </Tooltip>
                    ) : null
                  }
                  {
                    stats.impression ? (
                      <Tooltip title={t('pinterest_impression')}>
                        <span>
                          <SVG src="eye"/> {stats.impression}
                        </span>
                      </Tooltip>
                    ) : null
                  }
                  {
                    stats.comment ? (
                      <Tooltip title={t('pinterest_comment')}>
                        <span>
                          <SVG src="comment"/> {stats.comment}
                        </span>
                      </Tooltip>
                    ) : null
                  }
                  {
                    stats.save ? (
                      <Tooltip title={t('pinterest_save')}>
                        <span>
                          <SVG src="pin"/> {stats.save}
                        </span>
                      </Tooltip>
                    ) : null
                  }
                  {
                    stats.pin_click ? (
                      <Tooltip title={t('pinterest_pin_click')}>
                        <span>
                          <SVG src="hand"/> {stats.pin_click}
                        </span>
                      </Tooltip>
                    ) : null
                  }
                  {
                    stats.profile_visit ? (
                      <Tooltip title={t('pinterest_profile_visit')}>
                      <span>
                        <SVG src="person-outlined"/> {stats.profile_visit}
                      </span>
                      </Tooltip>
                    ) : null
                  }
                  {
                    stats.reaction ? (
                      <Tooltip title={t('pinterest_reaction')}>
                        <span>
                          <SVG src="hand-thumbs-up"/> {stats.reaction}
                        </span>
                      </Tooltip>
                    ) : null
                  }
                </div>
              ) : null
            }
            <Clamp lines={2}>
              <p>{htmlParse(item.description)}</p>
            </Clamp>
          </div>
        ) : null
      }
    </div>
  );
}

export default PinterestItemCard;