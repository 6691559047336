import { createUseStyles } from 'react-jss';

interface Props {
  size?: any;
  isClickable?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      '& + span': {
        fontSize: '13px',
        color: theme.colors.grey[650],
      },
      '&:only-of-type': {
        fontSize: '13px',
        color: theme.colors.grey[650],
      },
    },
  },
  substitutionInfoItem: {
    position: 'relative',
    width: (props: Props) => (props.size * 4) + 'px',
    height: (props: Props) => props.size + 'px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  },
  capsule: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: (props: Props) => `${(props.size * 2.4) + 4}px`,
    height: (props: Props) => `${props.size}px`,
    borderRadius: (props: Props) => `${props.size}px`,
    overflow: 'hidden',
  },
  substitutionPart: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => `${(props.size * 2.4) + 4}px`,
    height: (props: Props) => `${props.size}px`,
    backgroundColor: theme.colors.brightBlue[100],
    cursor: (props: Props) => {
      if(props.isClickable) return 'pointer';
      else return '';
    },
  },
  substitutionPartAM: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => `${(props.size * 1.2)}px`,
    height: (props: Props) => `${props.size}px`,
    backgroundColor: theme.colors.brightBlue[100],
    cursor: (props: Props) => {
      if(props.isClickable) return 'pointer';
      else return '';
    },
  },
  substitutionPartPM: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => `${(props.size * 1.2)}px`,
    height: (props: Props) => `${props.size}px`,
    backgroundColor: theme.colors.brightBlue[100],
    cursor: (props: Props) => {
      if(props.isClickable) return 'pointer';
      else return '';
    },
  },
  watchdogPart: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => `${(props.size * 2.4) + 4}px`,
    height: (props: Props) => `${props.size}px`,
    backgroundColor: theme.colors.brightBlue[100],
    cursor: (props: Props) => {
      if(props.isClickable) return 'pointer';
      else return '';
    },
    '& > svg': {
      width: (props: Props) => `${props.size * 0.5}px`,
      height: (props: Props) => `${props.size * 0.55}px`,
      color: theme.colors.white,
    },
  },
  watchdogPartAM: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => `${(props.size * 1.2)}px`,
    height: (props: Props) => `${props.size}px`,
    backgroundColor: theme.colors.brightBlue[100],
    cursor: (props: Props) => {
      if(props.isClickable) return 'pointer';
      else return '';
    },
    '& > svg': {
      width: (props: Props) => `${props.size * 0.5}px`,
      height: (props: Props) => `${props.size * 0.55}px`,
      color: theme.colors.white,
    },
  },
  watchdogPartPM: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => `${(props.size * 1.2)}px`,
    height: (props: Props) => `${props.size}px`,
    backgroundColor: theme.colors.brightBlue[100],
    cursor: (props: Props) => {
      if(props.isClickable) return 'pointer';
      else return '';
    },
    '& > svg': {
      width: (props: Props) => `${props.size * 0.5}px`,
      height: (props: Props) => `${props.size * 0.55}px`,
      color: theme.colors.white,
    }, 
  },
  attendancePart: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => `${(props.size * 2.4) + 4}px`,
    height: (props: Props) => `${props.size}px`,
    backgroundColor: theme.colors.primaryBlue[500],
  },
  attendancePartAM: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => `${(props.size * 1.2)}px`,
    height: (props: Props) => `${props.size}px`,
    backgroundColor: theme.colors.primaryBlue[500],
  },
  attendancePartPM: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => `${(props.size * 1.2)}px`,
    height: (props: Props) => `${props.size}px`,
    backgroundColor: theme.colors.primaryBlue[500],
  },
  createPart: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'background-color 0.25s',
    width: (props: Props) => `${(props.size * 2.4) + 4}px`,
    height: (props: Props) => `${props.size}px`,
    backgroundColor: theme.colors.grey[345],
    cursor: (props: Props) => {
      if(props.isClickable) return 'pointer';
      else return '';
    },
    '& > svg': {
      width: (props: Props) => `${props.size * 0.5}px`,
      height: (props: Props) => `${props.size * 0.55}px`,
      color: theme.colors.white,
    },
    '&:hover': {
      backgroundColor: (props: Props) => {
        if(props.isClickable) return theme.colors.grey[385];
        else return '';
      },
    },
  }, 
  createPartAM: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'background-color 0.25s',
    width: (props: Props) => `${(props.size * 1.2)}px`,
    height: (props: Props) => `${props.size}px`,
    backgroundColor: theme.colors.grey[345],
    cursor: (props: Props) => {
      if(props.isClickable) return 'pointer';
      else return '';
    },
    '& > svg': {
      width: (props: Props) => `${props.size * 0.5}px`,
      height: (props: Props) => `${props.size * 0.55}px`,
      color: theme.colors.white,
    },
    '&:hover': {
      backgroundColor: (props: Props) => {
        if(props.isClickable) return theme.colors.grey[385];
        else return '';
      },
    },
  },
  createPartPM: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'background-color 0.25s',
    width: (props: Props) => `${(props.size * 1.2)}px`,
    height: (props: Props) => `${props.size}px`,
    backgroundColor: theme.colors.grey[345],
    cursor: (props: Props) => {
      if(props.isClickable) return 'pointer';
      else return '';
    },
    '& > svg': {
      width: (props: Props) => `${props.size * 0.5}px`,
      height: (props: Props) => `${props.size * 0.55}px`,
      color: theme.colors.white,
    },
    '&:hover': {
      backgroundColor: (props: Props) => {
        if(props.isClickable) return theme.colors.grey[385];
        else return '';
      },
    },
  },  
}));

export default useStyles;