import HttpService from '../http.service';

const listMedias = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for(const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  } 
  return HttpService.get('v2', `media${query ? `${query}`: ""}`, {}, {});
};

const setAttribute = (mediaID: any, payload: any) => {
  return HttpService.put('v2', `media/attribute/${mediaID}`, payload, {}, {});
};

const downloadFile = (fileID: any) => {
  return HttpService.get('v2', `file/${fileID}`, {}, {responseType: 'arraybuffer'});
};

export {
  listMedias,
  setAttribute,
  downloadFile,
};