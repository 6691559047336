import DateFormat from '../../../utils/dateFormat';
import DateItem from './DateItem';
import IconButton from 'src/components/Buttons/IconButton';
import moment from '../../../utils/moment';
import months from '../../../constants/months';
import Popover from '@mui/material/Popover';
import React, { useMemo } from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { getUserSetting } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { moveLastToFirstInArray } from 'src/utils/useFunctions';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';
import { weekdaysMin } from '../../../constants/weekdays';

interface Props {
  weekNumber?: boolean,
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    position: "relative",
    borderRadius: "12px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    minWidth: 'calc(100% - 25px)',
    maxWidth: 'calc(100% - 25px)',
    height: "36px",
    backgroundColor: theme.colors.white,
    padding: "0px 10px 0px 15px",
    marginTop: "10px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.colors.grey[325],
    '&:hover': {
      cursor: "pointer",
    },
    '&.disabled': {
      cursor: 'auto',
      backgroundColor: theme.colors.grey[75],
      color: theme.colors.grey[560],
      '& svg': {
        color: theme.colors.grey[560],
      },
    },
  },
  selectedDate: {
    marginBottom: '0 !important',
    fontSize: '14px',
  },
  calendarWrapper: {
    '& .MuiPopover-paper': {
      borderRadius: '10px',
      boxShadow: theme.shadows[2],
    },
  },
  calendar: {
    position: "relative",
    width: (props: Props) => props.weekNumber ? "320px" : "280px",
    maxHeight: "500px",
    maxWidth: '100%',
    backgroundColor: theme.colors.white,
    zIndex: "9999",
    display: "flex",
    flexWrap: "wrap",
    borderRadius: '10px',
    overflow: 'hidden',
  },
  calendarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '5px 20px',
    '& p': {
      marginBottom: '0',
      fontWeight: 'bold',
    }
  },
  weekdayHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#ececec',
    cursor: 'auto',
  },
  weekday: {
    width: 'calc(100% / 7)',
    padding: '5px 0',
    fontSize: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      width: '100%',
      textAlign: 'center',
      marginBottom: '0 !important',
      fontSize: '11px !important',
    },
  },
  dateWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  navigator: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '68px',
    '& svg': {
      color: theme.colors.primaryBlue[500],
      width: '20px',
      height: '20px',
    },
  },
}));

type DatePickerType = {
  presetDate?: any;
  setDate?: any;
  presetDateRange?: any;
  weekNumber?: any;
  start?: any;
  end?: any;
  disabled?: boolean;
  dataCy?: any;
};

const DatePicker: React.FunctionComponent<DatePickerType> = ({ presetDate = moment(), setDate, presetDateRange, weekNumber, start, end, disabled = false, dataCy }) => {
  
  let dateRange: any = {};
  if(presetDateRange.start == null) dateRange.start = moment();
  else dateRange.start = presetDateRange.start;
  if(presetDateRange.end == null) dateRange.end = moment();
  else dateRange.end = presetDateRange.end;
  const { t } = useTranslation();
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  
  const [state, setState] = useStates({
    currentYearMonth: moment((presetDate && presetDate !== null) ? presetDate : undefined),
    anchorEl: null
  });

  const classes = useStyles({ weekNumber });

  const firstDayInWeek = getUserSetting(userData.userSettings, "customizations", ["calendar", "first_day_in_week"]);
  const weekdays: any = firstDayInWeek === 0 ? moveLastToFirstInArray(weekdaysMin) : weekdaysMin;

  useEffect(() => {
    setState("currentYearMonth", moment((presetDate && presetDate !== null) ? presetDate : undefined));
  }, [presetDate, state.anchorEl, setState], [presetDate, state.anchorEl]);

  const firstDate = useMemo(() => {
    const startOfMonth = new Date(state.currentYearMonth.year(), state.currentYearMonth.month(), 0);
    return moment(startOfMonth).subtract(startOfMonth.getDay(), 'days');
  }, [state.currentYearMonth]);
  
  const handleClick = (e: any) => {
    if(!disabled) {
      setState("anchorEl", e.currentTarget);
    }
  };

  const handleClose = () => {
    if(!disabled) {
      setState("anchorEl", null);
    }
  };

  const handleNextMonth = (e: any) => {
    e.stopPropagation();
    setState("currentYearMonth", moment(state.currentYearMonth).add(1, 'months'));
  };

  const handleLastMonth = (e: any) => {
    e.stopPropagation();
    setState("currentYearMonth", moment(state.currentYearMonth).subtract(1, 'months'));
  };
  
  useEffect(() => {
    if(end) {
      if(presetDateRange.start !== null && moment((presetDate && presetDate !== null) ? presetDate : undefined).isBefore(moment(dateRange.start))) {
        setDate(dateRange.start);
        setState("currentYearMonth", dateRange.start);
      }
    }  
  }, [dateRange.start, presetDate, presetDateRange.start, end, setDate, setState], [dateRange.start]); 

  return (
    <>
      <div className={`${classes.root} ${disabled ? 'disabled' : null}`} onClick={handleClick} data-cy={isCypress() ? dataCy : null}>
        <p className={classes.selectedDate} data-clarity-unmask="true">
          {
            presetDate === null ? (
              <span style={{color: "#787878"}}>{t('select_date')}</span>
            ) : (
              <>{DateFormat(moment((presetDate && presetDate !== null) ? presetDate : undefined).toString(), "default", languageData, t)}</>
            )
          }
        </p>
      </div>
      <Popover className={classes.calendarWrapper} open={Boolean(state.anchorEl)} anchorEl={state.anchorEl} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: end ? 'right' : 'left'}} transformOrigin={{vertical: 'top', horizontal: end ? 'right' : 'left'}}>
        <div className={classes.calendar}>
          <div className={classes.calendarHeader}>
            <p data-clarity-unmask="true">{`${t(months[state.currentYearMonth.month()].toLowerCase())} ${state.currentYearMonth.year()}`}</p>
            <div className={classes.navigator}>
              <IconButton onClick={handleLastMonth} dataCy='prevMonthButton'>
                <SVG src="chevron-left"/>
              </IconButton>
              <IconButton onClick={handleNextMonth} dataCy='nextMonthButton'>
                <SVG src="chevron-right"/>
              </IconButton>
            </div>
          </div>
          <div className={classes.weekdayHeader}>
            {
              weekNumber ? (
                <div className={classes.weekday}>
                  <p>{t("week").substring(0,1)}</p>
                </div>
              ) : null
            }
            {
              weekdays.map((weekday: any, key: any) => (
                <div className={classes.weekday} key={`k_${key}`}>
                  <p data-clarity-unmask="true">{t(weekday.toLowerCase())}</p>
                </div>
              ))
            }
          </div>
          <div className={classes.dateWrapper} data-cy={isCypress() ? 'calendar' : null}>
            {
              new Array(weekNumber ? 48 : 42).fill('').map((_, key) => {
                const itemDate = weekNumber ? moment(firstDate).add(key % 8 === 0 ? key - Math.floor(key / 8) : key - Math.ceil(key / 8), 'days').add(firstDayInWeek, 'day') : moment(firstDate).add(key, 'days').add(firstDayInWeek, 'day');
                return (
                  <DateItem
                    index={key}
                    key={`k_${key}`}
                    date={itemDate}
                    start={start}
                    end={end}
                    setDate={setDate}
                    currentSelectedDate={(presetDate && presetDate !== null) ? presetDate : undefined}
                    dateRange={dateRange}
                    presetDateRange={presetDateRange}
                    weekNumberMode={weekNumber}
                    handleClose={handleClose}
                  />
                )
              })
            }
          </div>
        </div>
      </Popover>
    </>
  );
};

export default DatePicker;