const calendarViewModes: any = [
  {
    name: 'day',
    value: 'day',
  },
  {
    name: 'week',
    value: 'week',
  },
  {
    name: 'month',
    value: 'month',
  },
  {
    name: 'year',
    value: 'year',
  },
];

export {
  calendarViewModes,
};