import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  photoArchivePage: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% - 48px)',
    width: 'calc(100% - 48px)',
    padding: '24px',
    height: 'calc(100% - 48px)',
    alignItems: 'center',
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  childrenWrapper: {
    display: 'flex',
    flex: '1 1 80%',
  },
  infoBlock: {
    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 3px 20px',
    borderRadius: '20px',
    backgroundColor: theme.colors.white,
    padding: '20px',
    minWidth: '300px',
    width: '40vw',
    maxWidth: '100%',
    height: 'fit-content',
    flex: '0 0 fit-content',
    textAlign: 'center',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '16px',
      fontWeight: 'bold',  
    },
    '& a': {
      color: theme.colors.primaryBlue[500],
    },
    '& > p': {
      fontSize: '14px', 
      marginBottom: '0',
      color: '#7F838B', 
    },
    '& > strong + p': {
      marginTop: '0.25rem', 
    },
    '& > p + p': {
      marginTop: '0.25rem', 
    }
  },
  childrenBlock: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '300px',
    width: '40vw',
    maxWidth: '100%',
    textAlign: 'center',
  },
  childBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: '1 1 100%',
    alignItems: 'center', 
    justifyContent: 'center',
    boxShadow: 'rgba(54, 44, 44, 0.08) 0px 3px 20px',
    borderRadius: '20px',
    marginBottom: '20px',
    backgroundColor: theme.colors.white,
    padding: '20px',
    gap: '8px',
  },
  years: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '8px',
    paddingTop: '8px',
    '& > span': {
      fontSize: '18px',
      fontWeight: '700',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 56px)',
      gridAutoFlow: 'dense',
      width: '100%',
      gap: '16px',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  photoArchiveItems: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 56px)',
    gridAutoFlow: 'dense',
    width: '100%',
    gap: '16px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;