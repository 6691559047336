import config from 'src/constants/config';
import EndOfScroll from 'src/components/Layouts/EndOfScroll';
import moment from 'src/utils/moment';
import NotFound from 'src/components/Layouts/NotFound';
import PopupCard from 'src/components/Cards/PopupCard';
import React, { useCallback, useRef } from 'react';
import Sidebar from 'src/components/Layouts/Sidebar';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getUserSetting } from 'src/utils/useUser';
import { InView } from 'react-intersection-observer';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  newsPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '24px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '24px 0px',
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  wrapperColumn: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  newsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '20px',
  },
  popupCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  popupCardInView: {
    width: '100%',
    height: 'auto',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    paddingBottom: '100px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const popupsData = useAppSelector((state: any) => state.popups);
  const userData = useAppSelector((state: any) => state.user);
  const popupService = useAppSelector((state: any) => state.services).popupsService;
  const popup = popupsData.popup;
  const popupPostPoned = getUserSetting(userData.userSettings, "customizations", ["app", "popup_postpone"]);
  const isPopupPostPoned = popupPostPoned ? moment(popupPostPoned).add(config.NEWS_POSTPONE_LIMIT,"minutes").isAfter(moment(),"minutes") : false;

  const limit = config.NEWS_LIMIT;
  const isLoading = useRef(false);

  const [state, setState, setStates] = useStates({
    news: [],
    isLoadingDefault: true,
    isLoadingMore: false,
    isEndOfScroll: false,
    page: 1,
  });

  const loadNews = useCallback((defaultNews?: boolean) => {
    if(state.isLoadingMore) return;
    if(state.isEndOfScroll && !defaultNews) return;
    let currentPage = state.page;
    const oldNews = defaultNews ? [] : state.news;
    if(defaultNews) {
      currentPage = 1;
      setStates({
        isEndOfScroll: false,
      });
    } else {              
      setStates({
        isEndOfScroll: false,
        isLoadingDefault: false,
        isLoadingMore: true,
      });
      currentPage++;
    } 
    if(currentPage !== state.page || currentPage === 1) {
      isLoading.current = true;
      setStates({
        isLoadingDefault: defaultNews
      });
      const settings = {
        page: currentPage,
        limit: limit,
      };
      popupService && popupService.listPopups(settings).then((result: any) => {
        if(result && result.data) {
          const response = result.data[config.JSONLD_DATA];
          const count = result.data[config.JSONLD_COUNT];
          if(count === 0) {
            setStates({
              isEndOfScroll: true,
              isLoadingMore: false,
            });
            setTimeout(() => { 
              setState("isLoadingDefault", false);
              isLoading.current = false;
            }, 1);           
          } else {
            const news = [].concat(oldNews, response);
            setStates({
              news: news,
              page: currentPage,
              isEndOfScroll: count < limit,
            });
            setTimeout(() => {  
              setStates({
                isLoadingDefault: false,
                isLoadingMore: false,
              });
              isLoading.current = false;
            }, 1); 
          }
        } 
      });
    }  
  }, [popupService, state.news, setState, setStates, state.isEndOfScroll, state.isLoadingMore, state.page, limit]);

  const onScrollView = (inView: any) => {
    if(inView) {
      if(!state.isLoadingMore && !state.isEndOfScroll) loadNews(false);
    }
  };

  const handleOnScroll = () => {
    document.dispatchEvent(new CustomEvent('scroll'))
  };

  useEffect(() => {
    if(!popup || isPopupPostPoned) {
      loadNews(true);
    }
  }, [loadNews, popup, isPopupPostPoned], [popup, isPopupPostPoned]);
  
  return (
    <div className={classes.newsPage} onScroll={handleOnScroll}>
      <div className={classes.wrapper}>
        <div className={classes.wrapperColumn}>
          <div className={classes.newsWrapper}>
          {
            state.news.length === 0 ? (
              <>
                {
                  (popup || isLoading.current) ? (
                    <div className={classes.spinner}>
                      <CircularProgress/>
                    </div>
                  ) : (
                    <NotFound title={t('no_news')} text={t('no_news_found')}/>
                  )
                }
              </>
            ) : (
              <>
                {
                  state.news.map((data: any, key: any) => {
                    return (key === state.news.length - 1) ? (
                      <div className={classes.popupCardWrapper} key={`k_${key}`}>
                        <InView className={classes.popupCardInView} onChange={(inView) => onScrollView(inView)}>
                          <PopupCard popupData={data} disableButtonMarkAsViewed={true} disableButtonPostPone={true}/>
                        </InView>
                        {
                          (state.isLoadingMore && !state.isEndOfScroll) ? (
                            <div className={classes.spinner}>
                              <CircularProgress/>
                            </div>
                          ) : null
                        }
                        {
                          state.isEndOfScroll ? ( 
                            <EndOfScroll text={t("no_more_news")}/>
                          ) : null
                        }
                      </div>
                    ) : (
                      <div className={classes.popupCardWrapper} key={`k_${key}`}>
                        <PopupCard popupData={data} disableButtonMarkAsViewed={true} disableButtonPostPone={true}/>
                      </div>
                    )
                  }
                  )
                }
              </>
            )
          }
          </div>
        </div>
        <Sidebar/>
      </div>
    </div>
  );
};

export default PageTemplate;