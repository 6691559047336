import React from 'react';
import useStyles from './style';
import { Button, MobileStepper, Step, StepButton, Stepper } from '@mui/material';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

type MobileType = {
  steps: any;
  currentStep: number;
  onStepChange: any;
  onComplete?: any;
  canNextStep?: boolean;
  showStepper: boolean;
  showFooter: boolean;
  footerContent: any;
  className?: any,
  classNameStepper?: any;
};

const Mobile: React.FunctionComponent<MobileType> = ({ steps, currentStep = 1, onStepChange, onComplete, canNextStep, showStepper = true, showFooter = true, footerContent, className, classNameStepper }) => {
  
  const { t } = useTranslation();
  
  const [state, setState] = useStates({
    mode: "view",
  });

  const totalSteps = steps.length;

  const activeStep = currentStep - 1;

  const classes = useStyles({
    mode: state.mode,
  });

  const handleStep = (step: any) => {
    if(state.mode === "view") {
      setState("mode", "click");
    } else {
      if(onStepChange) {
        setState("mode", "view");
        onStepChange(step);
      }
    }
  };

  const handleNext = () => {
    const step = currentStep + 1;
    handleStep(step);
  };

  const handleBack = () => {
    const step = currentStep - 1;
    handleStep(step);
  };

  const getStepData = (key: any) => {
    return steps[key];
  };

  return (
    <div className={`${classes.wizard} ${className ? className : ''}`}>
      {
        showStepper ? (
          <Stepper className={`${classes.stepper} ${classNameStepper ? classNameStepper : ''}`} nonLinear activeStep={activeStep} orientation="vertical" connector={state.mode === "view" ? null : undefined}>
            {
              steps.map((data: any, key: any) => (
                <Step key={`k_${key}`} className={state.mode === "view" ? (key === activeStep ? classes.stepVisible : classes.stepHidden) : ""}>
                  <StepButton className={classes.stepButton} optional={data.optional ? (<p>{t('optional')}</p>) : null} color="inherit" onClick={() => handleStep(key + 1)} disabled={state.mode === "click" && !getStepData(key).canSelect}>
                    {data.label}
                  </StepButton>
                </Step>
              ))
            }
          </Stepper>
        ) : null
      }
      <div className={state.mode === "view" ? classes.contentWrapper : classes.noContentWrapper}>
        {
          steps.map((data: any, key: any) => {
            if(key === activeStep) {
              return (
                <div className={classes.content} key={`k_${key}`}>{data.content}</div>
              );
            } else {
              return null;
            }
          })
        }
      </div>
      {
        showFooter ? (
          <>
          {
              footerContent ? (
                <>
                  {footerContent} 
                </>
              ) : (
                <MobileStepper
                  variant="text"
                  steps={totalSteps}
                  position="static"
                  activeStep={activeStep}
                  nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === totalSteps - 1}>
                      {t('next')}
                    </Button>
                  }
                  backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                      {t('back')}
                    </Button>
                  }
                />
                )
              }
          </>
        ) : null
      }
    </div>
  );
}

export default Mobile;
