import AbsenceInfoItem from '../../../Items/AbsenceInfoItem';
import IconButton from 'src/components/Buttons/IconButton';
import moment from '../../../../utils/moment';
import React from 'react';
import SVG from '../../../Images/SvgRenderer';
import theme from '../../../../ui/theme';
import useStyles from './style';
import { compareDates } from '../../../../utils/date';
import { getCalendarBackgroundColor } from 'src/utils/useFunctions';
import { getUserSetting } from 'src/utils/useUser';
import { isCypress } from '../../../../utils/useCypress';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

type DateItemType = {
  index?: any;
  date?: any;
  onClickExcuse?: any;
  onClickDate?: any;
};

const DateItem: React.FunctionComponent<DateItemType> = ({index, date, onClickExcuse, onClickDate}) => {

  const { t } = useTranslation();
  const classes = useStyles({
    getCalendarBackgroundColor: getCalendarBackgroundColor(date, index, theme),
  });

  const excusenotesData = useAppSelector((state: any) => state.excusenotes);
  const userData = useAppSelector((state: any) => state.user);

  const curChild = excusenotesData.currentChild;
  const curDate = excusenotesData.date;
  const list = excusenotesData.list;

  const absenceData = (list.length > 0 && curChild !== null && curChild !== undefined) ? (list.filter((data: any) => data.childID === curChild.childID).length > 0 ? (list.find((data: any) => data.childID === curChild.childID).dates.filter((data: any) => moment(data.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")).length === 1 ? (list.find((data: any) => data.childID === curChild.childID).dates.find((data: any) => moment(data.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"))) : []) : []) : [];

  const isTooltipEnabled = getUserSetting(userData.userSettings, "addons", ["excuse_notes", "excuse_notes_tooltips"]);

  const handleClick = (e: any) => {
    if(Object.keys(absenceData).length > 0) {
      handleClickExcuse(e);
    } else {
      if(date.isSameOrAfter(moment(),'day') && date.isSameOrBefore(moment().add(180,'days'),'day')) {
        handleClickDate(e);
      }
    }
  };

  const handleClickExcuse = (e: any) => {
    e.stopPropagation();
    onClickExcuse(absenceData.absenceID, absenceData.childID, "both");
  };

  const handleClickDate = (e: any) => {
    e.stopPropagation();
    if(curChild !== null && curChild !== undefined && (curChild.isArchived || !curChild.isInAnyActiveClass)) return;
    if(date.isSameOrAfter(moment(),'day')) onClickDate(date);
  };

  return (curChild !== null && curChild !== undefined) ? (
    <div className={`${classes.root} ${(curChild !== null && curChild !== undefined && !curChild.isArchived && curChild.isInAnyActiveClass) ? (Object.keys(absenceData).length > 0 ? 'open' : date.isSameOrAfter(moment(),'day') && date.isSameOrBefore(moment().add(180,'days'),'day') ? 'open' : '') : (Object.keys(absenceData).length > 0 ? 'open' : '')}`} onClick={handleClick} data-cy={isCypress() ? "excuseNotesDayItem-" + moment(date).format("YYYY-MM-DD") : null}>
      { 
        compareDates(date, moment()) ? (
          <p className={`${classes.date} ${moment(date).month() !== moment(curDate).month() ? 'fontGray' : null} ${(moment(date).day() === 6 || moment(date).day() === 0) ? 'fontDarkGray' : null}`}>
            <span className={classes.today} data-clarity-unmask="true">{date.date()}</span>
          </p>
        ) : (
          <p className={`${classes.date} ${moment(date).month() !== moment(curDate).month() ? 'fontGray' : null} ${(moment(date).day() === 6 || moment(date).day() === 0) ? 'fontDarkGray' : null}`} data-clarity-unmask="true">
            {moment(date).date()}
          </p>
        ) 
      }
      { 
        Object.keys(absenceData).length > 0 ?
          absenceData.absencePartID === 2 ? (
            <AbsenceInfoItem absencePartID={absenceData.absencePartID} morningAbsenceStatusID={absenceData.absenceTypeID} isLate={absenceData.isLate} absenceApprovalStatusID={absenceData.absenceApprovalStatusID} tooltip={isTooltipEnabled}/>          
          ) : absenceData.absencePartID === 3 ? (
            <AbsenceInfoItem absencePartID={absenceData.absencePartID} afternoonAbsenceStatusID={absenceData.absenceTypeID} isLate={absenceData.isLate} absenceApprovalStatusID={absenceData.absenceApprovalStatusID} tooltip={isTooltipEnabled}/>          
          ) : (
            <AbsenceInfoItem absencePartID={absenceData.absencePartID} morningAbsenceStatusID={absenceData.absenceTypeID} afternoonAbsenceStatusID={absenceData.absenceTypeID} isLate={absenceData.isLate} absenceApprovalStatusID={absenceData.absenceApprovalStatusID} tooltip={isTooltipEnabled}/>           
          )  
        : (date.isSameOrAfter(moment(),'day') && moment(date).isSameOrBefore(moment().add(180,'days'),'day') && curChild !== null && curChild !== undefined && !curChild.isArchived && curChild.isInAnyActiveClass) ? (
          <IconButton className={classes.addButton} data-cy={isCypress() ? "excuseNotesDayAddButton-" + moment(date).format("YYYY-MM-DD") : null} onClick={handleClickDate} tooltip={isTooltipEnabled ? t('excuse_note_create') : null} tooltipPosition='top' tooltipMaxWidth={400}>
            <SVG src="plus"/>
          </IconButton>
        ) : null
      }
    </div>
  ) : null;
};

export default DateItem;