import { createUseStyles } from 'react-jss';

interface Props {
  columnHeaderHeight: any;
  isFooterEnabled: any;
};

const useStyles = createUseStyles((theme: any) => ({
  dataTableWrapper: {
    maxWidth: '100%',
    width: '100%',
    overflow: 'visible',
    position: 'relative',
  },
  dataTable: {
    width: '100%',
    maxWidth: '100%',
    borderWidth: '0',
    overflow: 'visible',
    gap: '8px',
    borderRadius: (_: Props) => '',
    '& .MuiDataGrid-toolbarContainer': {
      backgroundColor: theme.colors.white,
      boxShadow: theme.shadows[2],
      borderRadius: '20px',
      padding: '8px 12px',
      '&:empty': {
        display: 'none',
      },
    },
    '& .MuiDataGrid-main': {
      boxShadow: theme.shadows[2],
      borderRadius: '20px',
      '& .MuiCircularProgress-svg': {
        color: theme.colors.primaryBlue[500],
      },
      '& .MuiDataGrid-virtualScroller': {
        width: '100%',
        maxWidth: '100%',
      },
      '& .MuiDataGrid-virtualScrollerRenderZone': {
        width: '100%',
        maxWidth: '100%',
      },
      '& .MuiDataGrid-filler': {
        backgroundColor: theme.colors.white,
      },
      '& .MuiDataGrid-columnHeaders': {
        maxWidth: '100%',
        minHeight: (props: Props) => `${props.columnHeaderHeight}px`,
        '& > div': {
          backgroundColor: theme.colors.white,
          borderRadius: '20px 20px 0px 0px',
          minHeight: (props: Props) => `${props.columnHeaderHeight}px`,
          maxWidth: '100%',
        },
      },
      '& .MuiDataGrid-columnHeader': {
        outline: 'none',
        fontSize: '14px',
        fontWeight: '600',
        '& > .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
      },
      '& .MuiDataGrid-row': {
        backgroundColor: theme.colors.white,
        maxWidth: '100%',
        '&:hover': {
          backgroundColor: theme.colors.grey[40],
        },
      },
      '& .MuiDataGrid-cell': {
        outline: 'none !important',
        gap: '6px',
        padding: '0 20px',
        '&.clickable': {
          cursor: 'pointer',
        },
        '& svg': {
          width: '0',
          height: '0',
        },
        '& > div.cellWrapper': {
          display: 'flex',
          alignItems: 'center',
          gap: '0px',
          position: 'relative',
          textAlign: 'left',
          '&.footer': {
            fontWeight: '600',
          },
          '& span': {
            display: 'flex',
            '&:not(.icon)': {
              paddingRight: '24px',
            },
            '&.icon': {
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              right: '0',
              '&.default': {
                color: theme.colors.grey[420],
              },
              '&.primary': {
                color: theme.colors.primaryBlue[400],
              },
              '&.info': {
                color: theme.colors.primaryBlue[400],
              },
              '&.success': {
                color: theme.colors.systemGreen[400],
              },
              '&.warning': {
                color: theme.colors.systemOrange[400],
              },
              '&.danger': {
                color: theme.colors.systemRed[400],
              },
              '&.tooltip': {
                cursor: 'help',
                '&:hover': {
                  '&.default': {
                    color: theme.colors.grey[450],
                  },
                  '&.primary': {
                    color: theme.colors.primaryBlue[500],
                  },
                  '&.info': {
                    color: theme.colors.primaryBlue[500],
                  },
                  '&.success': {
                    color: theme.colors.systemGreen[500],
                  },
                  '&.warning': {
                    color: theme.colors.systemOrange[500],
                  },
                  '&.danger': {
                    color: theme.colors.systemRed[500],
                  },
                },
              },
              '& > svg': {
                color: 'inherit',
                width: '18px',
                height: '18px',
                transition: 'color 0.25s',
              },
            },
          },
        },
      },
      '& .MuiDataGrid-actionsCell': {
        '& .MuiButtonBase-root': {
          '& > svg': {
            color: theme.colors.grey[650],
            width: '24px',
            height: '24px',
            transition: 'color 0.25s',
          },
        },
        '&:hover':{
          '& .MuiButtonBase-root': {
            backgroundColor: 'transparent',
            '& > svg': {
              color: theme.colors.grey[800],
            },
          },
        },
      },
    },
  },
  popper: {
    '& > .MuiPaper-root': {
      backgroundColor: 'transparent',
      boxShadow: 'unset',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[310],
      borderRadius: '12px',
      '& > .MuiList-root': {
        backgroundColor: theme.colors.white,
        borderRadius: 'inherit',
        boxShadow: theme.shadows[2],
        padding: '0',
        overflow: 'hidden',
        '& > .MuiMenuItem-root': {
          minHeight: '39px',
          fontSize: '16px',
          '& + hr': {
            margin: '0',
          },
          '& > .MuiListItemIcon-root': {
            '& > svg': {
              color: theme.colors.grey[600],
              width: '24px',
              height: '24px',
              transition: 'color 0.25s',
            },
          },
          '& > .MuiListItemText-root': {
            fontSize: 'inherit',
            '& > span': {
              fontSize: 'inherit',
            },
          },
          '&:hover':{
            '& > .MuiListItemIcon-root': {
              '& > svg': {
                color: theme.colors.grey[800],
              },
            },
          },
        },
      },
    },
    '& .MuiDataGrid-columnsManagement': {
      '& > .MuiFormControlLabel-root': {
        '& .Mui-disabled': {
          display: 'none',
        },
      },
    },
    '& .MuiDataGrid-panelWrapper': {
      backgroundColor: theme.colors.white,
      borderRadius: '12px',
      boxShadow: theme.shadows[2],
      margin: '8px',
    },
  },
  columnMenu: {
    borderRadius: '12px', 
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    '& > .MuiMenuItem-root': {
      '& + hr': {
        display: 'block',
      },
    },
    '& > hr': {
      display: 'none',
    }
  },
  footer: {
    backgroundColor: 'transparent',
    borderRadius: '0px 0px 20px 20px',
    border: '0',
    '& > .MuiTablePagination-root': {
      width: '100%',
    },
    '& .MuiTablePagination-input': {
      backgroundColor: theme.colors.white,
      borderRadius: '6px',
    },
  },
  pagination: {
    display: 'flex',
    '& > .MuiToolbar-root': {
      width: '100%',
      padding: '0',
      '& .MuiTablePagination-spacer': {
        display: 'none',
      }, 
    },
  },
  paginationMenu: {
    marginLeft: 'auto !important',
    '& > ul': {
      gap: '4px',
    },
  },
  paginationItem: {
    color: theme.colors.grey[800],
    borderRadius: '8px',
    borderWidth: '2px',
    width: '32px',
    height: '32px',
    padding: '0',
    minWidth: 'unset',
    fontSize: '16px',
    margin: '0',
    '&:not(.Mui-selected)': {
      borderColor: 'transparent',
    },
    '&.Mui-selected': {
      color: theme.colors.primaryBlue[500],
      borderColor: theme.colors.primaryBlue[500],
      backgroundColor: 'transparent',
      '&:hover': {
        color: theme.colors.primaryBlue[500],
        backgroundColor: 'transparent',
      },
    },
    '&:hover': {
      color: theme.colors.primaryBlue[500],
      backgroundColor: 'transparent',
    },
    '& > svg': {
      width: '16px',
      height: '16px',
    },
  },
  smallButton: {
    height: '32px',
    minWidth: '0px',
    padding: '5px 12px',
    '& > svg': {
      color: theme.colors.primaryBlue[250],
      width: '24px !important',
      height: '24px !important',
      transition: 'color 0.25s',
    },
    '&:hover':{
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
}));

export default useStyles;