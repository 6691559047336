import CircularProgress from '@mui/material/CircularProgress';
import DateItem from './DateItem';
import moment from '../../../utils/moment';
import React, { useMemo } from 'react';
import useStyles from './style';
import { getUserSetting } from 'src/utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { moveLastToFirstInArray } from 'src/utils/useFunctions';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useTranslation } from 'react-i18next';
import { weekdaysMin } from '../../../constants/weekdays';

type CalendarType = {
  className?: any;
  isLoading?: any;
  handleOpenExcuseDetail: any;
  handleOpenAddAbsence: any;
};

const Calendar: React.FunctionComponent<CalendarType> = ({ className, isLoading = false, handleOpenExcuseDetail, handleOpenAddAbsence }) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  const excusenotesData = useAppSelector((state: any) => state.excusenotes);
  const userData = useAppSelector((state: any) => state.user);
  
  const curDate = excusenotesData.date;
  const windowHandler: any = window;

  const firstDayInWeek = getUserSetting(userData.userSettings, "customizations", ["calendar", "first_day_in_week"]);

  const weekdays: any = firstDayInWeek === 0 ? moveLastToFirstInArray(weekdaysMin) : weekdaysMin;

  const firstDate = useMemo(() => {
    const startOfMonth = moment(curDate).date(0);
    return moment(startOfMonth).subtract(moment(startOfMonth).day(), 'days');
  }, [curDate]);

  useEffect(() => {
    windowHandler.isCalendarReady = !isLoading;
  }, [windowHandler, isLoading], [isLoading]);

  return (
    <div className={`${classes.root} ${className ? className : null}`} data-cy={isCypress() ? (isLoading ? "CalendarIsLoading" : "CalendarIsLoaded") : null}>
      {
        isLoading ? (
          <div className={classes.loadingWrapper}>
            <div className={classes.spinner}>
              <CircularProgress/>
            </div>
          </div>
        ) : null
      }
      <div className={classes.weekdayHeader}>
        {
          weekdays.map((weekday: any, key: any) => (
            <div className={classes.weekday} key={`k_${key}`}>
              <p>{t(weekday[0].toLowerCase() + weekday.slice(1))}</p>
            </div>
          ))
        }
      </div>
      <div className={classes.dateWrapper}>
        {
          firstDate && new Array(42).fill('').map((_, key) => {
            return (
              <DateItem
                index={key}
                key={`k_${key}`}
                date={moment(firstDate).add(key + firstDayInWeek, 'days')}
                onClickExcuse={(absenceID: any, childID: any, type: any) => handleOpenExcuseDetail(absenceID, childID, type)}
                onClickDate={(date: any) => handleOpenAddAbsence(date)}
              />
            );
          })
        }
      </div>
    </div>
  );
};

export default Calendar;