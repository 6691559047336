import ChildrenSelect from '../Selects/ChildrenSelect';
import CircularProgress from '@mui/material/CircularProgress';
import moment from '../../utils/moment';
import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { setAttendanceSelectedChildren } from '../../store/actions/attendance.actions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'src/utils/useMemo';

const useStyles = createUseStyles((theme: any) => ({
  spinner: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  customChildrenSelect: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '75%',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
      maxWidth: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '85%',
      maxWidth: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  customTopWrapper: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
  },
  customSearch: {
    padding: '24px 24px 0 24px',
  },
  customContainer: {
    margin: '0',
  },
  customSchoolList: {
    maxHeight: 'unset !important',
  },
  notFound: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      maxWidth: '80%',
    },
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '36px',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '24px',
      color: theme.colors.grey[650],
      fontSize: '16px',
      marginBottom: '0',
    },
  },
}));

type AttendanceType = {
  isLoaded: boolean;
  isReloaded: boolean;
  presentChildren: any;
  classesList: any;
  childrenList: any;
  defaultChildrenList?: any;
  handleUpdateState?: any;
};

const Attendance: React.FunctionComponent<AttendanceType> = ({isLoaded, isReloaded, presentChildren, classesList, childrenList, defaultChildrenList, handleUpdateState}) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const attendanceData = useAppSelector((state: any) => state.attendance);
  const dataData = useAppSelector((state: any) => state.data);
  const date = attendanceData.date;
  const selectedChildren = attendanceData.selectedChildren;
  const selectedChildrenRef = useRef(selectedChildren);

  const getClassData = useMemo(() => (classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  }, [dataData.classes]);

  const getChildData = useMemo(() => (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const classesListData = classesList.map((item: any) => {
    return {...item, sortOrder: getClassData(item.classID).sortOrder}; 
  });

  const childrenListData = childrenList.map((item: any) => {
    return {...item, displayName: getChildData(item.childID).displayName}; 
  });

  classesListData.sort((a: any, b: any) => { if (a.sortOrder === b.sortOrder) return a.classID - b.classID; return a.sortOrder - b.sortOrder; });

  childrenListData.sort((a: any, b: any) => {
    const nameA = a.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    const nameB = b.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    if(nameA === nameB) return a.childID - b.childID;
    return nameA > nameB ? 1 : -1;
  });

  useEffect(() => {
    selectedChildrenRef.current = selectedChildren;
  }, [selectedChildren]);

  const getDefaultChildData = (childID: any, classID: any) => {
    return defaultChildrenList.filter((item: any) => item.childID === childID && item.classID[0] === classID).length === 1 ? defaultChildrenList.find((item: any) => item.childID === childID && item.classID[0] === classID) : [];
  };
  
  const onClickChild = (e: any, data: any, allSelectedChildren: any) => {
    e.preventDefault();
    if(data.classData.attendanceMethod === "realtime") {
      const count = selectedChildrenRef.current.filter((theChild: any) => theChild.childID === data.child.childID && theChild.classID === data.class.classID && data.school).length;
      if(count === 0) { 
        const selectedChild = {
          childID: data.child.childID,
          classID: data.class.classID,
          isPresent: allSelectedChildren.filter((item: any) => JSON.stringify(item) === JSON.stringify({childID: data.child.childID, classID: data.class.classID})).length === 1 ? true : false,
        };
        const changedChildren = [...selectedChildrenRef.current, selectedChild];
        dispatch(setAttendanceSelectedChildren(changedChildren));
        selectedChildrenRef.current = changedChildren;
      } else {
        const changedChildren = selectedChildrenRef.current.map((theChild: any) => {
          if(theChild.childID === data.child.childID && theChild.classID === data.class.classID) {
            return null;
          } else {
            return theChild; 
          }
        }).filter((item: any) => item !== null);
        dispatch(setAttendanceSelectedChildren(changedChildren));
        selectedChildrenRef.current = changedChildren;
      } 
    } else {
      const count = selectedChildrenRef.current.filter((theChild: any) => theChild.childID === data.child.childID && theChild.classID === data.class.classID && data.school).length;
      const defaultPlanned = getDefaultChildData(data.child.childID, data.class.classID).planned;
      if(count === 0) {
        const selectedChild = {
          childID: data.child.childID,
          classID: data.class.classID,
          planned: data.childData.planned,
        };
        const changedChildren = [...selectedChildrenRef.current, selectedChild];
        dispatch(setAttendanceSelectedChildren(changedChildren));
        selectedChildrenRef.current = changedChildren;
        if(handleUpdateState) {
          const newChildrenlistData = childrenListData.map((item: any) => {
            if(item.childID === data.child.childID && item.classID[0] === data.class.classID) {
              return {...item, planned: data.childData.planned};
            } else {
              return item;
            } 
          });
          handleUpdateState("childrenList", newChildrenlistData);
        }
      } else {
        if(data.childData.planned === defaultPlanned) {
          const changedChildren = selectedChildrenRef.current.map((theChild: any) => {
            if(theChild.childID === data.child.childID && theChild.classID === data.class.classID) {
              return null;
            } else {
              return theChild; 
            }
          }).filter((item: any) => item !== null);
          dispatch(setAttendanceSelectedChildren(changedChildren));
          selectedChildrenRef.current = changedChildren;
          if(handleUpdateState) {
            const newChildrenlistData = childrenListData.map((item: any) => {
              if(item.childID === data.child.childID && item.classID[0] === data.class.classID) {
                return {...item, planned: defaultPlanned};
              } else {
                return item;
              } 
            });
            handleUpdateState("childrenList", newChildrenlistData);
          }
        } else {
          const changedChildren = selectedChildrenRef.current.map((theChild: any) => {
            if(theChild.childID === data.child.childID && theChild.classID === data.class.classID) {
              return {...theChild, planned: data.childData.planned};
            } else {
              return theChild; 
            }
          });
          dispatch(setAttendanceSelectedChildren(changedChildren));
          selectedChildrenRef.current = changedChildren;
          if(handleUpdateState) {
            const newChildrenlistData = childrenListData.map((item: any) => {
              if(item.childID === data.child.childID && item.classID[0] === data.class.classID) {
                return {...item, planned: data.childData.planned};
              } else {
                return item;
              } 
            });
            handleUpdateState("childrenList", newChildrenlistData);
          }
        }
      }
    }    
  };

  const onClickChildren = (e: any, schoolData: any, allSelectedChildren: any) => {
    e.preventDefault();
    let allChangedChildren = selectedChildrenRef.current;
    let allPresentChildren = presentChildren;
    schoolData.forEach((data: any) => {
      if(data.classData.attendanceMethod === "realtime") {
        const count = allChangedChildren.filter((theChild: any) => theChild.childID === data.child.childID && theChild.classID === data.class.classID && data.school).length;
        const defaultPresent = getDefaultChildData(data.child.childID, data.class.classID).isPresent;
        if(count === 0) { 
          const selectedChild = {
            childID: data.child.childID,
            classID: data.class.classID,
            isPresent: allSelectedChildren.filter((item: any) => JSON.stringify(item) === JSON.stringify({childID: data.child.childID, classID: data.class.classID})).length === 1 ? true : false,
          };
          allChangedChildren = [...allChangedChildren, selectedChild];
          if(allPresentChildren.filter((item: any) => item.childID === data.child.childID && item.classID === data.class.classID).length === 0) {
            allPresentChildren = [...allPresentChildren, selectedChild];
          } else {
            allPresentChildren = allPresentChildren.map((theChild: any) => {
              if(theChild.childID === data.child.childID && theChild.classID === data.class.classID) {
                if(selectedChild.isPresent) {
                  return selectedChild;
                } else {
                  return null;
                }
              } else {
                return theChild;
              }
            }).filter((item: any) => item !== null);
          }
        } else {
          if(data.childData.isPresent === defaultPresent) {
            allChangedChildren = allChangedChildren.map((theChild: any) => {
              if(theChild.childID === data.child.childID && theChild.classID === data.class.classID) {
                return null;
              } else {
                return theChild; 
              }
            }).filter((item: any) => item !== null);
            if(allPresentChildren.filter((item: any) => item.childID === data.child.childID && item.classID === data.class.classID).length === 0) {
              const selectedChild = {
                childID: data.child.childID,
                classID: data.class.classID,
                isPresent: allSelectedChildren.filter((item: any) => JSON.stringify(item) === JSON.stringify({childID: data.child.childID, classID: data.class.classID})).length === 1 ? true : false,
              };
              allPresentChildren = [...allPresentChildren, selectedChild];
            } else {
              allPresentChildren = allPresentChildren.map((theChild: any) => {
                if(theChild.childID === data.child.childID && theChild.classID === data.class.classID) {
                  return null;
                } else {
                  return theChild; 
                }
              }).filter((item: any) => item !== null);
            }
          } else {
            allChangedChildren = allChangedChildren.map((theChild: any) => {
              if(theChild.childID === data.child.childID && theChild.classID === data.class.classID) {
                return {...theChild, isPresent: data.childData.isPresent};
              } else {
                return theChild; 
              }
            });
            allPresentChildren = allPresentChildren.map((theChild: any) => {
              if(theChild.childID === data.child.childID && theChild.classID === data.class.classID) {
                return {...theChild, isPresent: data.childData.isPresent};
              } else {
                return theChild; 
              }
            });
          }
        } 
      } else {
        const count = allChangedChildren.filter((theChild: any) => theChild.childID === data.child.childID && theChild.classID === data.class.classID && data.school).length;
        const defaultPlanned = getDefaultChildData(data.child.childID, data.class.classID).planned;
        if(count === 0) {
          const selectedChild = {
            childID: data.child.childID,
            classID: data.class.classID,
            planned: data.childData.planned,
          };
          allChangedChildren = [...allChangedChildren, selectedChild];
          allPresentChildren = [...allPresentChildren, selectedChild];
        } else {
          if(data.childData.planned === defaultPlanned) {
            allChangedChildren = allChangedChildren.map((theChild: any) => {
              if(theChild.childID === data.child.childID && theChild.classID === data.class.classID) {
                return null;
              } else {
                return theChild; 
              }
            }).filter((item: any) => item !== null);
            allPresentChildren = allPresentChildren.map((theChild: any) => {
              if(theChild.childID === data.child.childID && theChild.classID === data.class.classID) {
                return null;
              } else {
                return theChild; 
              }
            }).filter((item: any) => item !== null);
          } else {
            allChangedChildren = allChangedChildren.map((theChild: any) => {
              if(theChild.childID === data.child.childID && theChild.classID === data.class.classID) {
                return {...theChild, planned: data.childData.planned};
              } else {
                return theChild; 
              }
            });
            allPresentChildren = allPresentChildren.map((theChild: any) => {
              if(theChild.childID === data.child.childID && theChild.classID === data.class.classID) {
                return {...theChild, planned: data.childData.planned};
              } else {
                return theChild; 
              }
            });
          }
        }
      }
    });
    dispatch(setAttendanceSelectedChildren(allChangedChildren));
    selectedChildrenRef.current = allChangedChildren;
    if(handleUpdateState) {
      let newChildrenlistData = childrenListData;
      schoolData.forEach((data: any) => {
        newChildrenlistData = newChildrenlistData.map((item: any) => {
          if(item.childID === data.child.childID && item.classID[0] === data.class.classID) {
            return {...item, planned: data.childData.planned};
          } else {
            return item;
          } 
        });
      });
      handleUpdateState("childrenList", newChildrenlistData);
      const newPresentChildren = allPresentChildren.map((item: any) => {
        return { childID: item.childID, classID: item.classID };
      });
      handleUpdateState("presentChildren", newPresentChildren);
    }
  };

  return isLoaded ? (
    <ChildrenSelect
      onClickChild={onClickChild}
      onClickSelectAll={onClickChildren}
      isMultipleSelect={false}
      isSelectAll={false}
      isSelectInAllClass={false}
      defaultClasses={classesListData.map((item: any) => { return { classID: item.classID, schoolID: item.schoolID }; })}
      defaultChildren={childrenListData.map((item: any) => { return { childID: item.childID, classID: item.classID, schoolID: item.schoolID }; })}
      defaultClassesData={classesListData}
      defaultChildrenData={childrenListData}
      selectedChildren={presentChildren}
      mode="attendance"
      isShowAttendanceCount={true}
      isAllowSearch={true}
      isAllowArchived={false}
      isAllowArchivedToggle={false}
      isDisableClick={(!moment(date).isSame(moment(),'day'))}
      customClasses={{
        childrenSelect: classes.customChildrenSelect,
        topWrapper: classes.customTopWrapper,
        search: classes.customSearch,
        container: classes.customContainer,
        schoolList: classes.customSchoolList,
      }}
      customNoResults={
        (
          <div className={classes.notFound}>
            <img src="/resources/images/noresults.png" alt={'no_results'}/>
            <span>{t('no_attendance_day')}</span>
            <p>{t('attendance_not_exist',{day: moment().format("DD.MM.YYYY")})}</p>
          </div>
        )
      }
      isReload={isReloaded}
    />  
  ) : (
    <div className={classes.spinner}>
      <CircularProgress/>
    </div>
  );
};

export default Attendance;