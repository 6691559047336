import { createUseStyles } from "react-jss";

interface Props {
  getCalendarBackgroundColor: any,
};

const useStyles = createUseStyles((theme: any) => ({
  dateItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    width: 'calc((100% - 6px) / 7)',
    height: '80px',
    gap: '10px',
    backgroundColor: (props: Props) => props.getCalendarBackgroundColor,
    borderRight: `1px solid ${theme.colors.grey[335]}`,
    borderBottom: `1px solid ${theme.colors.grey[335]}`,
    '&:nth-child(7n)': {
      borderRight: 'none',
    },
    '&:nth-last-child(-n+7)': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: theme.colors.grey[125],
      '& div': {
        '& > div': {
          opacity: '1',
        }
      }
    },
    '& .fontGray': {
      color: '#bcbcbc',
      fontWeight: 'normal',
    },
    '& .fontDarkGray': {
      color: theme.colors.grey[550],
      fontWeight: 'normal',
    },
    '&:nth-last-of-type(7)': {
      borderRadius: '0px 0px 0px 20px',
      [theme.breakpoints.down('md')]: {
        borderRadius: "0px",
      }, 
    },
    '&:nth-last-of-type(1)': {
      borderRadius: '0px 0px 20px 0px',
      [theme.breakpoints.down('md')]: {
        borderRadius: "0px",
      }, 
    },
  },
  date: {
    width: '100%',
    textAlign: 'center',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '0px',
    fontWeight: 'bold',
    '&.isDisabled': {
      color: '#bcbcbc',
      fontWeight: 'normal',
    },
    '&.isEnabled': {
      fontWeight: 'normal',
      color: theme.colors.black,
    },
  },
  today: {
    borderRadius: '100%',
    width: '28px',
    height: '28px',
    backgroundColor: theme.colors.grey[250],
    color: theme.colors.primaryBlue[500],
    fontWeight: '800',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '-4px auto',
  }
}));

export default useStyles;