import ClassesInput from 'src/components/Forms/ClassesInput';
import ClassesSelect from 'src/components/Selects/ClassesSelect';
import DatePicker from 'src/components/DatePickers/Default';
import React from 'react';
import Tooltip from 'src/components/Layouts/Tooltip';
import useStyles from './style';
import { useStates } from 'src/utils/useState';
import { getQueryParams } from 'src/utils/useFunctions';
import { useLocation } from 'react-router';

type FilterType = {
  mode: any;
  name: any;
  options: any;
  title: any;
  type: any;
  onChange: any;
  defaultValue: any;
};

const Filter: React.FunctionComponent<FilterType> = ({ mode, name, options, title, type, onChange, defaultValue }) => {

  const classes = useStyles();
  const location = useLocation();

  const queryParams = getQueryParams(location);
  const presetValue = queryParams.get(name);

  const [state, setState] = useStates({
    value: presetValue ? presetValue : defaultValue,
    isClassesSelectOpen: false,
  });

  const handleSelectDate = (value: any) => {
    handleOnChange(value);
  };

  const handleSelectClasses = (value: any) => {
    const values = value.map((item: any) => { return item.classID; }).join(",");
    handleOnChange(values);
  };

  const handleSelectClassesFromModal = (value: any) => {
    const values = value.map((item: any) => { return item.classID; }).join(",");
    handleOnChange(values);
  };

  const handleToggleClassesSelect = () => {
    setState("isClassesSelectOpen", !state.isClassesSelectOpen);
  };

  const handleOnChange = (value: any) => {
    setState("value", value);
    onChange(name, value);
  };

  return (
    <div className={classes.filterWrapper}>
      <Tooltip title={title} maxWidth={400} position='bottom'>
        <span>
          {
            type === "datePicker" ? (
              <>
                {
                  mode === "single" ? (
                    <DatePicker presetDate={state.value} setDate={handleSelectDate}/>
                  ) : null
                }
              </>
            ) : type === "classSelect" ? (
              <>
                <ClassesInput className={classes.classInputSelect} classNameWrapper={classes.classInputSelectWrapper} classNameChip={classes.classInputChip} selectedClasses={((state.value && typeof state.value === "string") ? state.value.split(",") : []).map((item: any) => { return { classID: parseInt(item) }; })} setSelectedClasses={handleSelectClasses} isDeletable={false} onClick={handleToggleClassesSelect}/>
                {
                  state.isClassesSelectOpen ? (
                    <ClassesSelect
                      isInModal={true}
                      isModalOpen={true}
                      modalTitle="classes"
                      modalOnClose={handleToggleClassesSelect}
                      modalOnSave={handleSelectClassesFromModal}
                      selectedClasses={((state.value && typeof state.value === "string") ? state.value.split(",") : []).map((item: any) => { return { classID: parseInt(item) }; })}
                      isSelectAll={mode === "multiple" ? true : false}
                      isMultipleSelect={mode === "multiple" ? true : false}
                      isAllowOnlyOneClassAtOnce={false}
                      isAllowArchived={false}
                      isAllowInactiveClasses={true}
                      isAllowArchivedToggle={true}
                      isAllowSearch={true}
                      isShowChildrenCounts={false}
                      defaultClasses={options.map((item: any) => { return { classID: item.value }; })}
                      mode="select"
                      modalAllowCancel={false}
                      modalAllowClose={true}
                      modalAllowClear={true}
                      modalAllowNoClass={true}
                    />
                  ) : null
                }
              </>
            ) : null
          } 
        </span>
      </Tooltip>
    </div>
  );
};

export default Filter;