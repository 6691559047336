import ChildrenListSelect from 'src/components/Selects/ChildrenListSelect';
import NotFound from 'src/components/Layouts/NotFound';
import PhotoArchiveItem from 'src/components/Items/PhotoArchiveItem';
import PremiumBanner from 'src/components/Layouts/PremiumBanner';
import React, { useRef } from 'react';
import Sidebar from 'src/components/Layouts/Sidebar';
import useStyles from './style';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const PageTemplate: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const classes = useStyles();
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);

  const photoArchiveWrapper: any = useRef(null);
  const photoArchiveWrapperClientWidth = (photoArchiveWrapper.current && photoArchiveWrapper.current.clientWidth) ? photoArchiveWrapper.current.clientWidth : 0;

  const getChildData = useMemo(() => (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const childrenData = (userData.membership.photoArchive && Array.isArray(userData.membership.photoArchive)) ? userData.membership.photoArchive.map((item: any) => {
    return getChildData(item.childID);
  }) : [];

  const [state, setState] = useStates({
    list: [],
    currentChild: childrenData[0],
    childrenListSelectWidth: photoArchiveWrapperClientWidth,
  });

  const setCurrentChild = (child: any) => {
    setState("currentChild", child);
  };
  

  const groupByYear = (data: any) => {
    return data.reduce((acc: any, item: any) => {
      const year = new Date(item.dateFrom).getFullYear();
      if(!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(item);
      return acc;
    }, {});
  };

  const sortByYearEntries = (data: any) => {
    return Object.entries(data).sort((a, b) => Number(b[0]) - Number(a[0]));
  };
  
  useEffect(() => {
    if(userData.membership.photoArchive && Array.isArray(userData.membership.photoArchive)) {
      const list = userData.membership.photoArchive.map((item: any) => {
        return { childID: item.childID, months:sortByYearEntries(groupByYear(item.months)) };
      });
      setState("list", list);
    }
  }, [setState], []);
  
  useEffect(() => {
    setState("childrenListSelectWidth", photoArchiveWrapperClientWidth);
  }, [browserData.width, photoArchiveWrapperClientWidth, setState], [browserData.width, photoArchiveWrapperClientWidth]);

  return (
    <>
      {
        !userData.membership.active ? (
          <PremiumBanner service="photoArchive" useBackdrop={state.list.length !== 0}/>
        ) : null
      }
      <div className={classes.photoArchivePage}>
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <div className={classes.childrenWrapper} ref={photoArchiveWrapper}>
              <ChildrenListSelect currentChild={state.currentChild} childrenData={childrenData} setCurrentChild={setCurrentChild} width={state.childrenListSelectWidth} isAllowArchived={true}/>
            </div>
            <div className={classes.infoBlock}>
              <span>{t('photo_archive_title')}</span>
              <p>{t('photo_archive_info')}.</p>
              <p>{t('photo_archive_download_info')} <em>({t('phoot_archive_download_month_info').toLowerCase()})</em>.</p>
            </div>
            {
              state.list.length > 0 ? (
                <div className={classes.childrenBlock}>
                  {                         
                    state.list.filter((item: any) => item.childID === state.currentChild.childID).map((item: any, key: any) => (
                      <div className={classes.childBlock} key={`k_${key}`}>
                        {
                          item.months.map((subItem: any, subKey: any) => (
                            <div className={classes.years} key={`k_${subKey}`}>
                              <span>{subItem[0]}</span>
                              <div className={classes.photoArchiveItems}>
                                {subItem[1].map((subSubItem: any, subSubKey: any) => (
                                  <PhotoArchiveItem key={`k_${subSubKey}`} childID={item.childID} dateFrom={subSubItem.dateFrom} dateTo={subSubItem.dateTo} count={subSubItem.count} canDownload={userData.membership.active}/>
                                ))}
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    ))         
                  }
                </div>
              ) : (
                <NotFound title={t('no_photo_archive')} text={t('no_photo_archive_found')}/>
              )
            }
          </div>
          {
            userData.membership.active ? (
              <Sidebar/>
            ) : null
          }
        </div>
      </div>
    </>
  );
};

export default PageTemplate;