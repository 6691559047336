import Calendar from '../../../components/Calendars/Substitutions';
import ChildrenListSelect from '../../../components/Selects/ChildrenListSelect';
import IconButton from 'src/components/Buttons/IconButton';
import moment from '../../../utils/moment';
import MonthPicker from 'src/components/DatePickers/Months';
import NormalButton from '../../../components/Buttons/NormalButton';
import React, { useCallback, useRef } from 'react';
import Sidebar from 'src/components/Layouts/Sidebar';
import SubstitutionCard from 'src/components/Cards/SubstitutionCard';
import SVG from '../../../components/Images/SvgRenderer';
import tabs from 'src/constants/tabs';
import TabsMenu from 'src/components/Menus/TabsMenu';
import WatchdogCard from 'src/components/Cards/WatchdogCard';
import { addToSubstitutionsList, addToSubstitutionsMonths, addToWatchdogs, addToWatchdogsMonths, clearSubstitutionsList, clearSubstitutionsMonths, clearWatchdogs, clearWatchdogsMonths, setChildData, setSubstitutionsCanAdd, setSubstitutionsCurrentChild, setSubstitutionsDate, setSubstitutionsFirstAvailableDate } from '../../../store/actions/substitutions.actions';
import { CircularProgress } from '@mui/material';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getQueryParams, getQueryString, getSchoolSettings, handleSum, isKey, monthsInDateRange } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { Navigate, useLocation } from 'react-router-dom';
import { setSubstitutionsDetailModal, setSubstitutionsHandleModal, setWatchdogDetailModal, setWatchdogHandleModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  substitutionsPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '0px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '0px',
    },
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    paddingBottom: '16px',
    '& > div': {
      [theme.breakpoints.down('xl')]: {
        width: '70%',
      },
    },
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  wrapperColumn: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      width: '70%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  substitutionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      gap: "0px",
    }, 
  },
  childrenWrapper: {
    display: 'flex',
    flex: '1 1 80%',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    width: '100%',
    position: 'relative',
    height: '42px',
    '& > button': {
      position: 'absolute',
      right: '0',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '8px',
      height: 'unset',
      '& > button': {
        position: 'unset',
        right: 'unset',
      },
    },
  },
  addButton: {
    position: 'fixed',
    right: '10%',
    bottom: '29px',
    [theme.breakpoints.down('lg')]: {
      right: '29px',
    },
    [theme.breakpoints.down('md')]: {
      bottom: '75px',
    },
    '& > button': {
      backgroundColor: theme.colors.primaryBlue[500],
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      height: '58px',
      width: '58px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > svg': {
        color: theme.colors.white,
        width: '20px',
        height: '19px',
      },
      '&:hover': {
        backgroundColor: theme.colors.primaryBlue[600],
      },
      '&:disabled': {
        backgroundColor: theme.colors.grey[600],
      },
    }
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  excuseNoteCardWrapper: {
    width: '100%',
  },
  notFound: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '36px',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '24px',
      color: theme.colors.grey[650],
      fontSize: '16px',
      marginBottom: '0',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    width: 'calc(100% - 24px)',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.chip,
    marginBottom: '24px',
    '& > span': {
      fontWeight: '600',
      fontSize: '18px',
      maxWidth: '100%',
    },
    '& > p': {
      fontSize: '16px',
    },
  },
  spinner: {
    display: "flex",
    width: '100%',
    height: '100%',
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  todayButton: {
    '& > span': {
      '& > svg': {
        width: '20px',
        height: '19px',
      },
    },
  },
  instructions: {
    color: theme.colors.grey[650],
    fontSize: '14px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    paddingBottom: '8px',
    '& > svg': {
      width: '16px',
      height: '16px',
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const substitutionsData = useAppSelector((state: any) => state.substitutions);
  const userData = useAppSelector((state: any) => state.user);
  const absenceService = useAppSelector((state: any) => state.services).absenceService;
  const substitutionsService = useAppSelector((state: any) => state.services).substitutionsService;
  const watchdogService = useAppSelector((state: any) => state.services).watchdogService;
  const filtersData = useAppSelector((state: any) => state.filters);
  const substitutionsWrapper: any = useRef(null);

  const queryString = getQueryString(location);
  const queryParams = getQueryParams(location);

  const urlChildID = queryParams.get("childID");
  const urlClassID = queryParams.get("classID");
  const urlDate = queryParams.get("date");
  const urlDayPart = queryParams.get("dayPart");
  const urlWatchdogID = queryParams.get("watchdogID");

  const substitutionsWrapperClientWidth = (substitutionsWrapper.current && substitutionsWrapper.current.clientWidth) ? substitutionsWrapper.current.clientWidth : 0;

  const schoolSettings = userData.schoolSettings;
  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;
  const childrenData = dataData.children.filter((item: any) => !item.isArchived && item.isInAnyActiveClass && item.attendanceProgram && item.attendanceProgram !== null && userAccessSchools.substitutions.includes(item.schoolID));
  const childrenWithAttendance = childrenData.length;
  
  const tabsItems = tabs.substitutions.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));

  const [state, setState] = useStates({
    isLoading: false,
    isChildDataLoaded: false,
    isFirstChildLoaded: false,
    childrenListSelectWidth: substitutionsWrapperClientWidth,
    substitutionsData: null,
    viewMode: tabsItems[0].value,
  });

  const setViewMode = (viewMode: any) => {
    setState("viewMode", viewMode);
  };

  const curChild = substitutionsData.currentChild;
  const curDate = substitutionsData.date;
  const list = substitutionsData.list;
  const watchdogs = substitutionsData.watchdogs;

  const childData = (curChild !== null && curChild !== undefined) ? dataData.children.find((child: any) => child.childID === curChild.childID) : [];
  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const getDateCredit = useCallback((date: any, customAllDays?: any, customSchoolDays?: any) => {
    const allDays = customAllDays ? customAllDays : substitutionsData.childData.allDays;
    const schoolDays = customSchoolDays ? customSchoolDays : substitutionsData.childData.schoolDays;
    const credits = allDays.filter((item: any) => item.substitutionRemaining && moment(item.date).isBetween(moment(date).subtract(schoolDays, 'days'), moment(date).add(schoolDays, 'days')) && moment(item.date).isBefore(moment(date),'day')).map((item: any) => {
      return item.substitutionRemaining;
    }).reduce(handleSum, 0);
    return credits;
  }, [substitutionsData.childData.allDays, substitutionsData.childData.schoolDays]);

  const parseDay = (day: any) => {
    if(day === 0) return 7;
    else return day;
  };

  const getDaySubstitutionData = (date: any) => {
    const isExist = substitutionsData.list.filter((item: any) => item.childID === curChild.childID).length > 0 ? substitutionsData.list.find((item: any) => item.childID === curChild.childID).dates.filter((item: any) => item.date === moment(date).format("YYYY-MM-DD")).length === 1 : false;
    if(isExist) {
      const substitutionData = substitutionsData.list.find((item: any) => item.childID === curChild.childID).dates.find((item: any) => item.date === moment(date).format("YYYY-MM-DD"));
      return substitutionData;
    } else {
      return [];
    }
  };

  const getDayWatchdogData = (date: any) => {
    const isExist = substitutionsData.watchdogs.filter((item: any) => item.childID === curChild.childID).length > 0 ? substitutionsData.watchdogs.find((item: any) => item.childID === curChild.childID).dates.filter((item: any) => item.date === moment(date).format("YYYY-MM-DD")).length === 1 : false;
    if(isExist) {
      const watchdogData = substitutionsData.watchdogs.find((item: any) => item.childID === curChild.childID).dates.find((item: any) => item.date === moment(date).format("YYYY-MM-DD"));
      return watchdogData;
    } else {
      return [];
    }
  };

  const getFirstAvailableDay = useCallback((customData?: any) => {
    const data = customData ? customData : state.substitutionsData;
    if(data) {
      const prevDays = moment().subtract(data.schoolDays, 'days');
      const nextDays = moment().add((180 + data.schoolDays), 'days');
      let currentDate = prevDays.clone();
      while(currentDate.isBefore(nextDays, 'day')) {
        const parsedDay = parseDay(moment(currentDate).day());
        if((getDateCredit(currentDate, data.allDays, data.schoolDays) > 0 && moment(currentDate).isAfter(moment(), 'days') && moment(currentDate).isSameOrBefore(moment(data.lastDay).add(data.schoolDays,'days'), 'days') && (data.attendanceProgram.days.filter((day: any) => day.day === parsedDay).length === 0 || (data.attendanceProgram.days.filter((day: any) => day.day === parsedDay).length !== 0 && data.attendanceProgram.days.find((day: any) => day.day === parsedDay).attendanceType !== "all")) && data.workingDays.indexOf(parsedDay) !== -1 && data.holidayDates.indexOf(parsedDay) === -1 && data.allDates.indexOf(parsedDay) === -1) && (getDaySubstitutionData(currentDate).length === 0 || (getDaySubstitutionData(currentDate).substitutionPartID !== 1 && data.attendanceProgram.days.filter((day: any) => day.day === parsedDay).length === 0)) && (getDayWatchdogData(currentDate).length === 0 || (getDayWatchdogData(currentDate).dayPart !== 1 && data.attendanceProgram.days.filter((day: any) => day.day === parsedDay).length === 0))) {
          return currentDate.format("YYYY-MM-DD");
        }
        currentDate = currentDate.add(1, 'day');
      }
      return null;
    }
  }, [state.substitutionsData, getDateCredit]);

  const getSubstitutionsData = useCallback((tempCurChild: any) => {
    if(getUserRole(userData.userObject.roleType) !== "teacher") {
      const schoolID = getChildData(tempCurChild.childID).schoolID;
      const schoolsDays = getSchoolSettings(schoolID, "modules", schoolSettings).substitutions && getSchoolSettings(schoolID, "modules", schoolSettings).substitutions.daysAbsenceValidity;
      const workingDays = getSchoolSettings(schoolID, 'workingDays', schoolSettings) === null ? [] : getSchoolSettings(schoolID, 'workingDays', schoolSettings);
      const holidayDates = getSchoolSettings(schoolID, 'holidayDates', schoolSettings) === null ? [] : getSchoolSettings(schoolID, 'holidayDates', schoolSettings);  
      substitutionsService && substitutionsService.listAvailableSubstitutions(tempCurChild.childID).then((result: any) => {
        if(result) {
          if(result.data) {
            if(result.data.absence) {
              const absenceData = result.data.absence;
              const prevDays = moment().subtract(schoolsDays, 'days').format("YYYY-MM-DD");
              const nextDays = moment().add((180 + schoolsDays), 'days').format("YYYY-MM-DD");
              absenceService && absenceService.listAbsenceRange(tempCurChild.childID, prevDays, nextDays).then((result: any) => {
                if(result) {
                  if(result.data) {
                    if(result.data.absence) {
                      const allAbsenceData = result.data.absence;
                      let credits = 0;
                      absenceData.forEach((item: any) => {
                        if(item.substitutionRemaining) {
                          credits = credits + item.substitutionRemaining;
                        }
                      });
                      const lastDay = absenceData.length === 0 ? moment() : absenceData.sort((a: any,b: any) => { return moment(b.date).unix() - moment(a.date).unix(); })[0].date;
                      const newData = {
                        attendanceProgram: childData.attendanceProgram === null ? { days: [] } : childData.attendanceProgram,
                        credit: credits,
                        allDays: absenceData,
                        allDates: allAbsenceData.map((item: any) => {
                          return item.date;
                        }),
                        lastDay: moment(lastDay),
                        holidayDates: holidayDates,
                        schoolDays: schoolsDays,
                        workingDays: workingDays,
                      };
                      setState("substitutionsData", newData);
                      dispatch(setChildData(newData));
                      const first = getFirstAvailableDay(newData);
                      dispatch(setSubstitutionsFirstAvailableDate(first));
                    } else {
                      createNotification(t("substitution_failed_load"), "error");
                      setState("isLoading", false);
                    }
                  } else {
                    createNotification(t("substitution_failed_load"), "error");
                    setState("isLoading", false);
                  }
                } else {
                  createNotification(t("substitution_failed_load"), "error");
                  setState("isLoading", false);
                }
              }).catch(() => {
                createNotification(t("substitution_failed_load"), "error");
                setState("isLoading", false);
                setState("isChildDataLoaded", false);
              });
            } else {
              createNotification(t("substitution_failed_load"), "error");
              setState("isLoading", false);
            }
          } else {
            createNotification(t("substitution_failed_load"), "error");
            setState("isLoading", false);
          }
        } else {
          createNotification(t("substitution_failed_load"), "error");
          setState("isLoading", false);
        }
        setState("isChildDataLoaded", false);
      }).catch(() => {
        createNotification(t("substitution_failed_load"), "error");
        setState("isLoading", false);
        setState("isChildDataLoaded", false);
      });
    }
  }, [absenceService, childData.attendanceProgram, dispatch, setState, substitutionsService, t, userData.userObject.roleType, schoolSettings, getChildData, getFirstAvailableDay]);

  const loadSubstitutions = useCallback((tempCurChild: any, tempCurDate: any) => {
    const currentDate = moment(tempCurDate);
    let startDate = moment(currentDate).subtract(1,'month');
    let endDate = moment(currentDate).add(1,'month');
    let months = monthsInDateRange(moment, startDate, endDate);
    if(substitutionsData.months.filter((item: any) => item.childID === tempCurChild.childID).length > 0) {
      if(substitutionsData.months.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(startDate).format("YYYY-MM")) !== -1) {
        startDate = currentDate;
        if(substitutionsData.months.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(startDate).format("YYYY-MM")) !== -1) {
          startDate = endDate;
        }
      }
      if(substitutionsData.months.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(endDate).format("YYYY-MM")) !== -1) {
        endDate = currentDate;
        if(substitutionsData.months.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(endDate).format("YYYY-MM")) !== -1) {
          endDate = startDate;
        }
      }
      months = monthsInDateRange(moment, startDate, endDate);
    }
    if(months.length === 1 && substitutionsData.months.filter((item: any) => item.childID === tempCurChild.childID).length > 0 && substitutionsData.months.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(months[0]).format("YYYY-MM")) !== -1) {
      setTimeout(() => {
        setState("isLoading", false);
      }, 500);
    } else {
      months.forEach((month: any) => {
        dispatch(addToSubstitutionsMonths({childID: tempCurChild.childID, month: month}));
      });
      substitutionsService && substitutionsService.listSubstitutionsRange(tempCurChild.childID, moment(startDate).startOf("month").format("YYYY-MM-DD"), moment(endDate).endOf("month").format("YYYY-MM-DD")).then((result: any) => {
        if(result) {
          if(result.data) {
            if(result.data.substitution) {
              months.forEach((month: any) => {
                const currentSubstitutionData = {childID: tempCurChild.childID, dates: result.data.substitution.filter((item: any) => moment(item.date).month() === moment(month).month())};
                dispatch(addToSubstitutionsList(currentSubstitutionData));
              });
              setTimeout(() => {
                setState("isLoading", false);
              }, 1000);
            } else {
              createNotification(t("substitution_failed_load"), "error");
              setState("isLoading", false);
            }
          } else {
            createNotification(t("substitution_failed_load"), "error");
            setState("isLoading", false);
          }
        } else {
          createNotification(t("substitution_failed_load"), "error");
          setState("isLoading", false);
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("substitution_failed_load"), "error");
        setState("isLoading", false);
      });
    }
  }, [dispatch, setState, substitutionsData.months, substitutionsService, t]);

  const loadWatchdogs = useCallback((tempCurChild: any, tempCurDate: any) => {
    const currentDate = moment(tempCurDate);
    let startDate = moment(currentDate).subtract(1,'month');
    let endDate = moment(currentDate).add(1,'month');
    let months = monthsInDateRange(moment, startDate, endDate);
    if(substitutionsData.watchdogsMonths.filter((item: any) => item.childID === tempCurChild.childID).length > 0) {
      if(substitutionsData.watchdogsMonths.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(startDate).format("YYYY-MM")) !== -1) {
        startDate = currentDate;
        if(substitutionsData.watchdogsMonths.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(startDate).format("YYYY-MM")) !== -1) {
          startDate = endDate;
        }
      }
      if(substitutionsData.watchdogsMonths.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(endDate).format("YYYY-MM")) !== -1) {
        endDate = currentDate;
        if(substitutionsData.watchdogsMonths.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(endDate).format("YYYY-MM")) !== -1) {
          endDate = startDate;
        }
      }
      months = monthsInDateRange(moment, startDate, endDate);
    }
    if(months.length === 1 && substitutionsData.watchdogsMonths.filter((item: any) => item.childID === tempCurChild.childID).length > 0 && substitutionsData.watchdogsMonths.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(months[0]).format("YYYY-MM")) !== -1) {

    } else {
      months.forEach((month: any) => {
        dispatch(addToWatchdogsMonths({childID: tempCurChild.childID, month: month}));
      });
      watchdogService && watchdogService.listWatchdogsRange(tempCurChild.childID, moment(startDate).startOf("month").format("YYYY-MM-DD"), moment(endDate).endOf("month").format("YYYY-MM-DD")).then((result: any) => {
        if(result) {
          if(result.data) {
            months.forEach((month: any) => {
              const currentWatchdogData = {childID: tempCurChild.childID, dates: result.data.filter((item: any) => moment(item.date).month() === moment(month).month())};
              dispatch(addToWatchdogs(currentWatchdogData));
            });
          } else {
            createNotification(t("watchdog_failed_load"), "error");
          }
        } else {
          createNotification(t("watchdog_failed_load"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("watchdog_failed_load"), "error");
      });
    }
  }, [dispatch, setState, substitutionsData.months, substitutionsService, t]);

  const prepareSubstitutions = useCallback((tempCurChild: any, tempCurDate: any) => {
    setState("isLoading", true);
    loadSubstitutions(tempCurChild, tempCurDate);
  }, [loadSubstitutions, setState]);

  const prepareWatchdogs = useCallback((tempCurChild: any, tempCurDate: any) => {
    loadWatchdogs(tempCurChild, tempCurDate);
  }, [loadWatchdogs, setState]);

  useEffect(() => {
    return () => {
      dispatch(clearSubstitutionsList());
      dispatch(clearSubstitutionsMonths());
      dispatch(clearWatchdogs());
      dispatch(clearWatchdogsMonths());
      dispatch(setSubstitutionsCurrentChild(null));
      dispatch(setSubstitutionsDate(moment()));
    };
  }, [dispatch, setState], []);

  useEffect(() => {
    setState("childrenListSelectWidth", substitutionsWrapperClientWidth);
  }, [browserData.width, substitutionsWrapperClientWidth, setState], [browserData.width, substitutionsWrapperClientWidth]);

  useEffect(() => {
    const first = getFirstAvailableDay();
    dispatch(setSubstitutionsFirstAvailableDate(first));
    dispatch(setSubstitutionsCanAdd(true));
  }, [], [state.substitutionsData]);
  
  useEffect(() => {
    if(filtersData.filterParams.childID.length === 1) {
      const childID = filtersData.filterParams.childID[0];
      const childData = getChildData(childID);
      dispatch(setSubstitutionsCurrentChild(childData));
    } else if(urlChildID) {
      const childID = parseInt(urlChildID);
      const childData = getChildData(childID);
      dispatch(setSubstitutionsCurrentChild(childData));
    } else {
      dispatch(setSubstitutionsCurrentChild(childrenData[0])); 
    }
  }, [childrenData, dispatch, filtersData.filterParams.childID, getChildData], [filtersData.filterParams.childID]);

  useEffect(() => {
    if(curChild !== null && curChild !== undefined && curDate !== null && curDate !== undefined) {
      setState("isLoading", true);
      prepareSubstitutions(curChild, curDate);
      prepareWatchdogs(curChild, curDate);
    }
  }, [curChild, curDate, prepareSubstitutions, setState], [curChild, curDate]);

  useEffect(() => {
    if(curChild !== null && curChild !== undefined && list !== undefined && list !== null && list.length !== 0 && !state.isChildDataLoaded && !state.isLoading) {
      setState("isChildDataLoaded", true);
      getSubstitutionsData(curChild);
    }
  }, [list, curChild, getSubstitutionsData, setState, state.isChildDataLoaded, state.isLoading], [list, curChild]);

  useEffect(() => {
    if(curChild !== null && curChild !== undefined && list !== undefined && list !== null && list.length !== 0 && !state.isFirstChildLoaded) {
      getSubstitutionsData(curChild);
      setState("isFirstChildLoaded", true);
    }
  }, [list, curChild, getSubstitutionsData, setState, state.isFirstChildLoaded], [list, curChild]);

  useEffect(() => {
    if(urlChildID && urlClassID && urlDate && urlDayPart && urlWatchdogID) {
      const settings = {
        isOpen: true,
        childID: parseInt(urlChildID),
        classID: parseInt(urlClassID),
        date: urlDate,
        dayPart: parseInt(urlDayPart),
        watchdogID: parseInt(urlWatchdogID),
      };
      dispatch(setWatchdogHandleModal(settings));
      const replacedString = decodeURIComponent(queryString).replace(`?childID=${urlChildID}`, '').replace(`&childID=${urlChildID}`, '').replace(`?classID=${urlClassID}`, '').replace(`&classID=${urlClassID}`, '').replace(`?date=${urlDate}`, '').replace(`&date=${urlDate}`, '').replace(`?dayPart=${urlDayPart}`, '').replace(`&dayPart=${urlDayPart}`, '').replace(`?watchdogID=${urlWatchdogID}`, '').replace(`&watchdogID=${urlWatchdogID}`, '');
      window.history.replaceState({},'',`/substitutions${replacedString}`);
    }
  }, [urlChildID, urlClassID, urlDate, urlDayPart, urlWatchdogID], [urlChildID, urlClassID, urlDate, urlDayPart, urlWatchdogID]);

  const handleSetToday = () => {
    const newDate = moment();
    dispatch(setSubstitutionsDate(newDate));
  };

  const handleSetDate = (newDate: any) => {
    dispatch(setSubstitutionsDate(newDate));
  };

  const handleOpenSubstitutionDetail = (substitutionID: any, childID: any) => {
    const settings = {
      isOpen: true,
      substitutionID: substitutionID,
      childID: childID,
      readOnly: false,
    };
    dispatch(setSubstitutionsDetailModal(settings));
  };

  const handleOpenWatchdogDetail = (watchdogID: any, childID: any) => {
    const settings = {
      isOpen: true,
      watchdogID: watchdogID,
      childID: childID,
      readOnly: false,
    };
    dispatch(setWatchdogDetailModal(settings));
  };

  const handleOpenAddSubstitution = (date: any) => {
    const settings = {
      isOpen: true,
      date: date,
      child: curChild,
    };
    dispatch(setSubstitutionsHandleModal(settings));
  };

  const handleOpenAddEmptySubstitution = () => {
    const settings = {
      isOpen: true,
      date: moment(substitutionsData.firstAvailableDate),
      child: curChild,
    };
    dispatch(setSubstitutionsHandleModal(settings));
  };

  return (userAccess.substitutions && childrenWithAttendance > 0) ? (
    <div className={classes.substitutionsPage}>
      <div className={classes.tabsWrapper}>
        <TabsMenu items={tabsItems} selected={state.viewMode} onSelect={setViewMode}/>
        <Sidebar/>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.wrapperColumn}>
          <div className={classes.substitutionsWrapper}>
            <div className={classes.headerWrapper}>
              <div className={classes.childrenWrapper} ref={substitutionsWrapper}>
                <ChildrenListSelect currentChild={substitutionsData.currentChild} childrenData={childrenData} setCurrentChild={setSubstitutionsCurrentChild} useDispatch={true} width={state.childrenListSelectWidth} isDisabled={state.isLoading}/>
              </div>
            </div>
            {
              state.viewMode === "calendar" ? (
                <>
                  <div className={classes.toolbar}>
                    <MonthPicker presetDate={moment(curDate)} setDate={handleSetDate} mode="spinner" disabled={state.isLoading}/>
                    <NormalButton className={classes.todayButton} startIcon={<SVG src="calendar-today"/>} disabled={moment(curDate).isSame(moment(), 'month') || state.isLoading} onClick={handleSetToday}>
                      {t('actual_month')} 
                    </NormalButton>
                  </div>
                  <Calendar isLoading={state.isLoading} handleOpenSubstitutionDetail={handleOpenSubstitutionDetail} handleOpenWatchdogDetail={handleOpenWatchdogDetail} handleOpenAddSubstitution={handleOpenAddSubstitution}/>
                  {
                    getUserRole(userData.userObject.roleType) !== "teacher" ? (
                      <span className={classes.instructions}>
                        {t('substitutions_instructions')} <SVG src="question-mark-circle"/>.
                      </span>
                    ) : null
                  }
                </>
              ) : state.viewMode === "list" ? (
                <>
                  <div className={classes.toolbar}>
                    <MonthPicker presetDate={moment(curDate)} setDate={handleSetDate} mode="spinner"/>
                    <NormalButton className={classes.todayButton} startIcon={<SVG src="calendar-today"/>} disabled={moment(curDate).isSame(moment(), 'month')} onClick={handleSetToday}>
                      {t('actual_month')} 
                    </NormalButton>
                  </div>
                  <div className={classes.list}>
                    {
                      state.isLoading ? (
                        <div className={classes.spinner}>
                          <CircularProgress/>
                        </div>
                      ) : (
                        <>
                          {
                            (curChild !== null && list.filter((item: any) => item.childID === curChild.childID).length > 0 && list.find((item: any) => item.childID === curChild.childID).dates.filter((item: any) => moment(item.date).format("YYYY-MM") === moment(curDate).format("YYYY-MM")).length > 0) ? (
                              <>
                              {
                                list.find((item: any) => item.childID === curChild.childID).dates.filter((item: any) => moment(item.date).format("YYYY-MM") === moment(curDate).format("YYYY-MM")).map((x: any) => { return {...x}}).sort((a: any, b: any) => { if (moment(a.date).unix() === moment(b.date).unix()) return b.substitutionID - a.substitutionID; return moment(b.date).unix() - moment(a.date).unix(); }).map((substitution: any, key: any) => (
                                  <SubstitutionCard substitution={{substitutionID: substitution.substitutionID, date: substitution.date, childID: substitution.childID}} onClick={() => handleOpenSubstitutionDetail(substitution.substitutionID, substitution.childID)} key={`k_${key}`}/>
                                ))
                              }
                              </>
                            ) : (
                              <div className={classes.notFound}>
                                <img src="/resources/images/noresults.png" alt={t('no_results') || ''}/>
                                <span>{t('no_results')}</span>
                                <p>{t('no_results_found')}</p>
                              </div>
                            )
                          }
                        </>
                      )
                    }
                  </div>
                </>
              ) : state.viewMode === "watchdogs" ? (
                <>
                  <div className={classes.toolbar}>
                    <MonthPicker presetDate={moment(curDate)} setDate={handleSetDate} mode="spinner"/>
                    <NormalButton className={classes.todayButton} startIcon={<SVG src="calendar-today"/>} disabled={moment(curDate).isSame(moment(), 'month')} onClick={handleSetToday}>
                      {t('actual_month')} 
                    </NormalButton>
                  </div>
                  <div className={classes.list}>
                    {
                      state.isLoading ? (
                        <div className={classes.spinner}>
                          <CircularProgress/>
                        </div>
                      ) : (
                        <>
                          {
                            (curChild !== null && watchdogs.filter((item: any) => item.childID === curChild.childID).length > 0 && watchdogs.find((item: any) => item.childID === curChild.childID).dates.filter((item: any) => moment(item.date).format("YYYY-MM") === moment(curDate).format("YYYY-MM")).length > 0) ? (
                              <>
                              {
                                watchdogs.find((item: any) => item.childID === curChild.childID).dates.filter((item: any) => moment(item.date).format("YYYY-MM") === moment(curDate).format("YYYY-MM")).map((x: any) => { return {...x}}).sort((a: any, b: any) => { if (moment(a.date).unix() === moment(b.date).unix()) return b.watchdogID - a.watchdogID; return moment(b.date).unix() - moment(a.date).unix(); }).map((watchdog: any, key: any) => (
                                  <WatchdogCard watchdog={{watchdogID: watchdog.watchdogID, date: watchdog.date, childID: watchdog.childID}} onClick={() => handleOpenWatchdogDetail(watchdog.watchdogID, watchdog.childID)} key={`k_${key}`}/>
                                ))
                              }
                              </>
                            ) : (
                              <div className={classes.notFound}>
                                <img src="/resources/images/noresults.png" alt={t('no_results') || ''}/>
                                <span>{t('no_results')}</span>
                                <p>{t('no_results_found')}</p>
                              </div>
                            )
                          }
                        </>
                      )
                    }
                  </div>
                </>
              ) : (
                <div className={classes.notFound}>
                  <img src="/resources/images/noresults.png" alt={t('no_results') || ''}/>
                  <span>{t('no_results')}</span>
                  <p>{t('no_results_found')}</p>
                </div>
              )
            }
          </div>
        </div>
        <Sidebar/>
        {
          (getUserRole(userData.userObject.roleType) !== "teacher" && substitutionsData.childData.credit > 0 && getFirstAvailableDay() && state.viewMode === "calendar") ? (
            <div className={classes.addButton}>
              <IconButton onClick={handleOpenAddEmptySubstitution} data-cy={isCypress() ? "excuseNotesAddButton" : null} disabled={!substitutionsData.canAdd}>
                <SVG src="plus"/>
              </IconButton>
            </div>
          ) : null
        }
      </div>
    </div>
  ) : (
    <Navigate to={`/403${queryString}`} state={{ from: location }}/>
  );
};

export default PageTemplate;