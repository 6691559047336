import AttendanceInfoItem from '../../../../Items/AttendanceInfoItem';
import ChildInfoItem from '../../../../Items/ChildInfoItem';
import CircleIcon from '@mui/icons-material/Circle';
import Grid from '@mui/material/Grid';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { getAppEnvironmentApiData } from 'src/utils/useApp';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  title: {
    fontWeight: '600',
  },
  subTitle: {
    fontWeight: '500',
  },
  text: {
    marginTop: '16px',
    marginBottom: '0px',
    color: theme.colors.grey[650],
    fontSize: '14px',
    maxWidth: '90%',
  },
  link: {
    color: theme.colors.primaryBlue[500],
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  attendanceBadge: {
    display: 'inline-flex',
    width: 'fit-content',
    alignItems: 'center',
    backgroundColor: theme.colors.chip,
    fontSize: '16px',
    borderRadius: '6px',
    padding: '4px 18px',
    marginTop: '16px',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    '& span + span': {
      marginLeft: '20px',
    },
    '& svg': {
      width: '18px',
      height: '18px',
      color: 'inherit',
      marginRight: '2px',
    },
    '& + p': {
      marginTop: '4px',
    }, 
  },
  attendancePresent: {
    color: '#55CEAD',  
  },
  attendanceAbsent: {
    color: '#8690B3',  
  },
  attendanceExcusedAbsent: {
    color: '#9B8AFF',  
  },
  attendanceAbsentPart: {
    color: '#BFC1DC',  
  },
  attendanceStatus: {
    fontWeight: 'bold',  
  },
  attendanceAbsentPartAM: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: '9px',
    marginLeft: '-9px',
  },
  attendanceAbsentPartAMPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: '9px',
    '& > svg': {
      color: '#FFFFFF !important',
      marginLeft: '-9px',
    },
  },
  attendanceAbsentPartPM: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: '9px',
    '& > svg': {
      marginLeft: '-9px',
    },
  },
  attendanceAbsentPartPMPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: '9px',
    '& > svg': {
      color: '#FFFFFF !important',
    },
  },
  attendanceRule: {
    color: '#DCDEF8',
    marginLeft: '9.5px',
    marginRight: '9.5px',
    marginBottom: '0px',
  },
  counts: {
    fontSize: '16px',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      marginTop: '8px',
    },
    '& > p': {
      marginTop: '0',
    },
    '& svg': {
      width: '18px',
      height: '18px',
      color: 'inherit',
      marginRight: '2px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    }, 
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '&:not(:first-of-type)': {
      marginTop: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: '0 0 100%',
      alignItems: 'center',
    },
  },
  fixLeft: {
    marginLeft: '9px',
  },
  childInfoItem: {
    cursor: 'auto',
    marginTop: '16px',
    display: 'flex',
    '& *': {
      cursor: 'auto',
    },
    '& > div': {
      marginTop: '0px',
      marginRight: '0px',
    },
  },
  childDefaultInfoItem: {
    cursor: 'auto',
    display: 'flex',
    '& *': {
      cursor: 'auto',
    },
    '& > div': {
      marginTop: '0px',
      marginRight: '0px',
    },
  },
  attendanceInfoItem: {
    '& > div': {
      marginBottom: '16px',
      marginLeft: '16px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
      }, 
    },
  },
  badges: {
    display: 'flex',
    marginTop: '16px',
    gap: '16px',
  },
}));

const Realtime = () => {

  const { t } = useTranslation();
  const classes = useStyles({});
  
  const classAttendanceStatus = [
    {
      id: '1',
      color: '#55cead',
      image: '',
    },
    {
      id: '2',
      color: '#9b8aff',
      image: '',
    },
    {
      id: '3',
      color: '#ee576c',
      image: 'https://sandbox-api.twigsee.com/assets/attendance_status/attendance_status_covid.svg',
    },
    {
      id: '4',
      color: '#ffa94e',
      image: '',
    },
    {
      id: '5',
      color: '#bfc1dc',
      image: '',
    },
    {
      id: '6',
      color: '#ee576c',
      image: '',
    },
    {
      id: '7', 
      color: '#00e5e5', 
      image: '',
    },
  ];

  const childData = {
    childID: 0,
    displayName: 'Kubík',
    photo: {
      photoID: '0',
      thumbLink: '/resources/images/childThumb.png',
      fullsizeLink: '/resources/images/childFull.png',
    },  
  };

  const classData = {
    classAttendanceStatus: classAttendanceStatus,
  };
  
  const adminUrl = getAppEnvironmentApiData().adminUrl;
  const adminUrlClear = adminUrl.replace("https://", "").replace("/", "");
  const childExcused = Object.assign({}, childData, { morningAttendanceStatusId: '2', afternoonAttendanceStatusId: '2' });
  const childExcusedMorning = Object.assign({}, childData, { morningAttendanceStatusId: '2' });
  const childExcusedAfternoon = Object.assign({}, childData, { afternoonAttendanceStatusId: '2' });
  const childConflict = Object.assign({}, childData, { morningAttendanceStatusId: '2', afternoonAttendanceStatusId: '2' });

  const openAdmin = () => {
    window.open(adminUrl);
  };

  return (
    <>
      <div className={classes.row}>
        <div className={classes.block}>
          <span className={classes.title}>
            {t('present_and_absent')}
          </span>
          <div className={classes.attendanceBadge}>
            <span className={classes.attendancePresent}>
              <SVG src="attendance-person-present"/>
              22
            </span>
            <span className={classes.attendanceAbsent}>
              <SVG src="attendance-person-missing"/>
              10
            </span>
          </div>
          <p className={classes.text}>
            {t('current_attendance')}
          </p>
          <div className={classes.counts}>
            <span className={classes.attendancePresent}>
              <SVG src="attendance-person-present"/>
              22
            </span>
            <p className={classes.text}>
              {t('number_present_children')}
            </p>
            <span className={classes.attendanceAbsent}>
              <SVG src="attendance-person-missing"/>
              10
            </span>
            <p className={classes.text}>
              {t('number_absent_children')}
            </p>
          </div>
        </div>
        <div className={classes.block}>
          <span className={classes.title}>
            {t('expected_attendance')}
          </span>
          <div className={classes.attendanceBadge}>
            <span className={classes.attendanceAbsentPart}>
              <div className={classes.attendanceAbsentPartAM}>
                <CircleIcon/>
              </div>
              <div className={classes.attendanceAbsentPartAMPlaceholder}>
                <CircleIcon/>
              </div>
              <SVG src="attendance-person-present"/>
              22
            </span>
            <p className={classes.attendanceRule}>|</p>
            <span className={classes.attendanceAbsentPart}>
              <div className={classes.attendanceAbsentPartPMPlaceholder}>
                <CircleIcon/>
              </div>
              <div className={classes.attendanceAbsentPartPM}>
                <CircleIcon/>
              </div>
              <SVG src="attendance-person-present"/>
              19
            </span>
          </div>
          <p className={classes.text}>
            {t('expected_numbers_present')}.
          </p>
          <div className={classes.counts}>
            <span className={`${classes.attendanceAbsentPart} ${classes.fixLeft}`}>
              <div className={classes.attendanceAbsentPartAM}>
                <CircleIcon/>
              </div>
              <div className={classes.attendanceAbsentPartAMPlaceholder}>
                <CircleIcon/>
              </div>
              <SVG src="attendance-person-present"/>
              22
            </span>
            <p className={classes.text}>
              {t('expected_number_morning')}
            </p>
            <span className={classes.attendanceAbsentPart}>
              <div className={classes.attendanceAbsentPartPMPlaceholder}>
                <CircleIcon/>
              </div>
              <div className={classes.attendanceAbsentPartPM}>
                <CircleIcon/>
              </div>
              <SVG src="attendance-person-present"/>
              19
            </span>
            <p className={classes.text}>
              {t('expected_number_afternoon')}
            </p>
          </div>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.block}>
          <span className={classes.title}>
            {t('absent_child')}
          </span>
          <Grid item xs={6} sm={2} className={classes.childInfoItem}>
            <ChildInfoItem childID={0} isSelected={false} defaultClassData={classData} defaultChildData={childData} mode="attendance" size="48"/>
          </Grid>
          <p className={classes.text}>
            {t("attendance_child_initial_state")}.
            <br/>
            <br/>
            {t("attendance_child_is_absent")}.
            <br/>
            <br/>
            {t('attendance_absent_not_excused')}.
          </p>            
          </div>
          <div className={classes.block}>
          <span className={classes.title}>
            {t('present_child')}
          </span>
          <Grid item xs={6} sm={2} className={classes.childInfoItem}>
            <ChildInfoItem childID={0} isSelected={true} defaultClassData={classData} defaultChildData={childData} mode="attendance" size="48"/>
          </Grid>
          <p className={classes.text}>
            {t("attendance_to_register")}. {t('attendance_present')}. {t('attendance_icon_tap')}.
            <br/>
            <br/>
            {t("attendance_present")}. {t('attendance_correction')}.
          </p>
        </div>
      </div>
      <div className={classes.row}>
          <div className={classes.block}>
          <span className={classes.title}>
            {t('excused_child')}
          </span>
          <div className={classes.badges}>
            <Grid item xs={6} sm={3} className={classes.childDefaultInfoItem}>
              <ChildInfoItem childID={0} defaultClassData={classData} defaultChildData={childExcusedMorning} mode="attendance" size="48"/>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.childDefaultInfoItem}>
              <ChildInfoItem childID={0} defaultClassData={classData} defaultChildData={childExcusedAfternoon} mode="attendance" size="48"/>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.childDefaultInfoItem}>
              <ChildInfoItem childID={0} defaultClassData={classData} defaultChildData={childExcused} mode="attendance" size="48"/>
            </Grid>
          </div>
          <p className={classes.text}>
            {t('attendance_purple')}.
            <br/>
            <br/>
            {t('attendance_badges_types')}: {t('attendance_circles')}.
          </p>
          </div>
          <div className={classes.block}>
          <span className={classes.title}>
            {t('badge_variants')}
          </span>
          <div className={classes.badges}>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId="2"
                afternoonAttendanceStatusId={null}
                classAttendanceStatus={classAttendanceStatus}
                text={t('morning')}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId={null}
                afternoonAttendanceStatusId="2"
                classAttendanceStatus={classAttendanceStatus}
                text={t('afternoon')}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId="2"
                afternoonAttendanceStatusId="2"
                classAttendanceStatus={classAttendanceStatus}
                text={t('whole_day')}
              /> 
            </Grid>
          </div>
          <p className={classes.text}>
            <span className={classes.subTitle}>{t('purple')}</span> - {t('attendance_parent_sent').toLowerCase()}
          </p>
          <div className={classes.badges}>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId="4"
                afternoonAttendanceStatusId={null}
                classAttendanceStatus={classAttendanceStatus}
                text={t('morning')}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId={null}
                afternoonAttendanceStatusId="4"
                classAttendanceStatus={classAttendanceStatus}
                text={t('afternoon')}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId="4"
                afternoonAttendanceStatusId="4"
                classAttendanceStatus={classAttendanceStatus}
                text={t('whole_day')}
              />
            </Grid>
          </div>
          <p className={classes.text}>
            <span className={classes.subTitle}>{t('orange')}</span> - {t('attendance_program').toLowerCase()}
          </p>
          <div className={classes.badges}>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId="7"
                afternoonAttendanceStatusId={null}
                classAttendanceStatus={classAttendanceStatus}
                text={t('morning')}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId={null}
                afternoonAttendanceStatusId="7"
                classAttendanceStatus={classAttendanceStatus}
                text={t('afternoon')}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId="7"
                afternoonAttendanceStatusId="7"
                classAttendanceStatus={classAttendanceStatus}
                text={t('whole_day')}
              /> 
            </Grid>
          </div>
          <p className={classes.text}>
            <span className={classes.subTitle}>{t('turquoise')}</span> - {t('attendance_substitution').toLowerCase()}
          </p>
          <div className={classes.badges}>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId="3"
                afternoonAttendanceStatusId={null}
                classAttendanceStatus={classAttendanceStatus}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId={null}
                afternoonAttendanceStatusId="3"
                classAttendanceStatus={classAttendanceStatus}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId="3"
                afternoonAttendanceStatusId="3"
                classAttendanceStatus={classAttendanceStatus}
                text={t('c19_infection')}
              />
            </Grid>
          </div>
          <div className={classes.badges}>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId="6"
                afternoonAttendanceStatusId={null}
                classAttendanceStatus={classAttendanceStatus}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId={null}
                afternoonAttendanceStatusId="6"
                classAttendanceStatus={classAttendanceStatus}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId="6"
                afternoonAttendanceStatusId="6"
                classAttendanceStatus={classAttendanceStatus}
                text={t('c19_quarantine')}
              />
            </Grid>
          </div>
          <p className={classes.text}>
            <span className={classes.subTitle}>{t('red')}</span> - {t('attendance_excused').toLowerCase()}
            <br/>
            <br/>
            &bull; {t('red_with_icon')} - <span className={classes.subTitle}>{t('c19_infection')}</span>
            <br/>
            &bull; {t('red_without_icon')} - <span className={classes.subTitle}>{t('c19_quarantine')}</span>
          </p>                       
          </div>
      </div>
      <div className={classes.row}>
        <div className={classes.block}>
          <span className={classes.title}>
            {t('combinations')}
          </span>
          <div className={classes.badges}>
            <Grid item xs={6} sm={2}>
              <AttendanceInfoItem
                morningAttendanceStatusId="2"
                afternoonAttendanceStatusId="4"
                classAttendanceStatus={classAttendanceStatus}
                text={t('attendance_morning_purple') + "<br>" + t('attendance_afternoon_orange')}
              />
            </Grid>
          </div>
          <p className={classes.text}>
            {t('attendance_combinations')}. {t('attendance_two_states')}.
          </p>
        </div>
        <div className={classes.block}>
          <span className={classes.title}>
            {t('excused_child_is_present')}
          </span>
          <Grid item xs={6} sm={2} className={classes.childInfoItem}>
            <ChildInfoItem childID={0} isSelected={true} defaultClassData={classData} defaultChildData={childConflict} mode="attendance" size="48"/>
          </Grid> 
          <p className={classes.text}>
            {t("attendance_arrived_excused")}.
            <br/><br/>
            {t("attendance_register")}.
            <br/><br/>
            {t('attendance_informations')}.
          </p>                
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.block}>
          <span className={classes.title}>
            {t('editting_attendance')}
          </span>
          <p className={classes.text}>
            {t('attendance_admin')} (<span className={classes.link} onClick={openAdmin}>{adminUrlClear}</span>).
          </p>
        </div>
      </div>
    </>
  );
};

export default Realtime;