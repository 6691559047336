import Banner from 'src/components/Layouts/Banner';
import menuItems from 'src/constants/menu';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import Switch from 'src/components/Forms/Switch';
import { Button } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole, getUserSetting, saveMultipleUserSettings, saveUserSettings } from '../../../../../../utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { mergeMenuItems, validateItemRequirements } from 'src/utils/useFunctions';
import { ReactSortable } from 'react-sortablejs';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 60px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    padding: '4px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    '& > button': {
      marginTop: '16px',
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'center',
    width: '100%',
    margin: '30px 0px auto 0px',
    maxHeight: '100%',
    overflow: 'visible',
    height: '100%',
  },
  menuItem: {
    width: '100%',
    height: '56px',
    '& .MuiSvgIcon-root': {
      transition: '0.3s all',
    },
  },
  menuItemLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: '24px',
    backgroundColor: theme.colors.grey[100],
    color: theme.colors.primaryBlue[700],
    fontSize: '12px',
    height: '100%',
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
    '&:disabled': {
      filter: 'grayscale(100%)',
      '& > *': {
        backgroundColor: 'transparent !important',
      },
    },
    '& .MuiButton-iconSizeMedium': {
      '& > *:first-child': {
        fontSize: 'unset',
      }   
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
      '& > span.text': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        paddingLeft: '0.25rem',      
      },
    },
    '&.MuiButton-text': {
      padding: '8px 20px',
    },
    '& .MuiButton-startIcon': {
      color: 'inherit',
    },
    '& .MuiButton-endIcon': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      '& > svg': {
        color: theme.colors.yellow[500],      
      },
    },
    '& svg': {
      color: 'inherit',
      height: "20px",
      width: "20px",
    },
  },
  menuItemIcon: {
    width: '16px',
    height: '16px',
    marginRight: '5px',
  },
  banner: {
    width: 'calc(40% - 40px)',
    maxWidth: '100%',
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    fontSize: '13px',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 40px)',
      borderRadius: '0px',
    },
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    backgroundColor: theme.colors.grey[200],
    borderRadius: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.black,
    },
    '&.disabled': {
      backgroundColor: theme.colors.grey[100],
      cursor: 'default',
      '& > svg': {
        color: theme.colors.grey[800],
      },
    },
  },
  settings: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
}));

const MenuItems: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);

  const userObject = userData.userObject;
  const userSettings = userData.userSettings;
  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;
  const userMembership = userData.membership;

  const isFirstTime = useRef(true);
  const isSaving = useRef(false);
  const changeHomepage = useRef(false);

  const mainMenuItems = menuItems.main.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));

  const mainMenuItemsDefaultAvailable = mainMenuItems.map((item: any) => {
    return validateItemRequirements(item, { dataData: dataData, isCypress: isCypress, layoutData: layoutData, userAccess: userAccess, userAccessSchools: userAccessSchools, userMembership: userMembership, userObject: userObject, userSettings: userSettings, getUserRole: getUserRole, getUserSetting: getUserSetting});
  }).filter((item: any) => item !== null);

  const mainMenuItemSettings = getUserSetting(userData.userSettings, "customizations", ["mainmenu"]);

  const mainMenuItemsUpdated = mainMenuItemSettings ? mergeMenuItems(mainMenuItems, mainMenuItemSettings) : mainMenuItems;

  const mainMenuItemsAvailable = mainMenuItemsUpdated.map((item: any) => {
    return validateItemRequirements(item, { dataData: dataData, isCypress: isCypress, layoutData: layoutData, userAccess: userAccess, userAccessSchools: userAccessSchools, userMembership: userMembership, userObject: userObject, userSettings: userSettings, getUserRole: getUserRole, getUserSetting: getUserSetting});
  }).filter((item: any) => item !== null);

  const mainMenuItemsSortable = mainMenuItemsAvailable.map((item: any) => {
    return {
      title: item.title,
      to: item.to,
      icon: item.icon,
      isVisible: item.isVisible,
      canBeHome: item.canBeHome,
      order: item.order,
    };
  });

  mainMenuItemsSortable.sort((a: any, b: any) => { return a.order - b.order; });

  const [state, setState] = useStates({
    mainMenuItems: mainMenuItemsSortable,
    lastMainMenuItems: mainMenuItemsSortable,
    isSaving: false,
  });

  const setMainMenuItems = (value: any) => {
    const settings = value.map((item: any, key: any) => {
      return {...item, order: (key + 1)};
    })
    setState("mainMenuItems", settings);
  };

  const handleSwitch = (to: any, value: any) => {
    const settings = state.mainMenuItems.map((item: any) => {
      if(item.to === to) {
        return {...item, isVisible: value};
      } else {
        return item;
      }
    });
    setState("mainMenuItems", settings);
    if(value === false) {
      const homepageSettings = getUserSetting(userData.userSettings, "customizations", ["homepage"]);
      if(homepageSettings === to) {
        const firstAvailableHomepage = settings.filter((item: any) => item.isVisible && item.canBeHome)[0];
        changeHomepage.current = firstAvailableHomepage.to;
      }
    }
  };

  const handleMove = (e: any, to: any, direction: 'up' | 'down') => {
    e.stopPropagation();
    e.preventDefault();
    if(state.isSaving) return;
    const currentIndex = state.mainMenuItems.findIndex((item: any) => item.to === to);
    if(currentIndex === -1 || (direction === 'up' && currentIndex === 0) || (direction === 'down' && currentIndex === state.mainMenuItems.length - 1)) {
      return;
    }
    const newMainMenuItems = [...state.mainMenuItems];
    const targetIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
    [newMainMenuItems[currentIndex].order, newMainMenuItems[targetIndex].order] = [newMainMenuItems[targetIndex].order, newMainMenuItems[currentIndex].order];
    newMainMenuItems.sort((a: any, b: any) => { return a.order - b.order; });
    setState("mainMenuItems", newMainMenuItems);
  };

  const handleSaveCallback = useCallback(async (newSettings: any, settings: any) => {
    if(isSaving.current === true) return;
    const items = [
      {
        keys: ["mainmenu"],
        values: newSettings,
      },
      {
        keys: ["homepage"],
        values: changeHomepage.current,
      },
    ];
    const result = changeHomepage.current ? await saveMultipleUserSettings(dispatch, userData, "customizations", items) : await saveUserSettings(dispatch, userData, "customizations", ["mainmenu"], newSettings);
    if(result) {
      setState("lastMainMenuItems", settings);
      createNotification(t('update_menu_saved'), "success");
      isSaving.current = false;
      setState("isSaving", false);
    } else {
      const settings = {
        isOpen: true,
      };
      setState("mainMenuItems", state.lastMainMenuItems);
      isSaving.current = false;
      setState("isSaving", false);
    }
  }, [dispatch, t, userData, setState, state.lastMainMenuItems]);

  const handleSaveMainMenuItems = useCallback(async (settings: any) => {
    const oldSettings = state.lastMainMenuItems.map((item: any) => { return { to: item.to, isVisible: item.isVisible, order: item.order }; });
    const newSettings = settings.map((item: any) => { return { to: item.to, isVisible: item.isVisible, order: item.order }; });
    if(isFirstTime.current === false) { 
      if(JSON.stringify(oldSettings) !== JSON.stringify(newSettings)) {
        if(isSaving.current === false) {
          handleSaveCallback(newSettings, settings);
          setState("isSaving", true);
          isSaving.current = true;
        }
      }
    } else {
      isFirstTime.current = false;
    }
  }, [state.lastMainMenuItems, setState, handleSaveCallback]);

  const handleReset = async () => {
    const homepageSettings = getUserSetting(userData.userSettings, "customizations", ["homepage"]);
    const items = [
      {
        keys: ["mainmenu"],
        values: [],
      },
      {
        keys: ["homepage"],
        values: mainMenuItemsDefaultAvailable.filter((item: any) => item.isVisible && item.canBeHome)[0],
      },
    ];
    const result = mainMenuItems.filter((item: any) => item.to === homepageSettings).length === 0 ? await saveMultipleUserSettings(dispatch, userData, "customizations", items) : await saveUserSettings(dispatch, userData, "customizations", ["mainmenu"], []);
    if(result) {
      setState("lastMainMenuItems", mainMenuItemsSortable);
      setState("mainMenuItems", mainMenuItemsSortable);
      createNotification(t('reset_default_reseted'), "success");
    } else {
      createNotification(t('reset_default_failed'), "error");
      setState("mainMenuItems", state.lastMainMenuItems);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSaveMainMenuItems(state.mainMenuItems);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [handleSaveMainMenuItems, state.mainMenuItems], [state.mainMenuItems]);

  return (
    <>
      <div className={classes.box}>
        <ReactSortable className={classes.menuList} list={state.mainMenuItems} setList={setMainMenuItems} disabled={state.isSaving}>
          {
            state.mainMenuItems.map((node: any, key: any) => {
              const cantBeSwitched = node.canBeHome ? (node.isVisible && state.mainMenuItems.filter((item: any) => item.isVisible && item.canBeHome).length <= 1) : false;
              return (
                <div className={classes.menuItem} key={`k_${key}`}>
                  <Button className={classes.menuItemLabel} startIcon={node.icon !== "" ? (<SVG src={node.icon}/>) : null} endIcon={<div className={classes.settings}> {key !== 0 ? (<span className={`${classes.iconButton} ${state.isSaving ? 'disabled' : null}`} onClick={(e: any) => handleMove(e, node.to, "up")}><SVG src="chevron-up"/></span>) : null}{(key + 1) !== state.mainMenuItems.length ? (<span className={`${classes.iconButton} ${state.isSaving ? 'disabled' : null}`} onClick={(e: any) => handleMove(e, node.to, "down")}><SVG src="chevron-down"/></span>) : null}<Switch name={node.to} checked={node.isVisible} onChange={(name: any, val: any) => handleSwitch(name, val)} disabled={cantBeSwitched || state.isSaving}/></div>}>
                    <span className="text">{t(`${node.title}`)}</span>
                  </Button>
                </div>  
              )
            })
          }
        </ReactSortable>
        <NormalButton buttonType='secondary' onClick={handleReset} disabled={mainMenuItemSettings.length === 0}>
          {t('reset_default')}
        </NormalButton>
      </div>
      <Banner className={classes.banner} icon={true} type='info'>
        {t(`menu_items_detail`)}
      </Banner>
    </>
  );
};

export default MenuItems;