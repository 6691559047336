import HttpService from '../http.service';

const listSubstitutions = (childID: any, date: any) => {
  return HttpService.get('v2', `substitution?childID=${childID}&date=${date}`, {}, {});
};

const listSubstitutionsRange = (childID: any, dateFrom: any, dateTo: any) => {
  return HttpService.get('v2', `substitution?childID=${childID}&dateFrom=${dateFrom}&dateTo=${dateTo}`, {}, {});
};

const findSubstitution = (substitutionID: any) => {
  return HttpService.get('v2', `substitution?substitutionID=${substitutionID}`, {}, {});
};

const listAvailableSubstitutions = (childID: any) => {
  return HttpService.get('v2', `absence?childID=${childID}&availableForSubstitution=1`, {}, {});
};

const createSubstitution = (payload: any) => {
  return HttpService.post('v2', `substitution`, payload, {}, {});
};

const editSubstitution = (substitutionID: any, payload: any) => {
  return HttpService.put('v2', `substitution/${substitutionID}`, payload, {}, {});
};

const deleteSubstitution = (substitutionID: any) => {
  return HttpService.remove('v2', `substitution/${substitutionID}`, {});
};

export {
  listSubstitutions,
  findSubstitution,
  listSubstitutionsRange,
  listAvailableSubstitutions,
  createSubstitution,
  editSubstitution,
  deleteSubstitution,
};