import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  notFound: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      maxWidth: '80%',
    },
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '36px',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '8px',
      color: theme.colors.grey[650],
      fontSize: '16px',
      marginBottom: '0',
    },
    '& > button': {
      marginTop: '24px',
    },
  },
}));

type NotFoundType = {
  className?: any;
  title?: any;
  text?: any;
  actionButton?: any;
  onActionButtonClick?: any;
};

const NotFound: React.FunctionComponent<NotFoundType> = ({ className, title, text, actionButton, onActionButtonClick }) => {
  
  const classes = useStyles();

  const handleOnActionButtonClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onActionButtonClick(e);
  };
    
  return (
    <div className={`${classes.notFound} ${className ? className : ''}`}>
      <SVG src="noresults"/>
      { 
        title ? (
          <span>{title}</span>
        ) : null
      }
      { 
        text ? (
          <p>{text}</p>
        ) : null
      }
      {
        (actionButton && onActionButtonClick) ? (
          <NormalButton buttonType='primary' onClick={handleOnActionButtonClick}>
            {actionButton}
          </NormalButton>
        ) : null
      }
    </div>  
  );
}

export default NotFound;