import AuthenticatedImage from '../../Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import DateFormat from '../../../utils/dateFormat';
import Modal from '../../../utils/modal';
import moment from '../../../utils/moment';
import NormalButton from '../../Buttons/NormalButton';
import React, { useCallback, useMemo } from 'react';
import SVG from '../../Images/SvgRenderer';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { deleteWatchdog } from '../../../store/actions/substitutions.actions';
import { isCypress } from '../../../utils/useCypress';
import { isKey } from 'src/utils/useFunctions';
import { setWatchdogDetailModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  text: {
    '& a': {
      color: theme.colors.primaryBlue[500],
    },
  },
  body: {
    marginTop: "20px",
    position: 'relative',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
  },
  footer: {
    display: "flex",
    flexDirection: 'column',
    marginTop: "30px",
    gap: '15px',
    padding: "0 20px 20px 20px",
    '& > div': {
      display: 'flex',
      gap: '10px',
      width: '100%',
      justifyContent: "flex-end",
      '& > button > span': {
        zoom: '90%',
        [theme.breakpoints.down('sm')]: {
          zoom: '70%',
        },
      },
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: "0 25px",
    width: 'calc(100% - 50px)',
    flexDirection: 'row',
    '& + div': {
      marginTop: '16px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: theme.colors.grey[700],
    width: '200px',
    flex: '0 0 200px',
    '& > svg': {
      width: '18.5px',
      height: '18.5px',
      marginRight: '8px',  
    }, 
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '14px',
    color: '#9B9EBB',
    maxHeight: '300px',
    overflow: 'auto',
    gap: '8px',
  },
  footerInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    color: '#9B9EBB',
    paddingLeft: "25px",
    '& > p': {
      marginBottom: '0',
    },
  },
  childPhoto: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
    margin: '0px 5px',
    position: 'relative',
    '& > div': {
      backgroundColor: 'transparent',
      borderRadius: '50%',
      minWidth: '36px',
      minHeight: '36px',
      width: '36px',
      height: '36px',
      maxWidth: '36px',
      maxHeight: '36px',
    },
    '& > p': {
      fontSize: '14px',
      marginLeft: '16px',
      maxWidth: '100%',
      textAlign: 'center',
      marginBottom: '0',
      color: '#9B9EBB',
    },
  },
  nickname: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',  
  },
  tag: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
    padding: '2px 8px',
    width: 'fit-content',
    borderRadius: '10px',
    backgroundColor: '#EEEEEE',
    fontWeight: 'bold',
    marginTop: '8px',
    cursor: 'pointer',
  },
}));

const WatchdogDetailModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dataData = useAppSelector((state: any) => state.data);
  const substitutionsData = useAppSelector((state: any) => state.substitutions);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const watchdogService = useAppSelector((state: any) => state.services).watchdogService;

  const [state, setState] = useStates({
    canClick: true,
    isCustom: false,
    customData: [],
    isReady: false,
  });
  
  const watchdogID = modalsData.watchdogDetailModal.watchdogID;
  const childID = modalsData.watchdogDetailModal.childID;
  const readOnly = modalsData.watchdogDetailModal.readOnly;
  const list = substitutionsData.watchdogs;
  const watchdogDataAll = useMemo(() => { return state.isCustom ? state.customData : (list.length > 0 && childID !== null && childID !== undefined) ? (list.filter((data: any) => data.childID === childID).length > 0 ? list.find((data: any) => data.childID === childID).dates : []) : []; }, [childID, list, state.customData, state.isCustom]);
  const watchdogData = useMemo(() => { return watchdogDataAll.length > 0 ? (watchdogDataAll.filter((data: any) => data.watchdogID === watchdogID).length === 0 ? {} : watchdogDataAll.find((data: any) => data.watchdogID === watchdogID)) : {}; }, [watchdogDataAll, watchdogID]);
  const childData = dataData.children.find((child: any) => child.childID === watchdogData.childID);
  const authorData = dataData.users.find((user: any) => user.userID === watchdogData.authorID);
  const classData = watchdogData.classID ? dataData.classes.find((theClass: any) => theClass.classID === watchdogData.classID) : [];

  const onCloseModal = useCallback(() => {
    const settings = {
      isOpen: false,
      date: null,
      watchdogID: null,
      childID: null,
      readOnly: false,
    };
    dispatch(setWatchdogDetailModal(settings));
  }, [dispatch]);

  useEffect(() => {
    if(Object.keys(watchdogData).length === 0) {
      watchdogService && watchdogService.findWatchdog(watchdogID).then((result: any) => {
        if(result) {
          if(result.data) {
            if(result.data.length === 1) {
              setState("customData", result.data);
              setState("isCustom", true);
              setState("isReady", true);
            } else {
              createNotification(t("watchdog_failed_load"), "error");
              onCloseModal();
            }
          } else {
            createNotification(t("watchdog_failed_load"), "error");
            onCloseModal();
          }
        } else {
          createNotification(t("watchdog_failed_load"), "error");
          onCloseModal();
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("watchdog_failed_load"), "error");
        onCloseModal();
      });
    } else {
      setState("isReady", true);
    }
  }, [onCloseModal, setState, watchdogData, watchdogID, watchdogService, t], []);

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    if(state.canClick) {
      setState("canClick", false);
      watchdogService && watchdogService.deleteWatchdog(watchdogData.watchdogID).then((result: any) => {
        if(result.status === 204) {
          createNotification(t("watchdog_deleted"),"success");
          const settings = {
            watchdogID: watchdogData.watchdogID,
            childID: childData.childID,
          };
          dispatch(deleteWatchdog(settings));
          setState("canClick", true);
          onCloseModal();      
        } else {
          createNotification(t("watchdog_not_deleted"),"error");
          setState("canClick", true);
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.message) ? e.response.message : t("watchdog_not_deleted"),"error");
        setState("canClick", true);
      });
    }
  };

  return state.isReady ? (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      {
        Object.keys(watchdogData).length > 0 ? (
          <div className={classes.root} data-cy={isCypress() ? "watchdogDetailModal" : null}>
            <div className={classes.header}>
              <div className={classes.wrapper}>
                <p>
                  {t('watchdog_detail')}
                  &nbsp;
                  {DateFormat(moment(watchdogData.date),"default", languageData, t)}
                  </p>
              </div>
              <CloseButton onClick={handleClose} dataCy="timesButton"/>
            </div>
            <div className={classes.body}>
              <div className={classes.item}>
                <span className={classes.title}>
                  <SVG src="user-circle-outlined"/>
                  {t('child')}
                </span>
                <div className={classes.childPhoto}>
                  <AuthenticatedImage thumbLink={childData.photo?.thumbLink}/>
                  <p className={classes.nickname}>{childData.displayName}</p>
                </div>
              </div>
              {
                watchdogData.classID ? (
                  <div className={classes.item}>
                    <span className={classes.title}>
                      <SVG src="class"/>
                      {t('class')}
                    </span>
                    <p className={classes.detail}>
                      {classData.name}
                    </p>
                  </div>
                ) : null
              }
              <div className={classes.item}>
                <p className={classes.title}>
                  <SVG src="calendar"/>
                  {t('date')}
                </p>
                <p className={classes.detail}>
                  {DateFormat(moment(watchdogData.date),"default", languageData, t)}
                </p>
              </div>
              <div className={classes.item}>
                <p className={classes.title}>
                  <SVG src="clock"/>
                  {t('part_of_day')}
                </p>
                <p className={classes.detail}>
                  {t(watchdogData.dayPart === 1 ? "whole_day" : watchdogData.dayPart === 2 ? "morning" : "afternoon" )}
                </p>
              </div>
              <div className={classes.footerInfo}>
                <p>{t('author')}: {authorData.displayName}</p>
                <p>{t('created')}: {DateFormat(moment(watchdogData.createdDate),"daymonthname", languageData, t)} &bull; {DateFormat(moment(watchdogData.createdDate),"time", languageData, t)}</p>
              </div>
            </div>
            {
              !readOnly ? (
                <div className={classes.footer}>
                {
                  (moment(watchdogData.date).isSameOrAfter(moment()) && !childData.isArchived) ? (
                    <div>
                      <NormalButton buttonType="primary" onClick={handleDelete} disabled={!state.canClick} dataCy="deleteWatchdogButton">
                        {t("delete_watchdog")}
                      </NormalButton>
                    </div>
                  ) : null
                }
                </div>
              ) : (
              <div className={classes.footer}/>
              )
            }
          </div>
        ) : (
          <></>
        )
      }
    </Modal>
  ) : null;
};

export default WatchdogDetailModal;