import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import useStyles from './style';
import { Step, StepButton, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';

type DesktopType = {
  steps: any;
  currentStep: number;
  onStepChange: any;
  onComplete?: any;
  canNextStep?: boolean;
  showStepper?: boolean;
  showFooter?: boolean;
  footerContent: any;
  className?: any;
  classNameStepper?: any;
};

const Desktop: React.FunctionComponent<DesktopType> = ({ steps, currentStep = 1, onStepChange, onComplete, canNextStep, showStepper = true, showFooter = true, footerContent, className, classNameStepper }) => {
  
  const { t } = useTranslation();
  
  const totalSteps = steps.length;

  const activeStep = currentStep - 1;

  const isLastStep = activeStep === totalSteps - 1;

  const classes = useStyles({
    showFooter: showFooter,
  });

  const handleStep = (step: any) => {
    if(onStepChange) {
      onStepChange(step);
    }
  };

  const handleNext = () => {
    const step = currentStep + 1;
    handleStep(step);
  };

  const handleBack = () => {
    const step = currentStep - 1;
    handleStep(step);
  };

  const getStepData = (key: any) => {
    return steps[key];
  };

  return (
    <div className={`${classes.wizard} ${className ? className : ''}`}>
      {
        showStepper ? (
          <Stepper className={`${classes.stepper} ${classNameStepper ? classNameStepper : ''}`} nonLinear activeStep={activeStep}>
            {
              steps.map((data: any, key: any) => (
                <Step key={`k_${key}`}>
                  <StepButton className={classes.stepButton} optional={data.optional ? (<p>{t('optional')}</p>) : null} onClick={() => handleStep(key + 1)} disabled={key === activeStep || !getStepData(key).canSelect}>
                    {data.label}
                  </StepButton>
                </Step>
              ))
            }
          </Stepper>
        ) : null
      }
      <div className={classes.contentWrapper}>
        {
          steps.map((data: any, key: any) => {
            if(key === activeStep) {
              return (
                <div className={classes.content} key={`k_${key}`}>{data.content}</div>
              );
            } else {
              return null;
            }
          })
        }
      </div>
      {
        showFooter ? (
          <div className={classes.footer}>
            {
              footerContent ? (
                <>
                  {footerContent} 
                </>
              ) : (
                <>
                  <NormalButton buttonType='secondary' disabled={activeStep === 0} onClick={handleBack}>
                    {t('previous_step')}
                  </NormalButton>
                  <NormalButton buttonType='primary' disabled={isLastStep} onClick={handleNext}>
                    {t('next_step')}
                  </NormalButton>
                </>
              )
            }
          </div>
        ) : null
      }
    </div>
  );
}

export default Desktop;
