import Input from 'src/components/Forms/Input';
import React from 'react';
import RenderSchools from '../RenderSchools';
import SVG from '../../../Images/SvgRenderer';
import Switch from 'src/components/Forms/Switch';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    width: '100%',
  },
  topWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    position: 'relative',
    zIndex: '1',
  },
  search: {
    flex: '1 1 auto',
    padding: '0 24px 24px 24px',
    width: 'calc(100% - 48px)',
    '& > form': {
      '& > div': {
        '& > span': {
          backgroundColor: 'transparent',
        },
        '& > input': {
          minHeight: '40px',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 12px 12px 12px',
    },
  },
  switch: {
    padding: '0 24px 24px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 12px 12px 0px',
      flexDirection: 'column',
      gap: '5px',
      '& > span:first-of-type': {
        marginTop: '5px',
      },
      '& > span:last-of-type': {
        fontSize: '10px',
      },
    },
  },
  noresults: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '18px 0',
    '& > img': {
      maxWidth: '80%',
    },
  },
  container: {
    width: '100%',  
  },
}));

type RenderContentType = {
  state: any;
  setState: any;
};

const RenderContent: React.FunctionComponent<RenderContentType> = ({
  state,
  setState,
}) => {

  const classes = useStyles();
  const { t } = useTranslation();
  let delayDebounceFn: any;

  const handleSearchChange = (name: any, value: any, e: any) => {
    e.stopPropagation();
    clearTimeout(delayDebounceFn);
    delayDebounceFn = setTimeout(() => {
      setState(name, value.toLowerCase());
    }, value.length === 0 ? 1 : 1000);
  };

  const handleArchivedSwitch = (value: any, e: any) => {
    e.stopPropagation();
    const isShowArchived = value;
    if(!isShowArchived) {
      const newSelectedEmployees = state.selectedEmployees.filter((item: any) => !state.functions.getEmployeeData(item.employeeID).isArchived);
      const stateNames = ["isShowArchived", "selectedEmployees"];
      const stateValues = [isShowArchived, newSelectedEmployees];
      setState(stateNames, stateValues);
    } else {
      setState("isShowArchived", isShowArchived); 
    }
  }; 

  return (
    <div className={classes.wrapper}>
      {
        !state.isInModal ? (
          <div className={`${classes.topWrapper} ${state.customClasses ? (state.customClasses.topWrapper ? state.customClasses.topWrapper : "") : ""}`}>
            {
              state.isAllowSearch ? (
                <div className={`${classes.search} ${state.customClasses ? (state.customClasses.search ? state.customClasses.search : "") : ""}`}>
                  <Input name="search" placeholder={t('search') + "..."} prepend={<SVG src="search"/>} onKeyDown={handleSearchChange} onChange={handleSearchChange} onKeyUp={handleSearchChange} dataCy='employeesSearch' dataClarityUnmask="true"/>                                                                                                                                                                                                                 
                </div>
              ) : null
            }  
            {
              state.isShowArchivedToggle ? (
                <Switch className={classes.switch} checked={state.isShowArchived} onChange={handleArchivedSwitch} label={t('archived')} dataCy='archivedSwitch'/>
              ) : null
            }  
          </div>
        ) : null
      }
      <div className={`${classes.container} ${state.customClasses ? (state.customClasses.container ? state.customClasses.container : "") : ""}`}>
        {
          state.functions.getEmployees().length === 0 ? (
            <div className={classes.noresults}>
              <img src="/resources/images/noresults.png" alt='no_results'/>
              {(t('no_results_found') + '...')}
            </div>
          ) : (
            <RenderSchools state={state} setState={setState}/>
          )
        }
      </div>
    </div>
  ); 
}

export default RenderContent;