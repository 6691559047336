import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    overflow: 'hidden',
    marginBottom: '30px',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      borderRadius: "0px",
    }, 
  },
  weekdayHeader: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.grey[335],
    borderRadius: '24px 24px 0 0',
    position: 'relative',
    zIndex: theme.zIndex.calendarHeader,
    [theme.breakpoints.down('md')]: {
      borderRadius: "0px",
    }, 
  },
  weekday: {
    width: 'calc(100% / 7)',
    padding: '10px',
    fontSize: '14px',
    '& p': {
      width: '100%',
      textAlign: 'center',
      marginBottom: '0',
    },
  },
  dateWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  loadingWrapper: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: theme.zIndex.calendarLoading,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(128,128,128,0.15)',
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

export default useStyles;