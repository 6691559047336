import config from 'src/constants/config';
import Input from 'src/components/Forms/Input';
import NormalButton from 'src/components/Buttons/NormalButton';
import NotFound from 'src/components/Layouts/NotFound';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import useStyles from './style';
import { ButtonBase, CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { getQueryParams, isKey } from 'src/utils/useFunctions';
import { isCypress } from 'src/utils/useCypress';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

type ReportTypeSelectType = {
  schoolID: any;
  reportTypeID: any;
  reportTypeData: any;
  onSelect: any;
  onBack: any;
};

const ReportTypeSelect: React.FunctionComponent<ReportTypeSelectType> = ({ schoolID, reportTypeID, reportTypeData, onSelect, onBack }) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const dataData = useAppSelector((state: any) => state.data);
  const reportsService = useAppSelector((state: any) => state.services).reportsService;

  const queryParams = getQueryParams(location);

  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;
  const disableWizard = queryParams.get("disableWizard");
  const isDisableWizard = disableWizard === "true" ? true : false;

  const limit = 16;

  const listSchools = dataData.schools;
  const listClasses = dataData.classes;
  const listChildren = dataData.children;

  const getSchools = () => {
    return listSchools.map((theSchool: any) => {
      return theSchool;
    }).filter((value: any) => value !== null);
  };

  const getChildren = () => {
    return listChildren.map((theChild: any) => {
      return theChild;
    }).filter((value: any) => value !== null);
  };

  const getClasses = () => {
    return listClasses.map((theClass: any) => {
      if(getChildren().filter((theChild: any) => theChild.classID.indexOf(theClass.classID) !== -1).length > 0) {
        return theClass;
      } else {
        return null;
      }
    }).filter((value: any) => value !== null);
  };

  const schoolsList = getSchools().map((theSchool: any) => {
    if(getClasses().filter((theClass: any) => theClass.schoolID === theSchool.schoolID).length > 0) {
      return theSchool;
    } else {
      return null;
    }
  }).filter((value: any) => value !== null);

  const [state, setState] = useStates({
    isLoading: false,
    reportTypes: [],
    noreportType: false,
    search: "",
    isMoreToLoad: false,
    page: 1,
  });

  const handleSearch = (value: any) => {
    setState("search", value);
  };

  const handleBack = () => {
    onBack();
  };

  const handleSelectReportView = (e: any, reportTypeID: any) => {
    e.stopPropagation();
    e.preventDefault();
    const reportTypeData = state.reportTypes.filter((item: any) => item.reportTypeID === reportTypeID).length === 1 ? state.reportTypes.find((item: any) => item.reportTypeID === reportTypeID) : null;
    onSelect(reportTypeData);
  };

  const handleLoadMore = () => {
    loadReportType(state.page);
  };

  const loadReportType = useCallback((page: any) => {
    setState("isLoading", true);
    const payload = {
      schoolID: schoolID,
      page: page,
      limit: limit,
    };
    reportsService && reportsService.listReportTypesList(payload).then((result: any) => {
      if(result && result.data) {
        const total = state.reportTypes.length;
        const response = result.data[config.JSONLD_DATA];
        const count = result.data[config.JSONLD_COUNT];
        if(count === 0) {
          if(total === 0) {
            setState("noreportTypes", true);
          }
          setState("isMoreToLoad", false);
          setState("isLoading", false);
        } else {
          setState("isMoreToLoad", response.length > count);
          setState("reportTypes", response);
          setState("page", state.page + 1);
          setState("isLoading", false);
        }
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("report_types_failed"), "error");
    });
  }, [reportsService, schoolID, setState, t]);

  useEffect(() => {
    setState("noreportTypes", false);
    loadReportType(state.page);
  }, [loadReportType], []);

  return (
    <div className={classes.reportViewSelectWrapper}>
      <div className={classes.reportTypeWrapper}>
        <div className={classes.search}>
          <Input className={classes.input} prepend={<SVG src="search"/>} type="text" placeholder={`${t('search')}...`} useName={false} onChange={handleSearch} disabled={(state.reportTypes.length === 0 && !state.noreportTypes && state.search === "")}/>
        </div>
        <div className={classes.reportTypeItems}>
          {
            (state.reportTypes.length === 0 && !state.noreportTypes && state.search === "") ? (
              <div className={classes.loading}>
                <CircularProgress className={classes.spinner}/>
              </div>
            ) : (
              <>
                {
                  state.reportTypes.filter((item: any) => item.reportTypeName.toLowerCase().includes(state.search.toLowerCase())).length === 0 ? (
                    <NotFound text={t('no_reports_view_found')}/>
                  ) : state.reportTypes.filter((item: any) => item.reportTypeName.toLowerCase().includes(state.search.toLowerCase())).map((item: any, key: any) => {
                    return (
                      <ButtonBase component="div" className={classes.reportTypeItem} onClick={(e: any) => handleSelectReportView(e, item.reportTypeID)} data-cy={isCypress() ? `reportType${item.reportTypeID}` : null} key={`k_${key}`}>
                        <div className={classes.reportTypeDetail}>
                          <div className={classes.reportTypeTopBar}>
                            <span className={classes.reportTypeInfo}>
                              <span className={classes.reportTypeName} data-cy={isCypress() ? 'reportTypeName' : null} data-clarity-unmask="true">
                                {item.reportTypeName}
                              </span>
                            </span>
                          </div>
                        </div>
                      </ButtonBase>
                    );
                  })
                }
              </>
            )
          }
        </div>
        {
          (!isEmbed || (isEmbed && !isDisableWizard)) ? (
            <div className={classes.footer}>
              {
                schoolsList.length !== 1 ? (
                  <NormalButton buttonType='secondary' onClick={handleBack}>{t('back')}</NormalButton>
                ) : null
              }
              {
                state.isMoreToLoad ? (
                  <NormalButton buttonType='primary' onClick={handleLoadMore} disabled={state.isLoading}>{t('report_types_load_more')}</NormalButton>
                ) : null
              }
            </div>
          ) : null
        }
      </div>
    </div>
  );
};

export default ReportTypeSelect;