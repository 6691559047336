import HttpService from '../http.service';

const listAttendance = (date: any, language?: string) => {
  return HttpService.get('v2', `attendance/${date}`, {customLanguage: language || undefined}, {});
};

const updateAttendance = (payload: any) => {
  return HttpService.post('v2', `attendance`, payload, {}, {});
};

export {
  listAttendance,
  updateAttendance,
};