import HttpService from '../http.service';

const listReportTypesList = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get('v3', `report_view_list${query ? `${query}`: ""}`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const listReportsView = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get('v3', `report_view${query ? `${query}`: ""}`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

export {
  listReportTypesList,
  listReportsView,
};