import React, { useCallback, useRef } from 'react';
import Select from 'src/components/Forms/Select';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserSetting, saveUserSettings } from 'src/utils/useUser';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 60px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    padding: '30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  selects: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '80%',
    '& > div:first-of-type': {
      '& > label': {
        marginTop: '0px',
      },
    },
  },
  select: {
    flex: 'unset',
    width: '100%',
  },
}));

const Communication: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const userData = useAppSelector((state: any) => state.user);

  const isSaving = useRef(false);

  const refreshTimes = useCallback(() => [
    {
      value: '0',
      name: t('never'),
    },
    {
      value: '30000',
      name: `30 ${t('seconds_more').toLowerCase()}`,
    },
    {
      value: '60000',
      name: `1 ${t('minute').toLowerCase()}`,
    },
    {
      value: '90000',
      name: `1.5 ${t('minutes').toLowerCase()}`,
    },
    {
      value: '120000',
      name: `2 ${t('minutes').toLowerCase()}`,
    },
    {
      value: '180000',
      name: `3 ${t('minutes').toLowerCase()}`,
    },
    {
      value: '300000',
      name: `5 ${t('minutes_more').toLowerCase()}`,
    },
  ], [t]);

  const autoRefreshThreads = getUserSetting(userData.userSettings, "customizations", ["communication", "autorefresh_threads"]);
  const autoRefreshMessages = getUserSetting(userData.userSettings, "customizations", ["communication", "autorefresh_messages"]);

  const [state, setState] = useStates({
    isSaving: false,
    autoRefreshThreads: refreshTimes().filter((item: any) => item.value === autoRefreshThreads.toString()).length === 0 ? refreshTimes()[2] : refreshTimes().find((item: any) => item.value === autoRefreshThreads.toString()),
    autoRefreshMessages: refreshTimes().filter((item: any) => item.value === autoRefreshMessages.toString()).length === 0 ? refreshTimes()[2] : refreshTimes().find((item: any) => item.value === autoRefreshMessages.toString()),
  });

  const handleAutoRefreshThreads = async (value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      setState("isSaving", true);
      const newValue = parseInt(value.value);
      const result = await saveUserSettings(dispatch, userData, "customizations", ["communication", "autorefresh_threads"], newValue);
      if(result) {
        createNotification(t("user_settings_saved"), "success");
        setState("autoRefreshThreads", value);
        isSaving.current = false;
        setState("isSaving", false);
      } else {
        const settings = {
          isOpen: true,
        };
        isSaving.current = false;
        setState("isSaving", false);
      }
    }
  };

  const handleAutoRefreshMessages = async (value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      setState("isSaving", true);
      const newValue = parseInt(value.value);
      const result = await saveUserSettings(dispatch, userData, "customizations", ["communication", "autorefresh_messages"], newValue);
      if(result) {
        createNotification(t("user_settings_saved"), "success");
        setState("autoRefreshMessages", value);
        isSaving.current = false;
        setState("isSaving", false);
      } else {
        const settings = {
          isOpen: true,
        };
        isSaving.current = false;
        setState("isSaving", false);
      }
    }
  };

  return (
    <>
    <div className={classes.box}>
      <div className={classes.selects}>
        <Select className={classes.select} label={t('communication_auto_refresh_threads')} items={refreshTimes()} selected={state.autoRefreshThreads} setSelected={handleAutoRefreshThreads} allowClear={false} disabled={state.isSaving}/>
        <Select className={classes.select} label={t('communication_auto_refresh_messages')} items={refreshTimes()} selected={state.autoRefreshMessages} setSelected={handleAutoRefreshMessages} allowClear={false} disabled={state.isSaving}/>
      </div>
    </div>
    </>
  );
};

export default Communication;