import { createUseStyles } from "react-jss";

interface Props {
  mode?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  wizard: {
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    borderRadius: '24px',
    padding: '16px',
    width: 'calc(100% - 32px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: 'calc(100% - 32px)',
  },
  stepper: {
    padding: '8px 8px',
    width: 'calc(100% - 16px)',
  },
  contentWrapper: {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 90px)',
    flex: '1 1 auto',
  },
  noContentWrapper: {
    display: 'none',
    width: '100%',
    height: 'calc(100% - 90px)',
    flex: '1 1 auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      minHeight: '16px',
    },
  },
  stepVisible: {
    visibility: 'visible',
  },
  stepHidden: {
    visibility: 'hidden',
    display: 'none',
  },
  stepButton: {
    backgroundColor: (props: Props) => {
      if(props.mode === "view") return theme.colors.grey[100];
      else return "";
    },
    borderRadius: '14px',
    '&:not(.Mui-disabled)': {
      '& .MuiStepIcon-root': {
        '&:not(.Mui-active)': {
          color: theme.colors.grey[800],
        },
      },
      '& .MuiStepLabel-label': {
        '&:not(.Mui-active)': {
          color: theme.colors.grey[800],
          fontWeight: '500',
        },
      },
    },
    '& .MuiStepLabel-label': {
      '&.Mui-active': {
        fontWeight: '600',
      }
    },
    '& p': {
      fontSize: '12px',
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
}));

  export default useStyles;