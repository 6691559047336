import config from 'src/constants/config';
import React, { useCallback } from 'react';
import ReportTypeSelect from './Components/ReportTypeSelect';
import Result from './Components/Result';
import SchoolSelect from './Components/SchoolSelect';
import useStyles from './style';
import Wizard from 'src/components/Layouts/Wizard';
import { createNotification } from 'src/utils/createNotification';
import { getQueryParams, isKey } from 'src/utils/useFunctions';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const PageTemplate: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const location = useLocation();

  const reportsService = useAppSelector((state: any) => state.services).reportsService;

  const queryParams = getQueryParams(location);
  const presetReportTypeID = queryParams.get("reportTypeID");
  const presetSchoolID = queryParams.get("schoolID");

  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;
  const disableWizard = queryParams.get("disableWizard");
  const isDisableWizard = disableWizard === "true" ? true : false;
  
  const classes = useStyles({
    containerHeight: layoutData.containerHeight,
  });

  const listSchools = dataData.schools;
  const listClasses = dataData.classes;
  const listChildren = dataData.children;

  const getSchools = () => {
    return listSchools.map((theSchool: any) => {
      return theSchool;
    }).filter((value: any) => value !== null);
  };

  const getChildren = () => {
    return listChildren.map((theChild: any) => {
      return theChild;
    }).filter((value: any) => value !== null);
  };

  const getClasses = () => {
    return listClasses.map((theClass: any) => {
      if(getChildren().filter((theChild: any) => theChild.classID.indexOf(theClass.classID) !== -1).length > 0) {
        return theClass;
      } else {
        return null;
      }
    }).filter((value: any) => value !== null);
  };

  const schoolsList = getSchools().map((theSchool: any) => {
    if(getClasses().filter((theClass: any) => theClass.schoolID === theSchool.schoolID).length > 0) {
      return theSchool;
    } else {
      return null;
    }
  }).filter((value: any) => value !== null);

  const [state, setState] = useStates({
    currentStep: 1,
    schoolID: schoolsList.length === 1 ? schoolsList[0].schoolID : null,
    reportTypeID: null,
    reportTypeData: [],
    isLoadingTable: false,
    isFirstLoad: true,
    filteredTable: null,
  });

  const handleSetCurrentStep = (step: any) => {
    setState("isFirstLoad", true);
    setState("currentStep", step);
  };

  const handleBack = () => {
    const step = state.currentStep - 1;
    handleSetCurrentStep(step);
  };

  const handleSetSchool = (schoolID: any) => {
    setState("schoolID", schoolID);
    setState("reportTypeID", null);
    setState("currentStep", state.currentStep + 1);
  };

  const handleSetReportType = (reportTypeData: any) => {
    setState("isFirstLoad", true);
    setState("reportTypeID", reportTypeData.reportTypeID);
    setState("reportTypeData", reportTypeData);
    setState("currentStep", state.currentStep + 1);
  };

  const handleState = (name: any, value: any) => {
    setState(name, value);
  };

  const steps = [
    schoolsList.length > 1 ? {
      label: t("school"),
      content: <SchoolSelect onSelect={handleSetSchool}/>,
      canSelect: true,
      optional: false,
      showFooter: false,
      footerContent: null,
    } : null,
    {
      label: t('type'),
      content: <ReportTypeSelect schoolID={state.schoolID} reportTypeID={state.reportTypeID} reportTypeData={state.reportTypeData} onSelect={handleSetReportType} onBack={handleBack}/>,
      canSelect: state.schoolID !== null,
      optional: false,
      showFooter: false,
      footerContent: null,
    },
    {
      label: t('result'),
      content: <Result state={state} setState={handleState} schoolID={state.schoolID} reportTypeID={state.reportTypeID} reportTypeData={state.reportTypeData} onBack={handleSetCurrentStep}/>,
      canSelect: state.schoolID !== null && state.reportTypeID !== null && state.reportTypeData !== null,
      showFooter: false,
      footerContent: null,
    }
  ];

  const stepsList = steps.filter((item: any) => item !== null);

  const getStepData: any = (key: any) => {
    return stepsList[key] ? stepsList[key] : [];
  };

  const preloadReportType = useCallback(() => {
    setState("schoolID", presetSchoolID);
    setState("reportTypeID", presetReportTypeID);
    setState("currentStep", stepsList.length);
    const payload = {
      schoolID: presetSchoolID,
      page: 1,
      limit: 1,
    };
    reportsService && reportsService.listReportTypesList(payload).then((result: any) => {
      if(result && result.data) {
        const response = result.data[config.JSONLD_DATA];
        const count = result.data[config.JSONLD_COUNT];
        if(count === 0) {
          createNotification(t("report_type_failed"), "error");
        } else {
          const reportTypeData = response[0];
          setState("reportTypeData", reportTypeData);
        }
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("report_types_failed"), "error");
    });
  }, [reportsService, presetSchoolID, setState, t]);

  useEffect(() => {
    if(presetReportTypeID && presetSchoolID) {
      preloadReportType();
    }
    if(presetReportTypeID && !presetSchoolID) {
      createNotification(t("reports_missing_report_type"), "error");
    }
    if(!presetReportTypeID && presetSchoolID) {
      createNotification(t("reports_missing_school"), "error");
    }
  }, [preloadReportType], [presetReportTypeID, presetSchoolID]);

  return (
    <div className={classes.reportsPage}>
      <div className={classes.wrapper}>
        <div className={classes.reports}>
          <Wizard className={classes.wizard} classNameStepper={classes.stepper} steps={stepsList} currentStep={state.currentStep} defaultStep={1} onStepChange={handleSetCurrentStep} showStepper={(!isEmbed || (isEmbed && !isDisableWizard))} showFooter={(!isEmbed || (isEmbed && !isDisableWizard)) ? getStepData(state.currentStep - 1).showFooter : false} footerContent={getStepData(state.currentStep - 1).footerContent}/>
        </div>
      </div>
    </div>
  );
};

export default PageTemplate;