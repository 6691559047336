import Filters from 'src/components/Layouts/Filters';
import IconButton from 'src/components/Buttons/IconButton';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import theme from 'src/ui/theme';
import useBreakpoint from 'src/utils/useBreakpoint';
import { createUseStyles } from 'react-jss';
import { getQueryParams, getQueryString, getSideContentSize, haveSameRoot, isValidHexColor } from 'src/utils/useFunctions';
import { isCypress } from '../../utils/useCypress';
import { setInitialPage, setTheme } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate } from 'react-router';
import { useMemo } from 'src/utils/useMemo';
import { useTranslation } from 'react-i18next';

interface Props {
  children: any;
};

interface ClassesProps {
  backgroundColor?: any;
  isDisplayContent?: any;
  isFiltersVisible?: any;
  isSideContent?: any;
  sideContentSize: any;
};

const useStyles = createUseStyles((_: any) => ({
  embedLayout: {
    width: '100vw',
    maxWidth: '100vw',
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'auto',
    backgroundColor: (props: ClassesProps) => props.backgroundColor,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  }, 
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    maxWidth: '100%',
    overflow: 'visible',
    '& *': {
      display: (props: ClassesProps) => {
        if(!props.isDisplayContent) return '';
        else return '';
      },
    },
    '& > div': {
      flex: '1 1 auto',
    },
  },
  backToInitialPageButton: {
    position: 'fixed',
    top: '32px',
    left: '64px',
    width: '48px',
    height: '48px',
    backgroundColor: theme.colors.primaryBlue[500],
    color: theme.colors.white,
    '& > svg': {
      width: '32px',
      height: '32px',
      color: 'inherit',
    },
    '&:hover': {
      backgroundColor: theme.colors.primaryBlue[600],
    },
  },
}));

const EmbedLayout: React.FunctionComponent<Props> = (props: Props) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const layoutData = useAppSelector((state: any) => state.layout);
  const isDisplayContent = useMemo(() => layoutData.isModalFullscreen ? false : true, [layoutData.isModalFullscreen]);
  const breakpoint: any = useBreakpoint();

  const queryParams = getQueryParams(location);
  const queryString = getQueryString(location);
  const background = queryParams.get("background");
  const appTheme = queryParams.get("theme");
  const backgroundColor = background === "gradient" ? theme.colors.gradient : (isValidHexColor(`#${background}`) ? `#${background}` : theme.colors.white);

  const classes = useStyles({
    backgroundColor: backgroundColor,
    isDisplayContent: isDisplayContent,
    isFiltersVisible: layoutData.isFiltersVisible,
    isSideContent: layoutData.sideContent !== null,
    sideContentSize: getSideContentSize(breakpoint),
  });

  const onMessage = useCallback((e: any) => {
    const data = e.data;
    if(data) {
      if(data.action) {
        if(data.action === "navigate" && data.to) {
          navigate(`${data.to}${queryString}`);
        }
      }
    }
  }, [navigate, queryString]);

  const handleBackToInitialPage = () => {
    navigate(`${layoutData.initialPage}${queryString}`);
  };

  useEffect(() => {
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [onMessage] , []);

  useEffect(() => {
    if(appTheme && appTheme === "dark") {
      dispatch(setTheme("dark"));
    }
  }, [appTheme, dispatch], []);

  useEffect(() => {
    if(!layoutData.initialPage) {
      if(!location.pathname.includes("/auth")) {
        dispatch(setInitialPage(location.pathname));
      }
    }
  }, [layoutData.initialPage, location.pathname, dispatch], [location.pathname]);

  return (
    <div className={classes.embedLayout} data-cy={isCypress() ? `embedLayout` : null}>
      <Filters/>
      <div className={classes.content} data-cy={isCypress() ? `embedLayoutContent` : null}> 
        {props.children}
      </div>
      {
        (location.pathname !== layoutData.initialPage && !haveSameRoot(location.pathname,layoutData.initialPage)) ? (
          <IconButton className={classes.backToInitialPageButton} onClick={handleBackToInitialPage} tooltip={t('back_to_initial_page')} tooltipMaxWidth={400}>
            <SVG src="arrow-left"/>
          </IconButton>
        ) : null
      }
    </div>
  );
}

EmbedLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default EmbedLayout;