import HttpService from '../http.service';

const listWatchdogs = (childID: any, date?: any) => {
  return HttpService.get('v2', `substitution/watchdog?childID=${childID}${date ? `&date=${date}` : ``}`, {}, {});
};

const listWatchdogsRange = (childID: any, dateFrom: any, dateTo: any) => {
  return HttpService.get('v2', `substitution/watchdog?childID=${childID}&dateFrom=${dateFrom}&dateTo=${dateTo}`, {}, {});
};

const findWatchdog = (watchdogID: any) => {
  return HttpService.get('v2', `substitution/watchdog?watchdogID=${watchdogID}`, {}, {});
};

const createWatchdog = (payload: any) => {
  return HttpService.post('v2', `substitution/watchdog`, payload, {}, {});
};

const deleteWatchdog = (watchdogID: any) => {
  return HttpService.remove('v2', `substitution/watchdog/${watchdogID}`, {});
};

export {
  listWatchdogs,
  listWatchdogsRange,
  findWatchdog,
  createWatchdog,
  deleteWatchdog,
};