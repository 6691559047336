import HttpService from '../http.service';

const listCategories = (schoolID: any) => {
  return HttpService.get('v2', `stock/category?schoolID=${schoolID}`, {}, {});
};

const listTags = (schoolID: any) => {
  return HttpService.get('v2', `stock/tag?schoolID=${schoolID}`, {}, {});
};

const listItems = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get('v2', `stock/item${query ? `${query}`: ""}`, {}, {});
};

const listOrders = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get('v2', `stock/order${query ? `${query}`: ""}`, {}, {});
};

const createOrder = (payload: any) => {
  return HttpService.post('v2', `stock/order`, payload, {}, {});
};

const editOrder = (orderID: any, payload: any) => {
  return HttpService.put('v2', `stock/order/${orderID}`, payload, {}, {});
};

const deleteOrder = (orderID: any) => {
  return HttpService.remove('v2', `stock/order/${orderID}`, {});
};

export {
  listCategories,
  listTags,
  listItems,
  listOrders,
  createOrder,
  editOrder,
  deleteOrder
};