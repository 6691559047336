import HttpService from '../http.service';

const listWage = (employeeID: any, schoolID: any, date: any) => {
  return HttpService.get('v2', `wage?employeeID=${employeeID}&schoolID=${schoolID}&date=${date}`, {}, {});
};

const downloadFile = (fileID: any) => {
  return HttpService.get('v2', `file/${fileID}`, {}, {responseType: 'arraybuffer'});
};

export {
  listWage,
  downloadFile,
};