import HttpService from '../http.service';

const exportClassbook = (date: any, schoolID: any, classID: any, format: 'pdf' | 'xlsx', custom?: any) => {
  return HttpService.get('v2', `export?type=classbook&format=${format}&schoolID=${schoolID}&classID=${classID}&date=${date}${custom ? `&${custom}` : ''}`, {}, {responseType: 'arraybuffer'});
};

const exportReport = (payload: any) => {
  return HttpService.post('v2', `export/report`, payload, {}, {responseType: 'arraybuffer'});
};

const exportReportGetColumns = (type: any) => {
  return HttpService.get('v2', `export/report-columns?type=${type}`, {}, {});
};

const exportReportGetTemplate = (type: any, schoolID: any) => {
  return HttpService.get('v2', `export/report-template?type=${type}&schoolID=${schoolID}`, {}, {});
};

const exportStockDeliveryList = (orderID?: any) => {
  return HttpService.get('v2', `stock/order/delivery-list?orderID=${orderID}`, {}, {responseType: 'arraybuffer'});
};

const exportReportCreateTemplate = (payload: any) => {
  return HttpService.post('v2', `export/report-template`, payload, {}, {});
};

const exportReportEditTemplate = (templateID: any, payload: any) => {
  return HttpService.put('v2', `export/report-template/${templateID}`, payload, {}, {});
};

const exportReportDeleteTemplate = (templateID: any) => {
  return HttpService.remove('v2', `export/report-template/${templateID}`, {});
};

export {
  exportClassbook,
  exportReport,
  exportReportGetColumns,
  exportReportGetTemplate,
  exportStockDeliveryList,
  exportReportCreateTemplate,
  exportReportEditTemplate,
  exportReportDeleteTemplate,
};