import config from 'src/constants/config';
import StorageService from 'src/services/storage.service';

const isLocalStorageExist = StorageService.existItem("twigsee.test");
const localStorage = isLocalStorageExist ? StorageService.getItem("twigsee.test") : "";
const paramsArray = localStorage.split(",");

const paramKeys = config.APP_TEST_PARAMS;

const testingParams: any = {};
paramKeys.forEach((key: any) => {
  const found = paramsArray.find((param: string) => param.startsWith(`${key}=`));
  testingParams[key] = found ? found.split("=")[1] : null;
});

export {
  testingParams,
};