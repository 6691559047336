import config from 'src/constants/config';
import Filter from '../Filter';
import moment from 'src/utils/moment';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback, useRef, useState } from 'react';
import Table from '../Table';
import useStyles from './style';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { getQueryParams, isKey } from 'src/utils/useFunctions';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import Select from 'src/components/Forms/Select';
import NotFound from 'src/components/Layouts/NotFound';

type ResultType = {
  schoolID: any;
  reportTypeID: any;
  reportTypeData: any;
  state: any;
  setState: any;
  onBack: any;
};

const Result: React.FunctionComponent<ResultType> = ({ schoolID, reportTypeID, reportTypeData, state, setState, onBack }) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const dataData = useAppSelector((state: any) => state.data);
  const reportsService = useAppSelector((state: any) => state.services).reportsService;

  const queryParams = getQueryParams(location);

  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;
  const disableWizard = queryParams.get("disableWizard");
  const isDisableWizard = disableWizard === "true" ? true : false;
  
  const presetDate = queryParams.get("date");
  const presetClassID = queryParams.get("classID");

  const listSchools = dataData.schools;
  const listClasses = dataData.classes;
  const listChildren = dataData.children;

  const getSchools = () => {
    return listSchools.map((theSchool: any) => {
      return theSchool;
    }).filter((value: any) => value !== null);
  };

  const getChildren = () => {
    return listChildren.map((theChild: any) => {
      return theChild;
    }).filter((value: any) => value !== null);
  };

  const getClasses = () => {
    return listClasses.map((theClass: any) => {
      if(getChildren().filter((theChild: any) => theChild.classID.indexOf(theClass.classID) !== -1).length > 0) {
        return theClass;
      } else {
        return null;
      }
    }).filter((value: any) => value !== null);
  };

  const schoolsList = getSchools().map((theSchool: any) => {
    if(getClasses().filter((theClass: any) => theClass.schoolID === theSchool.schoolID).length > 0) {
      return theSchool;
    } else {
      return null;
    }
  }).filter((value: any) => value !== null);

  const [resultData, setResultData]: any = useState([]);

  const filtersDataRef: any = useRef({
    date: presetDate ? moment(presetDate) : moment(),
    classID: presetClassID ? presetClassID : "",
  });

  const filters = reportTypeData.filters;

  const limit = 16;

  const tables: any = resultData.filter((item: any) => item.type === "table").map((item: any) => {
    return { name: item.title, value: item.id }; 
  });
  
  const handleBack = () => {
    setState("isFirstLoad", true);
    onBack(schoolsList.length === 1 ? 1 : 2);
  };

  const loadReportView = useCallback((page: any) => {
    setState("isLoadingTable", true);
    const payload = {
      schoolID: schoolID,
      reportTypeID: reportTypeID,
      date: moment(filtersDataRef.current.date).format("YYYY-MM-DD"),
      classID: (filtersDataRef.current.classID && typeof filtersDataRef.current.classID === "string") ? filtersDataRef.current.classID : '',
      page: page,
      limit: limit,
    };
    reportsService && reportsService.listReportsView(payload).then((result: any) => {
      if(result && result.data) {
        const response = result.data[config.JSONLD_DATA];
        const count = result.data[config.JSONLD_COUNT];
        if(count === 0) {
          setState("isLoadingTable", false);
          setState("isFirstLoad", false);
        } else {
          setResultData(response);
          setState("isLoadingTable", false);
          setState("isFirstLoad", false);
        }
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("report_view_failed"), "error");
    });
  }, [reportsService, schoolID, setState, t]);

  const onChange = (name: any, value: any) => {
    filtersDataRef.current = {...filtersDataRef.current, [name]: value};
    loadReportView(1);
  };

  const handleFilterTable = (value: any) => {
    setState("filteredTable", value);
  };

  useEffect(() => {
    loadReportView(1);
  }, [loadReportView], []);

  return (
    <div className={classes.resultWrapper}>
      <span>{reportTypeData.reportTypeName}</span>
      <div className={classes.filtersWrapper}>
        {
          (filters && filters.length >= 0) ? (
            <div className={classes.filters}>
              {
                filters.map((item: any, key: any) => (
                  <div key={`k_${key}`}>
                    <Filter mode={item.mode} name={item.name} options={item.options} title={item.title} type={item.type} defaultValue={item.defaultValue} onChange={onChange}/>
                  </div>
                ))
              }
            </div>
          ) : null
        }
        {
          tables.length > 1 ? (
            <Select className={classes.select} items={tables} selected={state.filteredTable} setSelected={handleFilterTable} inputLabel={t('filter_table')}/>
          ) : null
        }
      </div>
      <div className={classes.content}>
        {
          resultData.length === 0 ? (
            <>
            {
              state.isFirstLoad ? (
                <div className={classes.loading}>
                  <CircularProgress className={classes.spinner}/>
                </div>
              ) : (
                <NotFound title={t('no_report_found')}/>
              )
            }
            </>
          ) : null
        }
        {
          resultData.map((item: any, key: any) => (
            <div className={classes.wrapper} key={`k_${key}`} >
              {
                (item.type === "table" && (state.filteredTable === null || item.id === state.filteredTable.value)) ? (
                  <Table elements={item.elements} id={item.id} title={item.title} isLoading={state.isLoadingTable}/>
                ) : null
              }
            </div>
          ))
        }
      </div>
      {
        (!isEmbed || (isEmbed && !isDisableWizard)) ? (
          <div className={classes.footer}>
            <NormalButton buttonType='secondary' onClick={handleBack}>{t('back')}</NormalButton>
          </div>
        ) : null
      }
    </div>
  );
};

export default Result;