import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    color: theme.colors.black,
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: "20px",
  },
  accordions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  accordion: {
    width: '100%',
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    overflow: 'hidden',
    margin: '0 !important',
    borderRadius: '12px !important',
    '&.Mui-disabled': {
      '& .MuiAccordionSummary-expandIconWrapper': {
        display: 'none',
      },
    },
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    backgroundColor: theme.colors.grey[300],
    padding: '8px 8px',
    alignItems: 'center',
    gap: '8px',
    minHeight: 'unset !important',
    opacity: '1 !important',
    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      margin: '12px 0',
      '& > span': {
        fontSize: '16px',
        fontWeight: '600',
      },
      '& > p': {
        fontSize: '14px',
        marginLeft: 'auto',
      },
    },
    '& > .MuiAccordionSummary-expandIconWrapper': {
      '& > svg': {
        width: '24px',
        height: '24px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px 4px',
    },
  },
  accordionDetails: {
    padding: '16px',
    backgroundColor: theme.colors.grey[200],
    '& > p': {
      fontSize: '13px',
    }
  },
  list: {
    paddingInlineStart: '20px',
    listStyle: 'none',
    '& > li': {
      '&::before': {
        content: `'\\2022'`, 
        color: theme.colors.primaryBlue[500],
        fontWeight: '800',
        fontSize: '18px',
        display: 'inline-block',
        width: '1rem',
        marginLeft: '-1rem',
      },
      '& > span': {
        fontWeight: '500',
      }
    },
  },
  downloadButton: {
    minWidth: 'unset',
    width: 'fit-content',
    margin: '0 0 0 auto',
  },
}));

export default useStyles;