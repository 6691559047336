import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  schoolSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    height: 'fit-content',
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    borderRadius: '24px',
  },
  schoolsSelect: {
    maxHeight: '100%',
    overflow: 'auto',
    borderRadius: '24px',
    height: '100%',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '& > div:first-of-type': {
        '& > div': {
          padding: '24px',
        },
      },
      '& > div:last-of-type': {
        flex: '1 1 auto',
      },
    },
  },
  customContainer: {
    backgroundColor: theme.colors.grey[100],
    maxHeight: '100%',
    overflow: 'auto',
  },
  customTopWrapper: {
    position: 'sticky',
    top: '-0px',
    zIndex: '2',
    '& > div': {
      padding: '24px',
    },
  },
}));

export default useStyles;