import { attendanceSlice } from './slices/attendance.slice';
import { browserSlice } from './slices/browser.slice';
import { calendarSlice } from './slices/calendar.slice';
import { childrenSlice } from './slices/children.slice';
import { classbookSlice } from './slices/classbook.slice';
import { clubSlice } from './slices/club.slice';
import { commentsSlice } from './slices/comments.slice';
import { communicationSlice } from './slices/communication.slice';
import { configurationSlice } from './slices/configuration.slice';
import { controllersSlice } from './slices/controllers.slice';
import { dashboardSlice } from './slices/dashboard.slice';
import { dataSlice } from './slices/data.slice';
import { diagnosticsSlice } from './slices/diagnostics.slice';
import { drawersSlice } from './slices/drawers.slice';
import { excusenotesSlice } from './slices/excusenotes.slice';
import { filtersSlice } from './slices/filters.slice';
import { firebasenotificationSlice } from './slices/firebasenotification.slice';
import { gallerySlice } from './slices/gallery.slice';
import { languageSlice } from './slices/language.slice';
import { layoutSlice } from './slices/layout.slice';
import { loadingSlice } from './slices/loading.slice';
import { mediasSlice } from './slices/medias.slice';
import { modalsSlice } from './slices/modals.slice';
import { notificationSlice } from './slices/notification.slice';
import { photoarchiveSlice } from './slices/photoarchive.slice';
import { popupsSlice } from './slices/popups.slice';
import { servicesSlice } from './slices/services.slice';
import { stockSlice } from './slices/stock.slice';
import { substitutionsSlice } from './slices/substitutions.slice';
import { timelineSlice } from './slices/timeline.slice';
import { timetableSlice } from './slices/timetable.slice';
import { userSlice } from './slices/user.slice';
import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
  reducer: {
    attendance: attendanceSlice.reducer,
    browser: browserSlice.reducer,
    calendar: calendarSlice.reducer,
    classbook: classbookSlice.reducer,
    club: clubSlice.reducer,
    comments: commentsSlice.reducer,
    communication : communicationSlice.reducer,
    configuration: configurationSlice.reducer,
    controllers: controllersSlice.reducer,
    dashboard: dashboardSlice.reducer,
    data: dataSlice.reducer,
    diagnostics: diagnosticsSlice.reducer,
    drawers: drawersSlice.reducer,
    excusenotes: excusenotesSlice.reducer, 
    filters: filtersSlice.reducer,
    firebasenotification: firebasenotificationSlice.reducer,
    gallery: gallerySlice.reducer,
    children: childrenSlice.reducer,
    language: languageSlice.reducer,
    layout: layoutSlice.reducer,
    loading: loadingSlice.reducer,
    medias: mediasSlice.reducer,
    modals: modalsSlice.reducer,
    notification: notificationSlice.reducer,
    photoarchive: photoarchiveSlice.reducer,
    popups: popupsSlice.reducer,
    services: servicesSlice.reducer,
    stock: stockSlice.reducer,
    substitutions: substitutionsSlice.reducer,
    timeline: timelineSlice.reducer,
    timetable: timetableSlice.reducer,
    user: userSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware({
      serializableCheck: false,
    })
  ),
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;