import DateFormat from 'src/utils/dateFormat';
import moment from 'src/utils/moment';
import React, { useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import useStyles from './style';
import { CircularProgress } from '@mui/material';
import { convertToCapitalFirstLetter, createFileName } from 'src/utils/useFunctions';
import { createNotification } from 'src/utils/createNotification';
import { saveAs } from 'file-saver';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

type PhotoArchiveItemType = {
  childID: number;
  dateFrom: any;
  dateTo: any;
  count: number;
  size?: any;
  canDownload?: boolean;
};

const PhotoArchiveItem: React.FunctionComponent<PhotoArchiveItemType> = ({ childID, dateFrom, dateTo, count, size = 56, canDownload = true }) => {
  
  const { t } = useTranslation();
  const languageData = useAppSelector((state: any) => state.language);

  const galleryService = useAppSelector((state: any) => state.services).galleryService;
  
  const downloadFilesData: any = useRef([]);
  const downloadFileIndex: any = useRef(1);

  const [state, setState] = useStates({
    zipState: "none",
    zipSelected: [],
    zipCurrentIndex: 1,
  });

  const classes = useStyles({
    size: size,
    zipState: state.zipState,
    canDownload: canDownload,
  });

  const zipProgress = state.zipState === "running" ? Math.floor(((state.zipCurrentIndex - 1) / state.zipSelected.length) * 100) : 0;

  const handleDownloadChildDate = () => {
    if(state.zipState === "running" || !canDownload) {
      return;
    }
    const filterParams = {
      childID: childID,
      dateFrom: dateFrom,
      dateTo: dateTo,
      type: "photo",
      limit: count,
    };
    galleryService && galleryService.listMedias(filterParams).then((result: any) => {
      if(result && result.data && result.data.media) {
        const resultMedias = result.data.media;
        setState("zipSelected", resultMedias);
        setState("zipState", "running");
        handleDownload(resultMedias);
      }
    });
  };

  const handleZip = () => {
    if(downloadFilesData.current.length > 0) {
      const zip = require('jszip')();
      downloadFilesData.current.forEach((item: any) => {
        zip.file(item.name, item.base64, {base64: true});
      });
      zip.generateAsync({type:"blob"}).then((content: any) => {
        setState("zipCurrentIndex", 1);
        setState("zipSelected", []);
        setState("zipState", "stop");
        createNotification(t('gallery_download_zip_success'), 'success');
        setTimeout(() => {
          downloadFilesData.current = [];
          downloadFileIndex.current = 1;
          saveAs(content, `twigsee-download-${createFileName(DateFormat(moment(dateFrom), "yearmonthname", languageData, t))}.zip`);
        }, 1000);
      });
    } else {
      setTimeout(() => {
        setState("zipCurrentIndex", 1);
        setState("zipSelected", []);
        setState("zipState", "stop");
        createNotification(t('gallery_download_zip_failed'), 'error');
        downloadFilesData.current = [];
        downloadFileIndex.current = 1;
      }, 1000);
    }
  };

  const handleDownload = (files: any) => {
    if(files.length === 0) {
      setState("zipCurrentIndex", 1);
      setState("zipSelected", []);
      setState("zipState", "stop");
      createNotification(t('gallery_download_zip_failed'), 'error');
      return;
    }
    const index = downloadFileIndex.current;
    downloadFileIndex.current = index + 1;
    setState("zipCurrentIndex", index + 1);
    const file = files[index - 1];
    if(file && file.link) {
      const src = file.link;
      const headers = new Headers();
      fetch(src, {headers}).then(async (response) => {
        if(response) {
          if(response.status === 200) {
            const binaryData: any = await response.arrayBuffer();
            const bytes = new Uint8Array(binaryData);
            const len = bytes.byteLength;
            let string = '';
            for (let i = 0; i < len; i++) {
              string += String.fromCharCode(bytes[i]);
            }
            const base64 = btoa(string);
            const fileData = {
              base64: base64,
              name: file.name,
            };
            downloadFilesData.current = [...downloadFilesData.current, fileData];
            if(index === files.length) {
              handleZip();
            } else {
              handleDownload(files);
            }
          } else {
            if(index === files.length) {
              handleZip();
            } else {
              handleDownload(files);
            }
          }
      } else {
        if(index === files.length) {
          handleZip();
        } else {
          handleDownload(files);
        }
      }
      }).catch(() => {
        if(index === files.length) {
          handleZip();
        } else {
          handleDownload(files);
        }
      });
    } else {
      if(index === files.length) {
        handleZip();
      } else {
        handleDownload(files);
      }
    }
  };

  return (
    <div className={classes.photoArchiveItem}>
      <div onClick={handleDownloadChildDate}>
        <CircularProgress className={classes.circle} variant="determinate" value={100} thickness={2.5}/>
        {
          zipProgress ? (
            <CircularProgress className={classes.loading} variant="determinate" value={zipProgress} thickness={2.5}/>
          ) : null
        }
        <SVG src={state.zipState === "running" ? "zip-archive-outlined" : "download"}/>
      </div>
      <strong>{convertToCapitalFirstLetter(DateFormat(moment(dateFrom), "monthname", languageData, t))}</strong>
      <span>{count}</span>
    </div>
  );
}

export default PhotoArchiveItem;