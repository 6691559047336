import { createUseStyles } from 'react-jss';

interface Props {
  containerHeight?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  reportsPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '24px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '0',
      width: '100%',
      maxWidth: '100%',
    },
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100% - 16px)',
    maxHeight: 'calc(100% - 16px)',
    [theme.breakpoints.down('md')]: {
      height: 'unset !important',
      maxHeight: 'unset !important',
    },
  },
  reports: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    width: '100%',
    height: (props: Props) => `${props.containerHeight - 104}px`,
  },
  wizard: {
    backgroundColor: 'unset',
    boxShadow: 'unset',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0',
      height: '100%',
    },
  },
  stepper: {
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    borderRadius: '24px',
  },
}));

export default useStyles;