import { createUseStyles } from 'react-jss';

interface Props {
  size?: any;
  zipState?: any;
  canDownload?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  photoArchiveItem: {
    position: 'relative',
    width: (props: Props) => props.size + 'px',
    height: (props: Props) => (props.size + (props.size / 4)) + 'px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    zIndex: '0',
    gap: '8px',
    '& > strong': {
      fontSize: (props: Props) => (props.size / 4.5) + 'px',
      fontWeight: 'normal',
      position: 'absolute',
      top: (props: Props) => (props.size + (props.size / 16)) + 'px',
    },
    '& > span': {
      position: 'absolute',
      top: '-8px',
      right: '-12px',
      width: '22px',
      height: '22px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '9px !important',
      lineHeight: '9px !important',
      backgroundColor: theme.colors.primaryBlue[500],
      color: theme.colors.white,
      borderWidth: '3px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      borderRadius: '100%',
      zIndex: '3',
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      backgroundColor: (props: Props) => {
        if(props.zipState === "running" || !props.canDownload) return theme.colors.grey[225];
        return theme.colors.grey[200];
      },
      borderRadius: '100%',
      cursor: (props: Props) => {
        if(props.zipState === "running" || !props.canDownload) return 'default';
        return "pointer";
      },
      '& > svg': {
        width: (props: Props) => (props.size / 2) + 'px',
        height: (props: Props) => (props.size / 2) + 'px',
      },
    },
  },
  circle: {
    color: theme.colors.grey[225],
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '1',
    width: (props: Props) => props.size + 'px !important',
    height: (props: Props) => props.size + 'px !important',
  },
  loading: {
    color: theme.colors.primaryBlue[500],
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '2',
    width: (props: Props) => props.size + 'px !important',
    height: (props: Props) => props.size + 'px !important',
  },
}));
  
export default useStyles;