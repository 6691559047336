import htmlParse from 'html-react-parser';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import Tooltip from 'src/components/Layouts/Tooltip';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

type SubstitutionInfoType = {
  morningStatus?: any;
  afternoonStatus?: any;
  size?: any;
  text?: any;
  tooltip?: boolean;
  onClickMorning?: any;
  onClickAfternoon?: any;
};

const SubstitutionInfoItem: React.FunctionComponent<SubstitutionInfoType> = ({ morningStatus, afternoonStatus, size = 30, text, tooltip, onClickMorning, onClickAfternoon }) => {

  const { t } = useTranslation();
  const classes = useStyles({
    size: size,
    isClickable: onClickMorning || onClickAfternoon,
  });

  const titles: any = {
    wholeDay: {
      substitution: t('substitution_whole_day'),
      attendance: t('attendance_programme_whole_day'),
      watchdog: t('watchdog_whole_day'),
      create: t('substitution_create_whole_day'),
    },
    morning: {
      substitution: t('substitution_morning'),
      attendance: t('attendance_programme_morning'),
      watchdog: t('watchdog_whole_morning'),
      create: t('substitution_create_morning'),
    },
    afternoon: {
      substitution: t('substitution_afternoon'),
      attendance: t('attendance_programme_afternoon'),
      watchdog: t('watchdog_whole_afternoon'),
      create: t('substitution_create_afternoon'),
    },
  };

  const positions: any = {
    wholeDay: "top",
    morning: "top-end",
    afternoon: "top-start",
  };

  const getTooltipTitle = (dayPart: any, status: any) => {
    if(dayPart && status) {
      return titles[dayPart][status];
    } else {
      return "";
    }
  };

  const getTooltipPosition = (dayPart: any) => {
    if(dayPart) {
      return positions[dayPart];
    } else {
      return "";
    }
  };

  const TooltipContainer = ({children, type, status}: any) => {
    return (tooltip && children) ? (
      <>
        {
          (type === "wholeDay" && morningStatus === afternoonStatus) || (type === "morning" && morningStatus && morningStatus !== afternoonStatus) || (type === "afternoon" && afternoonStatus && afternoonStatus !== morningStatus) ? (
            <Tooltip title={getTooltipTitle(type, status)} position={getTooltipPosition(type)} maxWidth={200} align='center'>
              {children}
            </Tooltip>
          ) : (
            <>
              {
                type === "wholeDay" ? (
                  <span>
                    {children}
                  </span>
                ) : children
              }
            </>
          )
        }
      </>
    ) : children;
  };
  
  const handleOnClickMorning = (e: any) => { 
    e.preventDefault();
    e.stopPropagation();
    if(onClickMorning) onClickMorning(e);
  };

  const handleOnClickAfternoon = (e: any) => { 
    e.preventDefault();
    e.stopPropagation();
    if(onClickAfternoon) onClickAfternoon(e);
  };

  return (
    <div className={classes.wrapper}>
      <TooltipContainer type="wholeDay" status={morningStatus}>
        <div className={classes.substitutionInfoItem}>
          <div className={classes.capsule}>
            {
              morningStatus === afternoonStatus ? (
                <>
                  {
                    morningStatus === "substitution" ? (
                      <div className={classes.substitutionPart} onClick={handleOnClickMorning}/>
                    ) : morningStatus === "attendance" ? (
                      <div className={classes.attendancePart} onClick={handleOnClickMorning}/>
                    ) : morningStatus === "watchdog" ? (
                      <div className={classes.watchdogPart} onClick={handleOnClickMorning}>
                        <SVG src="watchdog"/>
                      </div>
                    ) : morningStatus === "create" ? (
                      <div className={classes.createPart} onClick={handleOnClickMorning}>
                        <SVG src="plus"/>
                      </div>
                    ) : null
                  }
                </>
              ) : (
                <>
                  <TooltipContainer type="morning" status={morningStatus}>
                    {
                      morningStatus === "substitution" ? (
                        <div className={classes.substitutionPartAM} onClick={handleOnClickMorning}/>
                      ) : morningStatus === "attendance" ? (
                        <div className={classes.attendancePartAM} onClick={handleOnClickMorning}/>
                      ) : morningStatus === "watchdog" ? (
                        <div className={classes.watchdogPartAM} onClick={handleOnClickMorning}>
                          <SVG src="watchdog"/>
                        </div>
                      ) : morningStatus === "create" ? (
                        <div className={classes.createPartAM} onClick={handleOnClickMorning}>
                          <SVG src="plus"/>
                        </div>
                      ) : null
                    }
                  </TooltipContainer>
                  <TooltipContainer type="afternoon" status={afternoonStatus}>
                    {
                      afternoonStatus === "substitution" ? (
                        <div className={classes.substitutionPartPM} onClick={handleOnClickAfternoon}/>
                      ) : afternoonStatus === "attendance" ? (
                        <div className={classes.attendancePartPM} onClick={handleOnClickAfternoon}/>
                      ) : afternoonStatus === "watchdog" ? (
                        <div className={classes.watchdogPartPM} onClick={handleOnClickAfternoon}>
                          <SVG src="watchdog"/>
                        </div>
                      ) : afternoonStatus === "create" ? (
                        <div className={classes.createPartPM} onClick={handleOnClickAfternoon}>
                          <SVG src="plus"/>
                        </div>
                      ) : null
                    }
                  </TooltipContainer>
                </>
              )
            }
          </div>
        </div>
      </TooltipContainer>
      {
        text ? (
          <span>{htmlParse(text)}</span>
        ) : null
      }
    </div>
  );
};

export default SubstitutionInfoItem;