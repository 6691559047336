import CalendarEventItem from 'src/components/Items/CalendarEventItem';
import CalendarEventsItem from 'src/components/Items/CalendarEventsItem';
import Clamp from 'react-multiline-clamp';
import moment from '../../../../../utils/moment';
import React, { useCallback, useMemo, useRef } from 'react';
import theme from '../../../../../ui/theme';
import { compareDates } from '../../../../../utils/date';
import { createUseStyles } from 'react-jss';
import { getCalendarBackgroundColor, getCalendarDateColor } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from '../../../../../utils/useCypress';
import { setCalendarEventHandleModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  getCalendarBackgroundColor: any;
  getCalendarDateColor: any;
};

const useStyles = createUseStyles((theme: any) => ({
  eventsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    minWidth: '100%',
    height: '100%',
    gap: '10px',
    backgroundColor: (props: Props) => props.getCalendarBackgroundColor,
    '& .fontGray': {
      color: '#bcbcbc',
    },
    '& .fontDarkGray': {
      color: theme.colors.grey[550],
      fontWeight: 'normal',
    },
  },
  date: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    fontSize: '14px',
    marginTop: '5px',
    fontWeight: '800',
    position: 'relative',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '28px',
      height: '28px',
      borderRadius: '100%',
      '&:hover': {
        backgroundColor: (props: Props) => props.getCalendarDateColor,
      },
    },
  },
  today: {
    '& > span': {
      color: theme.colors.primaryBlue[500],
      backgroundColor: theme.colors.grey[250],
      '&:hover': {
        backgroundColor: `${theme.colors.grey[250]} !important`,
      },
    },
  },
  events: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    gap: '4px',
    padding: '8px 0',
    minHeight: 'calc(40% - 16px)',
    height: 'calc(40% - 16px)',
    maxHeight: 'calc(40% - 16px)',
  },
  moreEvents: {
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    width: 'calc(100% - 24px)',
    padding: '0 8px',
    height: '30px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    position: 'relative',
    color: theme.colors.black,
    transition: 'background-color 0.25s',
    cursor: 'pointer',
    '& > svg': {
      display: 'block',
      width: '16px',
      height: '16px',
    },
    '&:hover': {
      backgroundColor: theme.colors.primaryBlue[50],
    },
  },
  name: {
    maxWidth: '100%',
    fontSize: '11px',
    lineHeight: '13px',
    textAlign: 'center',
    wordBreak: 'break-all',
    margin: 'auto 0',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
    },
  },
  hours: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: '15px 0 8px 0',
    backgroundColor: (props: Props) => props.getCalendarBackgroundColor,
  },
  hour: {
    display: 'flex',
    '& > span': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      width: '100px',
      fontSize: '14px',
      padding: '0 16px',
      '& > p': {
        position: 'absolute',
        top: '-10px',
      },
    },
    '& > div': {
      position: 'relative',
      borderLeftWidth: '1px',
      borderLeftStyle: 'solid',
      borderLeftColor: theme.colors.grey[400],
      minHeight: '200px',
    },
    '&:first-child': {
      '& > span': {
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: theme.colors.grey[400],
        '& > p': {
          visibility: 'hidden',
        },
      },
      '& > div': {
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: theme.colors.grey[400],
        '&::before': {
          content: `''`,
          position: 'absolute',
          top: '-15px',
          left: '-1px',
          height: '15px',
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          borderLeftColor: theme.colors.grey[400],
        },
      },
    },
    '&:not(:first-child)': {
      '& > div': {
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: theme.colors.grey[400],
        '&::before': {
          content: `''`,
          position: 'absolute',
          top: '-1px',
          left: '-15px',
          width: '15px',
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: theme.colors.grey[400],
        },
      },
    },
  },
}));

type DateItemType = {
  index?: any;
  curDate?: any;
  date?: any;
};

const DateItem: React.FunctionComponent<DateItemType> = ({index, curDate, date}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const browserData = useAppSelector((state: any) => state.browser);
  const calendarData = useAppSelector((state: any) => state.calendar);
  const userData = useAppSelector((state: any) => state.user);
  const allEvents = calendarData.events;
  const isLoading = calendarData.isLoading;
  
  const getEvents = (allEvents: any, date: any) => {
    return allEvents.filter((item: any) => moment(date).isSameOrAfter(item.dateTimeFrom, 'day') && moment(date).isSameOrBefore(item.dateTimeTo, 'day'));
  };

  const [state, setState] = useStates({
    anchorEl: null,
    eventsCount: 1,
  });

  const events = useMemo(() => getEvents(allEvents, date), [allEvents, date]);
  const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]; 

  const eventWrapperRefs: any = useRef([]);
  const eventsRef: any = useRef(null);

  const classes = useStyles({
    getCalendarBackgroundColor: getCalendarBackgroundColor(date, index, theme),
    getCalendarDateColor: getCalendarDateColor(date, index, theme),
  });

  const handleAddEvent = (e: any) => {
    if(getUserRole(userData.userObject.roleType) === "director" || getUserRole(userData.userObject.roleType) === "teacher") {
      if(eventWrapperRefs.current["wrapper"] === e.target || eventWrapperRefs.current["date"] === e.target || eventWrapperRefs.current["span"] === e.target || eventWrapperRefs.current["hours"] === e.target  || eventsRef.current === e.target) {
        e.preventDefault();
        e.stopPropagation();
        const settings = {
          isOpen: true,
          date: date,
          dateTime: null,
          eventID: null,
        };
        dispatch(setCalendarEventHandleModal(settings));
      }
    }
  };

  const handleAddHourEvent = (e: any, hour: any) => {
    if(getUserRole(userData.userObject.roleType) === "director" || getUserRole(userData.userObject.roleType) === "teacher") {
      if(eventWrapperRefs.current["hourSpan" + hour] === e.target || eventWrapperRefs.current["hourP" + hour] === e.target || eventWrapperRefs.current["hourEvents" + hour] === e.target) {
        e.preventDefault();
        e.stopPropagation();
        const settings = {
          isOpen: true,
          date: null,
          dateTime: moment(date).set('hour', hour).set('minute', 0),
          eventID: null,
        };
        dispatch(setCalendarEventHandleModal(settings));
      }
    }
  };

  const getCount = useCallback(() => {
    if(eventsRef.current) {
      const height = eventsRef.current.clientHeight - 16;
      const count = Math.floor(height / 34);
      setState("eventsCount", count < 1 ? 1 : count);
   } else {
    setState("eventsCount", 1);
   }
  }, [setState]);

  useEffect(() => {
    setTimeout(getCount, 100);
  }, [getCount], [browserData.height, isLoading]);

  useEffect(() => {
    setTimeout(getCount, 1000);
  }, [getCount], []);

  const handleClickMoreItems = (e: any) => {
    setState("anchorEl", eventsRef.current);
  };

  const handleCloseMoreItems = () => {
    setState("anchorEl", null);
  };

  useEffect(() => {
    if(eventWrapperRefs && eventWrapperRefs.current["hours"]) {
      eventWrapperRefs.current["hours"].scrollTo({
        top: 0,
      });
    }
  }, [isLoading]);

  const moreEvents = state.eventsCount === events.length ? false : events.length > state.eventsCount;
  const moreEventsCount = events.length - state.eventsCount + 1;

  return (
    <div className={classes.eventsWrapper} onDoubleClick={handleAddEvent} data-cy={isCypress() ? "calendarDayItem-" + moment(date).format("YYYY-MM-DD") : null} ref={(el: any) => (eventWrapperRefs.current["wrapper"] = el)}>
      <p className={`${classes.date} ${compareDates(date, moment()) ? classes.today : ''} ${moment(date).month() !== moment(curDate).month() ? 'fontGray' : null} ${(moment(date).day() === 6 || moment(date).day() === 0) ? 'fontDarkGray' : null}`} ref={(el: any) => (eventWrapperRefs.current["date"] = el)}>
        <span ref={(el: any) => (eventWrapperRefs.current["span"] = el)} data-clarity-unmask="true">
          {date.date()}
        </span>
      </p>
      {
        events.length !== 0 ? (
          <div className={classes.events} ref={eventsRef}>
            {
              events.filter((item: any) => moment(item.dateTimeFrom).format("HH:mm:ss") === "00:00:00" && moment(item.dateTimeTo).format("HH:mm:ss") === "00:00:00").slice(0, state.eventsCount === events.length ? events.length : (state.eventsCount - 1)).map((item: any, key: any) => (
                <CalendarEventItem data={item} date={date} overlaping={true} key={`k_${key}`}/>
              ))
            }
            {
              moreEvents ? (
                <>
                  <div className={classes.moreEvents} onClick={handleClickMoreItems}>
                    <Clamp withTooltip lines={1}>
                      <span className={classes.name} data-clarity-unmask="true">{ moreEventsCount < 5 ? t('calendar_more', {count: moreEventsCount}).toLowerCase() : t('calendar_more_more', {count: moreEventsCount}).toLowerCase()}</span>
                    </Clamp> 
                  </div> 
                  <CalendarEventsItem anchorEl={state.anchorEl} onClose={handleCloseMoreItems} date={date} events={events}/>
                </>
              ) : null
            }
          </div>
        ) : null
      }
      <div className={classes.hours} onDoubleClick={handleAddEvent} ref={(el: any) => (eventWrapperRefs.current["hours"] = el)}>
        {
          hours.map((hour: any, key: any) => { 
          const hourEvents = events.filter((item: any) => (moment(item.dateTimeFrom).format("HH:mm:ss") !== "00:00:00" && moment(item.dateTimeTo).format("HH:mm:ss") !== "00:00:00") || ((moment(item.dateTimeFrom).format("HH:mm:ss") === "00:00:00" || moment(item.dateTimeTo).format("HH:mm:ss") === "00:00:00") && moment(item.dateTimeFrom).isSame(moment(item.dateTimeTo), "date"))).map((item: any) => {
              if(item.dateTimeFrom !== item.dateTimeTo) {
                if(moment(date).isBetween(moment(item.dateTimeFrom), moment(item.dateTimeTo), 'day', '[]')) {
                  if(moment(item.dateTimeFrom).isSame(moment(item.dateTimeTo), 'day')) {
                    if(hour >= moment(item.dateTimeFrom).hour() && hour <= moment(item.dateTimeTo).hour()) {
                      return item;
                    } else {
                      return null;
                    }
                  } else {
                    if(moment(date).isSame(moment(item.dateTimeFrom), 'day')) {
                      if(hour >= moment(item.dateTimeFrom).hour()) {
                        return item;
                      } else {
                        return null;
                      }
                    } else if(moment(date).isSame(moment(item.dateTimeTo), 'day')) {
                      if(hour <= moment(item.dateTimeTo).hour()) {
                        return item;
                      } else {
                        return null;
                      }
                    } else {
                      return null;
                    }
                  }
                } else {
                  return null;
                }
              } else {
                return null;
              }
            }).filter((item: any) => item !== null);
            return (
              <div className={classes.hour} key={`k_${key}`} onDoubleClick={(e: any) => handleAddHourEvent(e, hour)} ref={(el: any) => (eventWrapperRefs.current["hour" + hour] = el)}>
                <span ref={(el: any) => (eventWrapperRefs.current["hourSpan" + hour] = el)}>
                  <p ref={(el: any) => (eventWrapperRefs.current["hourP" + hour] = el)} data-clarity-unmask="true">{moment().set('hour', hour).set('minute', 0).format("HH:mm")}</p>
                </span>
                <div className={classes.events} ref={(el: any) => (eventWrapperRefs.current["hourEvents" + hour] = el)}>
                  {
                    hourEvents.map((item: any, key: any) => (
                      <CalendarEventItem data={item} date={date} overlaping={false} key={`k_${key}`}/>
                    ))
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default DateItem;