import AttendanceInfoItem from '../AttendanceInfoItem';
import AuthenticatedImage from '../AuthenticatedImage';
import Clamp from 'react-multiline-clamp';
import React, { useCallback, useMemo } from 'react';
import TooltipIcon from 'src/components/Icons/TooltipIcon';
import { createUseStyles } from 'react-jss';
import { getUserRole } from '../../../utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { setChildCardModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  isSelected?: boolean;
  isDisabled?: boolean;
  postSeen?: boolean;
  mode?: any;
  attendanceMethod?: any;
  planned?: any;
  disableClick?: any;
  size?: any;
}

const useStyles = createUseStyles((theme: any) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'inherit',
    color: (props: Props) => {
      if(props.mode === "select") {
        if(props.isSelected) return theme.colors.primaryBlue[500];
        else return '#808080';  
      }
      else if(props.mode === "seen") {
        if(props.postSeen) return theme.colors.primaryBlue[500];
        else return '#808080';  
      }
      else if(props.mode === "attendance") {
        if(props.attendanceMethod === "realtime") {
          if(props.isSelected) return '#5A5C7B';
          else return '#B1B8C1';
        } if(props.attendanceMethod === "planned") {
          if(props.planned !== "none") return '#5A5C7B';
          else return '#B1B8C1';
        }
        else return '#B1B8C1';
      }
      else if(props.mode === "answered") return "#5A5C7B";
      else if(props.mode === "notanswered") return "#808080";
      else return '#808080';
    },
    fontSize: '14px',
    cursor: (props: Props) => (props.disableClick || props.isDisabled) ? 'auto' : 'pointer',
    '& > div': {
      position: 'relative',
      borderWidth: (props: Props) => {
        if(props.mode === "select") return (props.size / 15) + 'px';
        else if(props.mode === "attendance") return (props.size / 10) + 'px';
        else return '0px';
      },
      borderStyle: (props: Props) => {
        if(props.mode === "select") return 'solid';
        else if(props.mode === "attendance") return 'solid';
        else return '';
      },
      borderColor: (props: Props) => {
        if(props.mode === "select") {
          if(props.isSelected) return theme.colors.primaryBlue[500];
          else return 'transparent';
        }
        else if(props.mode === "attendance") {
          if(props.attendanceMethod === "realtime") {
            if(props.isSelected) return '#55CEAD';
            else return 'transparent';
          }
          else return 'transparent';
        }
        else return 'transparent';
      },
      borderRadius: '100%',
      '&::before': {
        content: `''`,
        position: 'absolute',
        top: (props: Props) => {
          if(props.mode === "attendance") return `-${(props.size / 10) / 2}px`;
          else return '0px';
        },
        left: (props: Props) => {
          if(props.mode === "attendance") return `-${(props.size / 10) / 2}px`;
          else return '0px';
        },
        display: (props: Props) => {
          if(props.attendanceMethod === "planned") {
            if(props.planned === "am" || props.planned === "all") return 'block';
            else return 'none';
          }
          else return 'none'; 
        },
        borderWidth: (props: Props) => {
          if(props.mode === "attendance") return `${(props.size / 10)}px`;
          else return '0px';
        },
        borderStyle: (props: Props) => {
          if(props.mode === "attendance") return 'solid';
          else return '';
        },
        borderTopColor: (props: Props) => {
          if(props.mode === "attendance") return '#55CEAD';
          else return 'transparent';
        },
        borderLeftColor: (props: Props) => {
          if(props.mode === "attendance") return '#55CEAD';
          else return 'transparent';
        },
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        transform: 'rotate(-45deg)',
        borderRadius: (props: Props) => `${(props.size / 1.6875)}px`,
        width: (props: Props) => `calc(100% - ${(props.size / 10)}px)`,
        height: (props: Props) => `calc(100% - ${(props.size / 10)}px)`,
        zIndex: '1',
      },
      '&::after': {
        content: `''`,
        position: 'absolute',
        top: (props: Props) => {
          if(props.mode === "attendance") return `-${(props.size / 10) / 2}px`;
          else return '0px';
        },
        left: (props: Props) => {
          if(props.mode === "attendance") return `-${(props.size / 10) / 2}px`;
          else return '0px';
        },
        display: (props: Props) => {
          if(props.attendanceMethod === "planned") {
            if(props.planned === "pm" || props.planned === "all") return 'block';
            else return 'none';
          }
          else return 'none'; 
        },
        borderWidth: (props: Props) => {
          if(props.mode === "attendance") return `${(props.size / 10)}px`;
          else return '0px';
        },
        borderStyle: (props: Props) => {
          if(props.mode === "attendance") return 'solid';
          else return '';
        },
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: (props: Props) => {
          if(props.mode === "attendance") return '#55CEAD';
          else return 'transparent';
        },
        borderBottomColor: (props: Props) => {
          if(props.mode === "attendance") return '#55CEAD';
          else return 'transparent';
        },
        transform: 'rotate(-45deg)',
        borderRadius: (props: Props) => `${(props.size / 1.6875)}px`,
        width: (props: Props) => `calc(100% - ${(props.size / 10)}px)`,
        height: (props: Props) => `calc(100% - ${(props.size / 10)}px)`,
        zIndex: '1',
      },
    },
  },
  avatarWrapper: {
    position: 'relative',
    '& > div:first-of-type': {
      filter: (props: Props) => {
        if(props.mode === "select") {
          if(!props.isSelected) return 'grayscale(100%)';
          else return 'grayscale(0%)';
        } else if(props.mode === "attendance") {
          if(props.attendanceMethod === "realtime") {
            if(!props.isSelected) return 'grayscale(100%)';
            else return 'grayscale(0%)';
          } else if(props.attendanceMethod === "planned") {
            if(props.planned === "none") return 'grayscale(100%)';
            else return 'grayscale(0%)';
          }
          else return 'grayscale(0%)';
        }
        else if(props.mode === "notanswered") return "grayscale(100%)";
        else return 'grayscale(0%)';
      },
    },
  },
  avatar: {
    display: 'block',
    minWidth: (props: Props) => props.size + 'px',
    width: (props: Props) => props.size + 'px',
    maxWidth: (props: Props) => props.size + 'px',
    minHeight: (props: Props) => props.size + 'px',
    height: (props: Props) => props.size + 'px',
    maxHeight: (props: Props) => props.size + 'px',  
    borderRadius: '100%',
    backgroundColor: 'transparent !important',
    '& > div': {
      backgroundColor: theme.colors.white,
      borderRadius: '100%',    
    },
  },
  gdprBadge: {
    position: 'absolute',
    width: (props: Props) => (props.size / 1.85) + 'px',
    height: (props: Props) => (props.size / 1.85) + 'px',
    bottom: (props: Props) => '-' + (props.size / 11) + 'px',
    right: (props: Props) => '-' + (props.size / 5.5) + 'px',
    filter: (props: Props) => !props.isSelected ? 'grayscale(100%)' : 'grayscale(0%)',
    '& > svg': {
      '--circle-color': theme.colors.alwaysWhite,
      '--mask-color': theme.colors.alwaysBlack,
      '--mask-rect-color': theme.colors.alwaysWhite,
      '--path-first-color': theme.colors.systemRed[500],
      '--path-last-color': theme.colors.alwaysWhite,
    },
    zIndex: '2',
  },
  archivedBadge: {
    width: (props: Props) => (props.size / 2) + 'px',
    height: (props: Props) => (props.size / 2) + 'px',
    backgroundColor: theme.colors.grey[200],
    position: 'absolute',
    right: (props: Props) => '-' + (props.size / 15) + 'px',
    bottom: (props: Props) => '-' + (props.size / 15) + 'px',
    borderRadius: '50%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '2',
    '& > svg': {
      width: (props: Props) => (props.size / 3) + 'px',
      height: (props: Props) => (props.size / 3) + 'px',
      color: theme.colors.black + ' !important',
      transform: 'scale(0.8)' 
    },
  },
  classBadge: {
    position: 'absolute',
    padding: (props: Props) => (props.size / 25) + 'px ' + (props.size / 15) + 'px',
    right: (props: Props) => '-' + (props.size / 4) + 'px',
    bottom: (props: Props) => '-' + (props.size / 6) + 'px',
    backgroundColor: theme.colors.grey[100],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[300],
    borderRadius: '50px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.colors.grey[700],
    fontWeight: '500',
    fontSize: '12px',
    maxWidth: '50%',
    display: 'block',
    zIndex: '2',
  },
  seenBadge: {
    width: (props: Props) => (props.size / 2) + 'px',
    height: (props: Props) => (props.size / 2) + 'px',
    backgroundColor: (props: Props) => props.postSeen ? theme.colors.primaryBlue[500] : theme.colors.grey[500],
    position: 'absolute',
    right: (props: Props) => '-' + (props.size / 15) + 'px',
    bottom: (props: Props) => '-' + (props.size / 15) + 'px',
    borderRadius: '50%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '2',
    '& > svg': {
      width: (props: Props) => (props.size / 3) + 'px',
      height: (props: Props) => (props.size / 3) + 'px',
      color: theme.colors.white + ' !important',
      transform: 'scale(0.8)' 
    },
  },
  clubStateBadge: {
    width: (props: Props) => (props.size / 2) + 'px',
    height: (props: Props) => (props.size / 2) + 'px',
    position: 'absolute',
    right: (props: Props) => '-' + (props.size / 15) + 'px',
    bottom: (props: Props) => '-' + (props.size / 15) + 'px',
    borderRadius: '50%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '2',
    '&.state1': {
      backgroundColor: theme.colors.systemOrange[200],
      '& > svg': {
        color: theme.colors.systemOrange[500],
      },
    },
    '&.state2': {
      backgroundColor: theme.colors.systemGreen[200],
      '& > svg': {
        color: theme.colors.systemGreen[500],
      },
    },
    '&.state3': {
      backgroundColor: theme.colors.systemRed[200],
      '& > svg': {
        color: theme.colors.systemRed[500],
      },
    },
    '& > svg': {
      width: (props: Props) => (props.size / 3) + 'px',
      height: (props: Props) => (props.size / 3) + 'px',
      transform: 'scale(0.8)' 
    },
  },
  attendanceBadge: {
    position: 'absolute',
    right: '-10px',
    bottom: '-2px',
    zIndex: '2',
  },
  childDisplayName: {
    maxWidth: '100%',
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: 'center',
    marginTop: '6px',
    wordBreak: 'break-all',
    fontWeight: (props: Props) => {
      if(props.mode === "select") {
        if(props.isSelected) return 'bold';
        else return 'normal';
      }
      else if(props.mode === "attendance") return '500'; 
      else if(props.mode === "answered") return 'bold';
      else return 'normal';
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
    },
  },
}));

type ChildInfoItemType = {
  childID: any;
  isSelected?: boolean;
  isDisabled?: boolean;
  isPostSeen?: boolean;
  defaultClassData?: any;
  defaultChildData?: any;
  mode?: any;
  attendanceMethod?: any;
  planned?: 'am' | 'pm' | 'all' | 'none',
  isVisibleGDPR?: any;
  isVisibleClass?: any;
  isVisibleArchived?: any;
  useModal?: any;
  disableClick?: boolean;
  onClick?: any;
  size?: any;
  clampLines?: number;
};

const ChildInfoItem: React.FunctionComponent<ChildInfoItemType> = ({ childID, isSelected = false, isDisabled = false, isPostSeen = false, defaultClassData, defaultChildData, mode, attendanceMethod = "realtime", planned, isVisibleGDPR, isVisibleArchived, isVisibleClass, useModal, disableClick, onClick, size = 60, clampLines = 2 }) => {

  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const childData: any = useMemo(() => defaultChildData ? Object.assign({}, defaultChildData, dataData.children.find((item: any) => item.childID === childID)) : dataData.children.find((item: any) => item.childID === childID), [childID, dataData.children, defaultChildData]);
  
  const classes = useStyles({
    isSelected: isSelected,
    isDisabled: isDisabled,
    postSeen: isPostSeen,
    mode: mode,
    attendanceMethod: attendanceMethod,
    planned: planned ? planned : childData.planned,
    disableClick: disableClick,
    size: size,
  });
  
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state: any) => state.user);
  const onClickAction = onClick ? onClick : undefined;
  
  const onModalAction = () => {
    const settings = {
      isOpen: true,
      childID: childID,
    };
    dispatch(setChildCardModal(settings));
  };

  const getClassData = useCallback((classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  }, [dataData.classes]);
    
  const getClubStateIcon = (stateID: any) => {
    const state = [
      "clock",
      "checkmark",
      "close"
    ];
    return state[stateID - 1];
  };

  return (
    <div className={classes.root} onClick={(!disableClick && !isDisabled) ? (useModal ? onModalAction : onClickAction) : undefined} data-cy={isCypress() ? `child${childID}` : null}>
      <div className={classes.avatarWrapper}>
        <AuthenticatedImage thumbLink={childData.photo.thumbLink} className={classes.avatar} dataCy="childPhoto"/>
        {
          (mode === "select" && isVisibleGDPR && !childData.hasGDPR) ? (
            <TooltipIcon icon="gdpr-badge" title={t('gdpr')} position='bottom' className={classes.gdprBadge} dataCy='childGdpr'/>
          ) : null
        }
        {
          (isVisibleClass && childData.classID.length === 1) ? (
            <span className={classes.classBadge} data-cy={isCypress() ? `childGdpr` : null}>
              {getClassData(childData.classID[0]).name}
            </span>
          ) : null
        }
        {
          (isVisibleArchived && childData.isArchived) ? (
            <TooltipIcon icon="archived" title={t('archived')} position='bottom' className={classes.archivedBadge} dataCy={isCypress() ? `childArchived` : null}/>
          ) : null
        }
        {
          (mode === "view" && getUserRole(userData.userObject.roleType) !== "parent") ? (
            <TooltipIcon icon={isPostSeen ? "eye" : "eye-slash"} title={isPostSeen ? t('seen') : t('not_seen')} position='bottom' className={classes.seenBadge} dataCy='childSeen'/>
          ) : null
        }
        {
          mode === "clubState" ? (
            <TooltipIcon icon={getClubStateIcon(childData.state)} title={childData.stateText} position='bottom' className={`${classes.clubStateBadge} state${childData.state}`} dataCy='childClubState'/>
          ) : null
        }
        {
          (mode === "attendance" && childData && (childData.morningAttendanceStatusId != null || childData.afternoonAttendanceStatusId != null)) ? (
            <div className={classes.attendanceBadge} data-cy={isCypress() ? `childAttendance` : null}>
              <AttendanceInfoItem
                morningAttendanceStatusId={childData.morningAttendanceStatusId}
                afternoonAttendanceStatusId={childData.afternoonAttendanceStatusId}
                classAttendanceStatus={defaultClassData.classAttendanceStatus}
                size={size}
              />
            </div>
          ) : null
        }
      </div>
      <Clamp withTooltip lines={clampLines}>
        <span className={classes.childDisplayName} data-cy={isCypress() ? `childDisplayName` : null}>{childData.displayName}</span>
      </Clamp>  
    </div>
  );
};

export default ChildInfoItem;