import moment from '../../../../utils/moment';
import React from 'react';
import SubstitutionInfoItem from '../../../Items/SubstitutionInfoItem';
import theme from 'src/ui/theme';
import useStyles from './style';
import { compareDates } from '../../../../utils/date';
import { getCalendarBackgroundColor, handleSum } from 'src/utils/useFunctions';
import { getUserRole, getUserSetting } from 'src/utils/useUser';
import { isCypress } from '../../../../utils/useCypress';
import { useAppSelector } from '../../../../hooks/redux-hooks';

type DateItemType = {
  index?: any;
  date?: any;
  onClickSubstitution?: any;
  onClickWatchdog?: any;
  onClickDate?: any;
  isLoading?: any;
};

const DateItem: React.FunctionComponent<DateItemType> = ({index, date, onClickSubstitution, onClickWatchdog, onClickDate, isLoading}) => {
  
  const classes = useStyles({
    getCalendarBackgroundColor: getCalendarBackgroundColor(date, index, theme),
  });

  const substitutionsData = useAppSelector((state: any) => state.substitutions);
  const userData = useAppSelector((state: any) => state.user);

  const curChild = substitutionsData.currentChild;
  const curDate = substitutionsData.date;
  const list = substitutionsData.list;

  const watchdogs = substitutionsData.watchdogs;

  const substitutionData = (list.length > 0 && curChild !== null && curChild !== undefined) ? (list.filter((data: any) => data.childID === curChild.childID).length > 0 ? (list.find((data: any) => data.childID === curChild.childID).dates.filter((data: any) => moment(data.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")).length === 1 ? (list.find((data: any) => data.childID === curChild.childID).dates.find((data: any) => moment(data.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"))) : {}) : {}) : {};

  const watchdogData = (watchdogs.length > 0 && curChild !== null && curChild !== undefined) ? (watchdogs.filter((data: any) => data.childID === curChild.childID).length > 0 ? (watchdogs.find((data: any) => data.childID === curChild.childID).dates.filter((data: any) => moment(data.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")).length === 1 ? (watchdogs.find((data: any) => data.childID === curChild.childID).dates.find((data: any) => moment(data.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"))) : {}) : {}) : {};

  const isTooltipEnabled = getUserSetting(userData.userSettings, "addons", ["substitutions", "substitutions_tooltips"]);

  const getDateCredit = () => {
    const allDays = substitutionsData.childData.allDays;
    const credits = allDays.filter((item: any) => item.substitutionRemaining && moment(item.date).isBetween(moment(date).subtract(substitutionsData.childData.schoolDays, 'days'), moment(date).add(substitutionsData.childData.schoolDays, 'days')) && moment(item.date).isBefore(moment(date),'day')).map((item: any) => {
      return item.substitutionRemaining;
    }).reduce(handleSum, 0);
    return getUserRole(userData.userObject.roleType) !== "teacher" ? credits : 0;
  };
  
  const handleClickMorning = (e: any) => {
    if(Object.keys(substitutionData).length > 0 && (substitutionData.substitutionPartID === 1 || substitutionData.substitutionPartID === 2)) {
      handleClickSubstitution(e);
    } else if(Object.keys(watchdogData).length > 0 && (watchdogData.dayPart === 1 || watchdogData.dayPart === 2)) {
      handleClickWatchdog(e);
    } else {
      if(substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(date).day())).length === 0 ? null : (substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(date).day())).attendanceType === "am" || substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(date).day())).attendanceType === "all")) {
        return;
      }
      if(date.isAfter(moment(),'day') && moment(date).isSameOrBefore(moment(substitutionsData.childData.lastDay).add(substitutionsData.childData.schoolDays,'days'),'day')) {
        handleClickDate(e);
      }
    }
  };

  const handleClickAfternoon = (e: any) => {
    if(Object.keys(substitutionData).length > 0 && substitutionData.substitutionPartID === 3) {
      handleClickSubstitution(e);
    } else if(Object.keys(watchdogData).length > 0 && watchdogData.dayPart === 3) {
      handleClickWatchdog(e);
    } else {
      if(substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(date).day())).length === 0 ? null : (substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(date).day())).attendanceType === "pm" || substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(date).day())).attendanceType === "all")) {
        return;
      }
      if(date.isAfter(moment(),'day') && moment(date).isSameOrBefore(moment(substitutionsData.childData.lastDay).add(substitutionsData.childData.schoolDays,'days'),'day')) {
        handleClickDate(e);
      }
    }
  };

  const handleClickSubstitution = (e: any) => {
    e.stopPropagation();
    onClickSubstitution(substitutionData.substitutionID, substitutionData.childID);
  };

  const handleClickWatchdog = (e: any) => {
    e.stopPropagation();
    onClickWatchdog(watchdogData.watchdogID, watchdogData.childID);
  };

  const handleClickDate = (e: any) => {
    e.stopPropagation();
    if(curChild === null || curChild === undefined || curChild.isArchived || !curChild.isInAnyActiveClass || getDateCredit() <= 0 || (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(date).day())).length !== 0 && substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(date).day())).attendanceType === "all") || substitutionsData.childData.workingDays.indexOf(parseDay(moment(date).day())) === -1 || substitutionsData.childData.holidayDates.indexOf(parseDay(moment(date).day())) !== -1 || substitutionsData.childData.allDates.indexOf(parseDay(moment(date).day())) !== -1) return;
    if(date.isSameOrAfter(moment(),'day')) onClickDate(date);
  };

  const parseDay = (day: any) => {
    if(day === 0) return 7;
    else return day;
  };

  const morningStatus = () => {
    if(substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(date).day())).length === 0 ? null : (substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(date).day())).attendanceType === "am" || substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(date).day())).attendanceType === "all")) {
      return "attendance";
    } else {
      if(substitutionData.substitutionPartID === 2 || substitutionData.substitutionPartID === 1) {
        return "substitution";
      } else if(watchdogData.dayPart === 2 || watchdogData.dayPart === 1) {
        return "watchdog";
      } else {
        if(moment(date).isAfter(moment(),'day') && moment(date).isSameOrBefore(moment(substitutionsData.childData.lastDay).add(substitutionsData.childData.schoolDays,'days'),'day') && curChild !== null && curChild !== undefined && !curChild.isArchived && curChild.isInAnyActiveClass && getDateCredit() > 0 && (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(date).day())).length === 0 || (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(date).day())).length !== 0 && substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(date).day())).attendanceType === "pm")) && substitutionsData.childData.workingDays.indexOf(parseDay(moment(date).day())) !== -1 && substitutionsData.childData.holidayDates.indexOf(parseDay(moment(date).day())) === -1 && substitutionsData.childData.allDates.indexOf(parseDay(moment(date).day())) === -1 && (Object.keys(substitutionData).length === 0 || substitutionData.substitutionPartID !== 1) && (Object.keys(watchdogData).length === 0 || watchdogData.dayPart !== 1)) {
          return "create";
        } else {
          return null;
        }
      }
    }
  };

  const afternoonStatus = () => {
    if(substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(date).day())).length === 0 ? null : (substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(date).day())).attendanceType === "pm" || substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(date).day())).attendanceType === "all")) {
      return "attendance";
    } else {
      if(substitutionData.substitutionPartID === 3 || substitutionData.substitutionPartID === 1) {
        return "substitution";
      } else if(watchdogData.dayPart === 3 || watchdogData.dayPart === 1) {
        return "watchdog";
      } else {
        if(moment(date).isAfter(moment(),'day') && moment(date).isSameOrBefore(moment(substitutionsData.childData.lastDay).add(substitutionsData.childData.schoolDays,'days'),'day') && curChild !== null && curChild !== undefined && !curChild.isArchived && curChild.isInAnyActiveClass && getDateCredit() > 0 && (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(date).day())).length === 0 || (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(date).day())).length !== 0 && substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(date).day())).attendanceType === "am")) && substitutionsData.childData.workingDays.indexOf(parseDay(moment(date).day())) !== -1 && substitutionsData.childData.holidayDates.indexOf(parseDay(moment(date).day())) === -1 && substitutionsData.childData.allDates.indexOf(parseDay(moment(date).day())) === -1 && (Object.keys(substitutionData).length === 0 || substitutionData.substitutionPartID !== 3) && (Object.keys(watchdogData).length === 0 || watchdogData.dayPart !== 3)) {
          return "create";
        } else {
          return "null";
        }
      }
    }
  };

  return (curChild !== null && curChild !== undefined) ? (
    <div className={classes.dateItem} data-cy={isCypress() ? "substitutionDayItem-" + moment(date).format("YYYY-MM-DD") : null}>
      { 
        compareDates(date, moment()) ? (
          <p className={`${classes.date} ${moment(date).month() !== moment(curDate).month() ? 'fontGray' : null} ${(moment(date).day() === 6 || moment(date).day() === 0) ? 'fontDarkGray' : null}`}>
            <span className={classes.today} data-clarity-unmask="true">{moment(date).date()}</span>
          </p>
        ) : (
          <p className={`${classes.date} ${moment(date).month() !== moment(curDate).month() ? 'fontGray' : null} ${(moment(date).day() === 6 || moment(date).day() === 0) ? 'fontDarkGray' : null} ${(getDateCredit() > 0 && (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(date).day())).length === 0 || (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(date).day())).length !== 0 && substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(date).day())).attendanceType !== "all")) && substitutionsData.childData.workingDays.indexOf(parseDay(moment(date).day())) !== -1 && substitutionsData.childData.holidayDates.indexOf(parseDay(moment(date).day())) === -1 && substitutionsData.childData.allDates.indexOf(parseDay(moment(date).day())) === -1 && Object.keys(substitutionData).length === 0 && moment(date).isAfter(moment(),'day') && moment(date).isSameOrBefore(moment(substitutionsData.childData.lastDay).add(substitutionsData.childData.schoolDays,'days'),'day')) ? (isLoading ? 'isDisabled' : 'isEnabled') : 'isDisabled'}`} data-clarity-unmask="true">
            {moment(date).date()}
          </p>
        ) 
      }
      {
        !isLoading ? (
          <SubstitutionInfoItem morningStatus={morningStatus()} afternoonStatus={afternoonStatus()} tooltip={isTooltipEnabled} onClickMorning={handleClickMorning} onClickAfternoon={handleClickAfternoon}/>
        ) : null
      }
    </div>
  ) : null;
};

export default DateItem;