import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useMemo, useRef } from 'react';
import RenderAll from './RenderAll';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  childrenSelect: {
    width: '100%',
    '&.inModal': {
      display: 'none',
    },  
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
}));

type ChildrenSelectType = {
  onClickChild?: any;
  onClickSelectAll?: any;
  setSelectedChildren?: any;
  selectedChildren?: any;
  defaultSchools?: any;
  defaultClasses?: any;
  defaultChildren?: any;
  defaultSchoolsData?: any;
  defaultClassesData?: any;
  defaultChildrenData?: any; 
  displayMode?: 'default' | "simple";
  isInModal?: boolean;
  isModalOpen?: boolean;
  isMultipleSelect?: boolean;
  isSelectAll?: boolean;
  selectAllOptions?: string[],
  isSelectInAllClass?: boolean;
  mode?: string;
  isChildGDPR?: boolean;
  isChildModal?: boolean;
  isShowChildrenViews?: boolean;
  isShowChildrenArchived?: boolean;
  isShowAttendanceCount?: boolean;
  isShowCapacityCount?: boolean;
  isDisableTotalCount?: boolean;
  isDisableClick?: boolean;
  validateMultipleSchool?: boolean;
  validateAbsence?: boolean;
  isAllowInactiveClasses?: boolean;
  isAllowArchived?: boolean;
  isAllowArchivedToggle?: boolean;
  isAllowSearch?: boolean;
  isAllowOnlyOneChildAtOnce?: boolean;
  modalTitle?: string;
  modalOnClose?: any;
  modalOnSave?: any;
  modalCloseOnSave?: boolean;
  modalChildrenCount?: string;
  modalAllowChildrenCount?: boolean;
  modalAllowCancel?: boolean;
  modalAllowClose?: boolean;
  modalAllowClear?: boolean;
  modalAllowNoChild?: boolean;
  customSort?: any;
  customClasses?: any;
  customNoResults?: any;
  customCombineMessage?: any;
  isReload?: any;
  isCloseAllOnReload?: any;
};

const ChildrenSelect: React.FunctionComponent<ChildrenSelectType> = ({
  onClickChild,
  onClickSelectAll,
  setSelectedChildren,
  selectedChildren,
  defaultSchools,
  defaultClasses,
  defaultChildren,
  defaultSchoolsData,
  defaultClassesData,
  defaultChildrenData, 
  displayMode = "default",
  isInModal = false,
  isModalOpen = false,
  isMultipleSelect = false,
  isSelectAll = false,
  selectAllOptions = ["school", "class"],
  isSelectInAllClass = false,
  mode = "detail",
  isChildGDPR = false,
  isChildModal = false,
  isShowChildrenViews = false,
  isShowChildrenArchived = false,
  isShowAttendanceCount = false,
  isShowCapacityCount = false,
  isDisableTotalCount = false,
  isDisableClick = false,
  validateAbsence = false,
  validateMultipleSchool = false,
  isAllowInactiveClasses = true,
  isAllowArchived = false,
  isAllowArchivedToggle = true,
  isAllowSearch = true,
  isAllowOnlyOneChildAtOnce = false,
  modalTitle = "",
  modalOnClose,
  modalOnSave,
  modalCloseOnSave = true,
  modalChildrenCount = "default",
  modalAllowChildrenCount = true,
  modalAllowCancel = false,
  modalAllowClose = false,
  modalAllowClear = false,
  modalAllowNoChild = false,
  customSort,
  customClasses,
  customNoResults,
  customCombineMessage,
  isReload = false,
  isCloseAllOnReload = false,
}) => {

  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);

  const schoolsData = useMemo(() => dataData.schools.map((item: any) => {
    let returnItem = item;
    if(defaultSchoolsData) {
      const isMoreData = defaultSchoolsData.filter((more: any) => more.schoolID === returnItem.schoolID).length === 1 ? true : false;
      if(isMoreData) {
        const moreData = defaultSchoolsData.find((more: any) => more.schoolID === returnItem.schoolID);
        returnItem = Object.assign({}, returnItem, moreData);
      }
    }
    if(customSort) {
      const isSortData = customSort.filter((sort: any) => sort.schoolID === returnItem.schoolID).length === 1 ? true : false;
      if(isSortData) {
        const sortData = customSort.find((sort: any) => sort.schoolID === returnItem.schoolID);
        returnItem = Object.assign({}, returnItem, sortData);
      }
    }
    return returnItem;
  }), [dataData.schools, defaultSchoolsData, customSort]);

  const classesData = useMemo(() => dataData.classes.map((item: any) => {
    let returnItem = item;
    if(defaultClassesData) {
      const isMoreData = defaultClassesData.filter((more: any) => more.classID === returnItem.classID).length === 1 ? true : false;
      if(isMoreData) {
        const moreData = defaultClassesData.find((more: any) => more.classID === returnItem.classID);
        returnItem = Object.assign({}, returnItem, moreData);
      }
    }
    if(customSort) {
      const isSortData = customSort.filter((sort: any) => sort.classID === returnItem.classID).length === 1 ? true : false;
      if(isSortData) {
        const sortData = customSort.find((sort: any) => sort.classID === returnItem.classID);
        returnItem = Object.assign({}, returnItem, sortData);
      }
    }
    return returnItem;
  }), [dataData.classes, defaultClassesData, customSort]);
  
  const childrenData = useMemo(() => dataData.children.map((item: any) => {
    let returnItem = item;
    if(defaultChildrenData) {
      const moreDataCount = defaultChildrenData.filter((more: any) => more.childID === returnItem.childID).length;
      if(moreDataCount === 1) {
        const moreData = defaultChildrenData.find((more: any) => more.childID === returnItem.childID);
        returnItem = Object.assign({}, returnItem, moreData);
      } else {
        if(moreDataCount !== 0) {
          const classData = defaultChildrenData.filter((more: any) => more.childID === returnItem.childID).map((customData: any) => {
            return {...customData, classID: customData.classID[0]};
          });
          returnItem = {...returnItem, classData: classData};
        }
      }
    }
    if(customSort) {
      const isSortData = customSort.filter((sort: any) => sort.childID === returnItem.childID).length === 1 ? true : false;
      if(isSortData) {
        const sortData = customSort.find((sort: any) => sort.childID === returnItem.childID);
        returnItem = Object.assign({}, returnItem, sortData);
      }
    }
    return returnItem;
  }), [dataData.children, defaultChildrenData, customSort]);

  const defaultSchoolList = useMemo(() => (defaultSchools ? defaultSchools : dataData.schools).map((item: any) => { return { schoolID: item.schoolID }; }), [dataData.schools, defaultSchools]);
  const defaultClassList = useMemo(() => (defaultClasses ? defaultClasses : dataData.classes).map((item: any) => { return { classID: item.classID, schoolID: item.schoolID }; }), [dataData.classes, defaultClasses]);
  const defaultChildList = useMemo(() => (defaultChildren ? defaultChildren : dataData.children).map((item: any) => { return { childID: item.childID, classID: item.classID, schoolID: item.schoolID }; }), [dataData.children, defaultChildren]);

  const getChildData = useCallback((childID: any) => {
    return childrenData.filter((item: any) => item.childID === childID).length === 0 ? [] : childrenData.find((item: any) => item.childID === childID);
  }, [childrenData]);

  const getClassData = useCallback((classID: any) => {
    return classesData.filter((item: any) => item.classID === classID).length === 0 ? [] : classesData.find((item: any) => item.classID === classID);
  }, [classesData]);

  const getSchoolData = useCallback((schoolID: any) => {
    return schoolsData.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : schoolsData.find((item: any) => item.schoolID === schoolID);
  }, [schoolsData]);

  const getChildDataByClass = useCallback((childID: any, classID: any) => {
    let returnData = [];
    if(childrenData.filter((item: any) => item.childID === childID).length !== 0) {
      if(childrenData.find((item: any) => item.childID === childID).classData) {
        if(childrenData.find((item: any) => item.childID === childID).classData.filter((item: any) => item.classID === classID).length === 1) {
          returnData = childrenData.find((item: any) => item.childID === childID).classData.find((item: any) => item.classID === classID);
        }
      } else {
        returnData = childrenData.filter((item: any) => item.childID === childID && item.classID.indexOf(classID) !== -1).length === 0 ? [] : childrenData.find((item: any) => item.childID === childID && item.classID.indexOf(classID) !== -1);
      }
    }
    return returnData;
  }, [childrenData]);

  const getChildrenByClassID = useMemo(() => (classID: any) => {
    let list = defaultChildList.filter((item: any) => (getChildData(item.childID).classData && getChildData(item.childID).classData.length !== 0) ? getChildDataByClass(item.childID, item.classID[0]).classID === classID : getChildData(item.childID).classID.indexOf(classID) !== -1);
    if(stateRef.current.search !== "") {
      list = list.filter((item: any) => getChildData(item.childID).displayName.toLowerCase().includes(stateRef.current.search) || getChildData(item.childID).firstname.toLowerCase().includes(stateRef.current.search) || getChildData(item.childID).surname.toLowerCase().includes(stateRef.current.search));
    }
    if(!stateRef.current.isShowArchived) {
      list = list.filter((item: any) => !getChildData(item.childID).isArchived && getChildData(item.childID).isInAnyActiveClass).map((item: any) => {
        let isVisible = false;
        const allClasses = item.classID;
        if(allClasses && allClasses.length > 0) {
          allClasses.forEach((theClass: any) => {
            if(!getClassData(theClass).isArchived && getClassData(theClass).active) {
              isVisible = true;
            }
          });
        }
        return isVisible ? item : null;
      }).filter((item: any) => item !== null);
    }
    list.sort((a: any, b: any) => {
      if (getChildData(a.childID).customSortOrder !== undefined && getChildData(b.childID).customSortOrder !== undefined) {
        return getChildData(a.childID).customSortOrder - getChildData(b.childID).customSortOrder;
      } else if (getChildData(a.childID).customSortOrder !== undefined) {
        return -1;
      } else if (getChildData(b.childID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultChildList, getChildData, getChildDataByClass, getClassData]);

  const getChildrenBySchoolID = useCallback((schoolID: any) => {
    let list = defaultChildList.filter((item: any) => getChildData(item.childID).schoolID === schoolID);
    if(stateRef.current.search !== "") {
      list = list.filter((item: any) => getChildData(item.childID).displayName.toLowerCase().includes(stateRef.current.search) || getChildData(item.childID).firstname.toLowerCase().includes(stateRef.current.search) || getChildData(item.childID).surname.toLowerCase().includes(stateRef.current.search));
    }
    if(!stateRef.current.isShowArchived) {
      list = list.filter((item: any) => !getChildData(item.childID).isArchived && getChildData(item.childID).isInAnyActiveClass).map((item: any) => {
        let isVisible = false;
        const allClasses = item.classID;
        if(allClasses && allClasses.length > 0) {
          allClasses.forEach((theClass: any) => {
            if(!getClassData(theClass).isArchived && getClassData(theClass).active) {
              isVisible = true;
            }
          });
        }
        return isVisible ? item : null;
      }).filter((item: any) => item !== null);
    }
    list.sort((a: any, b: any) => {
      if (getChildData(a.childID).customSortOrder !== undefined && getChildData(b.childID).customSortOrder !== undefined) {
        return getChildData(a.childID).customSortOrder - getChildData(b.childID).customSortOrder;
      } else if (getChildData(a.childID).customSortOrder !== undefined) {
        return -1;
      } else if (getChildData(b.childID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultChildList, getChildData, getClassData]);

  const getClasses = useCallback(() => {
    let list = defaultClassList;
    if(stateRef.current.search !== "") {
      list = list.filter((item: any) => getClassData(item.classID).name.toLowerCase().includes(stateRef.current.search));
    }
    if(!stateRef.current.isShowArchived) {
      list = list.filter((item: any) => !getClassData(item.classID).isArchived && getClassData(item.classID).active);
    }
    list.sort((a: any, b: any) => {
      if (getClassData(a.classID).customSortOrder !== undefined && getClassData(b.classID).customSortOrder !== undefined) {
        return getClassData(a.classID).customSortOrder - getClassData(b.classID).customSortOrder;
      } else if (getClassData(a.classID).customSortOrder !== undefined) {
        return -1;
      } else if (getClassData(b.classID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultClassList, getClassData]);

  const getClassesBySchoolID = useCallback((schoolID: any) => {
    let list = defaultClassList.filter((item: any) => getClassData(item.classID).schoolID === schoolID);
    list = list.filter((item: any) => getChildrenByClassID(item.classID).length !== 0);
    if(stateRef.current.search !== "") {
      list = list.filter((item: any) => getChildrenByClassID(item.classID).length !== 0);
    }
    if(!stateRef.current.isShowArchived) {
      list = list.filter((item: any) => !getClassData(item.classID).isArchived && getClassData(item.classID).active);
    }
    list.sort((a: any, b: any) => {
      if(getClassData(a.classID).customSortOrder !== undefined && getClassData(b.classID).customSortOrder !== undefined) {
        return getClassData(a.classID).customSortOrder - getClassData(b.classID).customSortOrder;
      } else if(getClassData(a.classID).customSortOrder !== undefined) {
        return -1;
      } else if(getClassData(b.classID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultClassList, getChildrenByClassID, getClassData]);

  const getChildren = useCallback(() => {
    let list = defaultChildList;
    if(stateRef.current.search !== "") {
      list = list.filter((item: any) => getChildData(item.childID).displayName.toLowerCase().includes(stateRef.current.search) || getChildData(item.childID).firstname.toLowerCase().includes(stateRef.current.search) || getChildData(item.childID).surname.toLowerCase().includes(stateRef.current.search));
    }
    if(!stateRef.current.isShowArchived) {
      list = list.filter((item: any) => !getChildData(item.childID).isArchived && getChildData(item.childID).isInAnyActiveClass);
    }
    list.sort((a: any, b: any) => {
      if(getChildData(a.childID).customSortOrder !== undefined && getChildData(b.childID).customSortOrder !== undefined) {
        return getChildData(a.childID).customSortOrder - getChildData(b.childID).customSortOrder;
      } else if(getChildData(a.childID).customSortOrder !== undefined) {
        return -1;
      } else if(getChildData(b.childID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultChildList, getChildData]);

  const getSchools = useCallback(() => {
    let list = defaultSchoolList;
    list = list.filter((item: any) => getClassesBySchoolID(item.schoolID).length !== 0);
    list.sort((a: any, b: any) => {
      if (getSchoolData(a.schoolID).customSortOrder !== undefined && getSchoolData(b.schoolID).customSortOrder !== undefined) {
        return getSchoolData(a.schoolID).customSortOrder - getSchoolData(b.schoolID).customSortOrder;
      } else if (getSchoolData(a.schoolID).customSortOrder !== undefined) {
        return -1;
      } else if (getSchoolData(b.schoolID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultSchoolList, getClassesBySchoolID, getSchoolData]);

  const activeClasses = useMemo(() => defaultClassList.filter((item: any) => !getClassData(item.classID).isArchived && getClassData(item.classID).active), [defaultClassList, getClassData]);
  const archivedClasses = useMemo(() => defaultClassList.filter((item: any) => getClassData(item.classID).isArchived || !getClassData(item.classID).active), [defaultClassList, getClassData]);
  const activeChildren = useMemo(() => defaultChildList.filter((item: any) => !getChildData(item.childID).isArchived && getChildData(item.childID).isInAnyActiveClass && !getChildData(item.childID).isInArchivedClass), [defaultChildList, getChildData]);
  const archivedChildren = useMemo(() => defaultChildList.filter((item: any) => getChildData(item.childID).isArchived || getChildData(item.childID).isInArchivedClass || !getChildData(item.childID).isInAnyActiveClass), [defaultChildList, getChildData]);

  const defaultState: any = useMemo(() => {
    return {
      data: {
        schools: defaultSchoolList,
        classes: defaultClassList,
        children: defaultChildList,
      },
      isShowArchived: isAllowArchived ? isAllowArchived : false,
      isShowArchivedToggle: isAllowArchivedToggle ? isAllowArchivedToggle : false,
      isShowInactiveClasses: isAllowInactiveClasses ? isAllowInactiveClasses : false,
      isReload: isReload,
      isCloseAllOnReload: isCloseAllOnReload,
      isLoadedAll: false,
      search: '',
      onClickChild: onClickChild,
      onClickSelectAll: onClickSelectAll,
      selectedChildren: selectedChildren ? selectedChildren : [],
      defaultSelectedChildren: selectedChildren ? selectedChildren : [],
      setSelectedChildren: setSelectedChildren,
      defaultSchools: defaultSchools,
      defaultClasses: defaultClasses,
      defaultChildren: defaultChildren,
      defaultSchoolsData: defaultSchoolsData,
      defaultClassesData: defaultClassesData,
      defaultChildrenData: defaultChildrenData,
      displayMode: displayMode,
      isInModal: isInModal,
      isModalOpen: isModalOpen,
      isMultipleSelect: isMultipleSelect,
      isSelectAll: isSelectAll,
      selectAllOptions: selectAllOptions,
      isSelectInAllClass: isSelectInAllClass,
      mode: mode,
      isChildGDPR: isChildGDPR,
      isChildModal: isChildModal,
      isShowChildrenViews: isShowChildrenViews,
      isShowChildrenArchived: isShowChildrenArchived,
      isShowAttendanceCount: isShowAttendanceCount,
      isShowCapacityCount: isShowCapacityCount,
      isDisableTotalCount: isDisableTotalCount,
      isDisableClick: isDisableClick,
      validateAbsence: validateAbsence,
      validateMultipleSchool: validateMultipleSchool,
      isAllowInactiveClasses: isAllowInactiveClasses,
      isAllowArchived: isAllowArchived,
      isAllowArchivedToggle: isAllowArchivedToggle,
      isAllowSearch: isAllowSearch,
      isAllowOnlyOneChildAtOnce: isAllowOnlyOneChildAtOnce,
      modalTitle: modalTitle,
      modalOnClose: modalOnClose,
      modalOnSave: modalOnSave,
      modalCloseOnSave: modalCloseOnSave,
      modalChildrenCount: modalChildrenCount,
      modalAllowChildrenCount: modalAllowChildrenCount,
      modalAllowCancel: modalAllowCancel,
      modalAllowClose: modalAllowClose,
      modalAllowClear: modalAllowClear,
      modalAllowNoChild: modalAllowNoChild,
      customClasses: customClasses,
      customNoResults: customNoResults,
      customCombineMessage: customCombineMessage,
      functions: {
        getChildData: getChildData,
        getClassData: getClassData,
        getSchoolData: getSchoolData,
        getChildDataByClass: getChildDataByClass,
        getChildrenByClassID: getChildrenByClassID,
        getChildrenBySchoolID: getChildrenBySchoolID,
        getClassesBySchoolID: getClassesBySchoolID,
        getChildren: getChildren,
        getClasses: getClasses,
        getSchools: getSchools,
      },
    }
  }, [isReload, isCloseAllOnReload, customClasses, customNoResults, customCombineMessage, defaultChildList, defaultChildren, defaultChildrenData, defaultClassList, defaultClasses, defaultClassesData, defaultSchoolList, defaultSchools, defaultSchoolsData, getChildData, getChildDataByClass, getChildren, getChildrenByClassID, getChildrenBySchoolID, getClasses, getClassData, getClassesBySchoolID, getSchoolData, getSchools, isAllowArchived, isAllowArchivedToggle, isAllowInactiveClasses, isAllowOnlyOneChildAtOnce, isAllowSearch, isChildGDPR, isChildModal, isDisableClick, isDisableTotalCount, validateAbsence, validateMultipleSchool, displayMode, isInModal, isModalOpen, isMultipleSelect, isSelectAll, selectAllOptions, isSelectInAllClass, isShowAttendanceCount, isShowCapacityCount, isShowChildrenViews, isShowChildrenArchived, modalAllowCancel, modalAllowChildrenCount, modalAllowClear, modalAllowClose, modalAllowNoChild, modalChildrenCount, modalCloseOnSave, modalOnClose, modalOnSave, modalTitle, mode, onClickChild, selectedChildren, setSelectedChildren]);

  const [state, setState, setStates] = useStates(defaultState);

  const handleState = useCallback((name: any, value: any) => {
    if(Array.isArray(name) && Array.isArray(value)) {
      let stateHolder = state;
      let newObjects = {};
      name.forEach((item: any, index: any) => {
        stateHolder = {...stateHolder, [item]: value[index]};
        const newObject = {
          [item]: value[index],
        };
        newObjects = Object.assign(newObject, newObjects, newObject);
      });
      stateRef.current = stateHolder;
      setStates(newObjects);
    } else {
      stateRef.current = {...state, [name]: value};
      setState(name, value);
    }
  }, [state, setState, setStates]); 

  const stateRef = useRef(state);

  useEffect(() => {
    let stateNames: any = [];
    let stateValues: any = [];
    if(getUserRole(userData.userObject.roleType) === "parent" || state.displayMode === "simple") {
      if(archivedChildren.length === 0) {
        if(state.isShowArchived) {
          stateNames = [...stateNames, "isShowArchived"];
          stateValues = [...stateValues, false]; 
        }
        if(state.isShowArchivedToggle) {
          stateNames = [...stateNames, "isShowArchivedToggle"];
          stateValues = [...stateValues, false];  
        }
      }
      if(activeChildren.length === 0 && archivedChildren.length !== 0) {
        if(!state.isShowArchived) {
          stateNames = [...stateNames, "isShowArchived"];
          stateValues = [...stateValues, true]; 
        }
        if(state.isShowArchivedToggle) {
          stateNames = [...stateNames, "isShowArchivedToggle"];
          stateValues = [...stateValues, false];
        }
      }
    } else {
      if(activeClasses.length === 0 && activeChildren.length === 0) {
        if(archivedClasses.length > 0 || archivedChildren.length > 0) {
          if(!state.isShowArchived) {
            stateNames = [...stateNames, "isShowArchived"];
            stateValues = [...stateValues, true]; 
          }
          if(state.isShowArchivedToggle) {
            stateNames = [...stateNames, "isShowArchivedToggle"];
            stateValues = [...stateValues, false];
          }
        }
      }
      if(archivedClasses.length === 0 && archivedChildren.length === 0) {
        if(activeClasses.length > 0 || activeChildren.length > 0) {
          if(state.isShowArchived) {
            stateNames = [...stateNames, "isShowArchived"];
            stateValues = [...stateValues, false]; 
          }
          if(state.isShowArchivedToggle) {
            stateNames = [...stateNames, "isShowArchivedToggle"];
            stateValues = [...stateValues, false];
          }
        }
      }
    }
    if(selectedChildren) {
      if(selectedChildren.length > 0) {
        if(selectedChildren.filter((item: any) => getChildData(item.childID).isArchived).length > 0) {
          if(!state.isShowArchived) {
            stateNames = [...stateNames, "isShowArchived"];
            stateValues = [...stateValues, true];
          }
        }
      }
    }
    stateNames = [...stateNames, "isLoadedAll"];
    stateValues = [...stateValues, true]; 
    handleState(stateNames, stateValues);
  }, [activeChildren, activeClasses, archivedChildren, archivedClasses, handleState, selectedChildren, state.isShowArchived, state.isShowArchivedToggle, state.displayMode, userData.userObject.roleType, getChildData], []);

  useEffect(() => {
    return () => {
      if(setSelectedChildren) setSelectedChildren(stateRef.current.selectedChildren);
    }
  }, [setSelectedChildren], []);

  useEffect(() => {
    if(setSelectedChildren) setSelectedChildren(stateRef.current.selectedChildren);
  }, [setSelectedChildren], [stateRef.current.selectedChildren]);

  useEffect(() => {
    if(state.isDisableClick !== isDisableClick) {
      handleState("isDisableClick", isDisableClick);
    }
    if(isReload) {
      const newDefaultState = {...defaultState, isLoadedAll: true};
      setStates(newDefaultState);
      stateRef.current = newDefaultState;
    }
  }, [state, handleState, defaultState, setStates, isDisableClick, isReload], [isDisableClick, isReload]);

  return (
    <div className={`${classes.childrenSelect} ${isInModal ? 'inModal' : null} ${customClasses ? (customClasses.childrenSelect ? customClasses.childrenSelect : "") : ""}`}>
      {
        (!state.isLoadedAll && !state.isInModal) ? (
          <div className={classes.loading}>
            <CircularProgress className={classes.spinner}/>
          </div>
        ) : (
          <RenderAll state={state} setState={handleState}/>
        )
      }
    </div>
  );
}

export default ChildrenSelect;