import HttpService from '../http.service';

const listPosts = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get('v2', `post${query ? `${query}`: ""}`, {}, {});
};

const listMedias = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for(const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  } 
  return HttpService.get('v2', `media${query ? `${query}`: ""}`, {}, {});
};

const createPost = (payload: any) => {
  return HttpService.post('v2', 'post', payload, {}, {});
};

const updatePost = (postID: any, payload: any) => {
  return HttpService.put('v2', `post/${postID}`, payload, {}, {});
};

const deletePost = (postID: any) => {
  return HttpService.remove('v2', `post/${postID}`, {});
};

const downloadFile = (fileID: any) => {
  return HttpService.get('v2', `file/${fileID}`, {}, {responseType: 'arraybuffer'});
};

const uploadFile = (uploadURL: any, payload: any) => {
  return HttpService.post('v2', uploadURL, payload, {'Content-Type': 'application/json'}, {});
};

const setAttribute = (postID: any, payload: any) => {
  return HttpService.put('v2', `post/attribute/${postID}`, payload, {}, {});
};

export {
  listPosts,
  listMedias,
  createPost,
  updatePost,
  deletePost,
  downloadFile,
  uploadFile,
  setAttribute
};