import * as dataGridLocales from '@mui/x-data-grid/locales';
import MuiPagination from '@mui/material/Pagination';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import useStyles from './style';
import { DataGrid, GridCell, GridColDef, gridPageCountSelector, GridPagination, GridRow, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { getMuiLanguageCode } from 'src/utils/useFunctions';
import { PaginationItem, TablePaginationProps } from '@mui/material';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

type DataTableType = {
  className?: any;
  columns: GridColDef[];
  data: any;
  page?: number;
  defaultSorting?: any[];
  visibleRows?: number[];
  useAutoHeight?: boolean;
  autoHeight?: boolean;
  autoPageSize?: boolean;
  columnHeaderHeight?: number,
  rowHeight?: number,
  density?: 'comfortable' | 'compact' | 'standard',
  isAutosizable?: boolean;
  isCheckable?: boolean;
  isColumnFilterable?: boolean;
  isColumnMenuEnabled?: boolean;
  isColumnSelectable?: boolean;
  isColumnSortable?: boolean;
  isColumnResizable?: boolean;
  isDensitySelectable?: boolean;
  isExportEnabled?: boolean;
  isFooterEnabled?: boolean;
  isFooterPaginationEnabled?: boolean;
  isFooterSelectedRowCountEnabled?: boolean;
  isToolbarEnabled?: boolean;
  isLoading?: boolean;
  isShowCellVerticalBorder?: boolean;
  isShowColumnVerticalBorder?: boolean;
  isRowSelectable?: boolean;
  isClickRowSelectable?: boolean;
  isMultipleRowSelectable?: boolean;
  onCellClick?: any;
  onCellDoubleClick?: any;
  onColumnHeaderClick?: any;
  onColumnHeaderDoubleClick?: any;
  onColumnHeaderEnter?: any;
  onColumnHeaderLeave?: any;
  onColumnHeaderOut?: any;
  onColumnHeaderOver?: any;
  onColumnOrderChange?: any;
  onColumnResize?: any;
  onColumnWidthChange?: any;
  onDensityChange?: any;
  onMenuClose?: any;
  onMenuOpen?: any;
  onPaginationModelChange?: any;
  onPreferencePanelClose?: any;
  onPreferencePanelOpen?: any;
  onResize?: any;
  onRowClick?: any;
  onRowContextMenu?: any;
  onRowCountChange?: any;
  onRowDoubleClick?: any;
  noResultsText?: any;
};

type SupportedDataGridLocales = keyof typeof dataGridLocales;

const DataTable: React.FunctionComponent<DataTableType> = ({ className, columns, data, useAutoHeight = false, autoHeight = false, autoPageSize = false, page = 0, defaultSorting = [], visibleRows = [5], columnHeaderHeight = 42, rowHeight = 52, density = 'comfortable', isAutosizable, isCheckable, isColumnFilterable, isColumnMenuEnabled, isColumnSelectable, isColumnSortable, isColumnResizable, isDensitySelectable, isExportEnabled, isFooterEnabled, isFooterPaginationEnabled, isFooterSelectedRowCountEnabled, isToolbarEnabled, isLoading = false, isShowCellVerticalBorder, isShowColumnVerticalBorder, isRowSelectable, isClickRowSelectable, isMultipleRowSelectable, onCellClick, onCellDoubleClick, onColumnHeaderClick, onColumnHeaderDoubleClick, onColumnHeaderEnter, onColumnHeaderLeave, onColumnHeaderOut, onColumnHeaderOver, onColumnOrderChange, onColumnResize, onColumnWidthChange, onDensityChange, onMenuClose, onMenuOpen, onPaginationModelChange, onPreferencePanelClose, onPreferencePanelOpen, onResize, onRowClick, onRowContextMenu, onRowCountChange, onRowDoubleClick, noResultsText }) => {
  
  const classes = useStyles({
    columnHeaderHeight: columnHeaderHeight,
    isFooterEnabled: isFooterEnabled,
  });

  const paginationModel = { page: page, pageSize: visibleRows[0] };

  const [state, setState] = useStates({
    autoHeight: autoHeight,
    density: density,
    lastPageSize: paginationModel.pageSize,
    resizeOnLoading: false,
  });

  const handleResizeRef = useRef(false);
  const { t } = useTranslation();
  const languageData = useAppSelector((state: any) => state.language);
  const language = languageData.language;
  const getMuiLanguage: any = getMuiLanguageCode(language, dataGridLocales);
  const muiDataGridLanguage: SupportedDataGridLocales = getMuiLanguage;

  const gridColumns: GridColDef[] = columns.map((item: any) => {
    if(item) {
      return {...item, flex: item.flex ? item.flex : 1};
    } else {
      return null;
    }
  }).filter((item: any) => item !== null);

  const MemoizedCellComponent = React.memo((props: any) => {
    const column = props.column ? props.column : {};
    const rowID = props.rowId ? props.rowId : null;
    const field = column.field ? column.field : null;
    const row = data.filter((item: any) => item.id === rowID).length === 1 ? data.find((item: any) => item.id === rowID) : [];
    const cell = row[field] ? row[field] : null;
    const cellParams = cell ? cell.params : {};
    let isClickable = ((column && column.onClick) || (cellParams && cellParams.onClick));
    const handleOnClick = (e: any) => {
      if(column.onClick) {
        column.onClick(e, props);
      } else if(cellParams.onClick) {
        cellParams.onClick(e, props, cellParams);
      }
    };
    return <GridCell className={`${column.className ? column.className : ''} ${isClickable ? 'clickable' : ''}`} onClick={handleOnClick} {...props} />;
  });

  const MemoizedRowComponent = React.memo((props) => {
    return <GridRow {...props} />;
  });

  const handleOnDensityChange = (e: any) => {
    setState("density", e);
    handleResizeRef.current = true;
    if(onDensityChange) {
      onDensityChange(e);
    }
  };

  const handlePaginationChange = (e: any) => {
    if(e.pageSize !== state.lastPageSize) {
      handleResizeRef.current = true;
      setState("lastPageSize", e.pageSize);
    }
    if(onPaginationModelChange) {
      onPaginationModelChange(e);
    }
  };

  const handleOnResize = (e: any) => {
    if(handleResizeRef.current === true) {
      handleAutoResize();
    }
    if(onResize) {
      onResize(e);
    }
  };

  const handleAutoResize = () => {
    handleResizeRef.current = false;
    setState("autoHeight", true);
    setTimeout(() => {
      setState("autoHeight", autoHeight);
    }, 100);
  };

  const DataTableToolbar = () => {

    const apiRef = useGridApiContext();
    const handleExport = () => apiRef.current.exportDataAsCsv();

    return isToolbarEnabled ? (
      <GridToolbarContainer>
        {
          isColumnMenuEnabled ? (
            <GridToolbarColumnsButton slotProps={{button: { component: NormalButton, startIcon: '', className: classes.smallButton }}}/>
          ) : null
        }
        {
          isColumnFilterable ? (
            <GridToolbarFilterButton slotProps={{button: { component: NormalButton, startIcon: '', className: classes.smallButton }}}/>
          ) : null
        }
        {
          isDensitySelectable ? (
            <GridToolbarDensitySelector slotProps={{button: { component: NormalButton, startIcon: '', className: classes.smallButton }}}/>
          ) : null
        }
        <div style={{ flexGrow: 1 }} />
        {
          isExportEnabled ? (
            <NormalButton className={classes.smallButton} onClick={handleExport}>
              {t('export_csv')}
            </NormalButton>
          ) : null
        }
      </GridToolbarContainer>
    ) : null;
  };

  const DataTablePaginationRender = ({ page, onPageChange, className}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {

    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const currentPage = page + 1;

    const isHidePrevButton = currentPage === 1;
    const isHideNextButton = currentPage === pageCount;

    const isShowFirstButton = currentPage !== 1;
    const isShowLastButton = currentPage !== 1 && currentPage !== pageCount;
    
    const handleOnPageChange = (e: any, newPage: number) => {
      onPageChange(e, newPage - 1);
    };

    const previousButton = () => (<SVG src="pagination-prev"/>);
    const nextButton = () => (<SVG src="pagination-next"/>);
    const firstButton = () => (<SVG src="pagination-first"/>);
    const lastButton = () => (<SVG src="pagination-last"/>);

    return (
      <MuiPagination
        color="standard"
        shape="rounded"
        variant="outlined"
        className={`${classes.paginationMenu} ${className ? className : ''}`}
        count={pageCount}
        hidePrevButton={isHidePrevButton}
        hideNextButton={isHideNextButton}
        showFirstButton={isShowFirstButton}
        showLastButton={isShowLastButton}
        page={currentPage}
        siblingCount={1}
        boundaryCount={1}
        onChange={handleOnPageChange}
        renderItem={(item) => (
          <PaginationItem
            className={classes.paginationItem}
            slots={{
              previous: previousButton,
              next: nextButton,
              first: firstButton,
              last: lastButton,
            }}
            {...item} />
        )}
      />
    );
  };

  const DataTablePagination = (props: any) => {
    return <GridPagination ActionsComponent={DataTablePaginationRender} {...props} />;
  };

  useEffect(() => {
    if(isLoading) {
      setState("resizeOnLoading", true);
    } else {
      if(state.resizeOnLoading) {
        handleAutoResize();
        setState("resizeOnLoading", false);
      }
    }
  }, [isLoading]);

  return (
    <div className={classes.dataTableWrapper}>
      <DataGrid
        className={`${classes.dataTable} ${className ? className : ''}`}
        rows={data}
        autoHeight={useAutoHeight ? true : state.autoHeight}
        autoPageSize={autoPageSize}
        columnHeaderHeight={columnHeaderHeight}
        rowHeight={rowHeight}
        density={state.density}
        columns={gridColumns}
        initialState={{ pagination: { paginationModel }, sorting: { sortModel: defaultSorting } }}
        pageSizeOptions={visibleRows}
        checkboxSelection={isCheckable}
        disableAutosize={!isAutosizable}
        disableColumnFilter={!isColumnFilterable}
        disableColumnMenu={!isColumnMenuEnabled}
        disableColumnSelector={!isColumnSelectable}
        disableColumnSorting={!isColumnSortable}
        disableColumnResize={!isColumnResizable}
        disableDensitySelector={!isDensitySelectable}
        disableRowSelectionOnClick={!isClickRowSelectable}
        disableMultipleRowSelection={!isMultipleRowSelectable}
        hideFooter={!isFooterEnabled}
        hideFooterPagination={!isFooterPaginationEnabled}
        hideFooterSelectedRowCount={!isFooterSelectedRowCountEnabled}
        showCellVerticalBorder={isShowCellVerticalBorder}
        showColumnVerticalBorder={isShowColumnVerticalBorder}
        rowSelection={isRowSelectable}
        onCellClick={onCellClick}
        onCellDoubleClick={onCellDoubleClick}
        onColumnHeaderClick={onColumnHeaderClick}
        onColumnHeaderDoubleClick={onColumnHeaderDoubleClick}
        onColumnHeaderEnter={onColumnHeaderEnter}
        onColumnHeaderLeave={onColumnHeaderLeave}
        onColumnHeaderOut={onColumnHeaderOut}
        onColumnHeaderOver={onColumnHeaderOver}
        onColumnOrderChange={onColumnOrderChange}
        onColumnResize={onColumnResize}
        onColumnWidthChange={onColumnWidthChange}
        onDensityChange={handleOnDensityChange}
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
        onPaginationModelChange={handlePaginationChange}
        onPreferencePanelClose={onPreferencePanelClose}
        onPreferencePanelOpen={onPreferencePanelOpen}
        onResize={handleOnResize}
        onRowClick={onRowClick}
        onRowCountChange={onRowCountChange}
        onRowDoubleClick={onRowDoubleClick}
        loading={isLoading}
        localeText={{...dataGridLocales[muiDataGridLanguage].components.MuiDataGrid.defaultProps.localeText, noRowsLabel: noResultsText }}
        slots={{
          cell: MemoizedCellComponent,
          row: MemoizedRowComponent,
          toolbar: DataTableToolbar,
          pagination: DataTablePagination,
        }}
        slotProps={{
          row: {
            onContextMenu: (e) => onRowContextMenu ? onRowContextMenu(e) : null,
          },
          basePopper: {
            className: classes.popper,
          },
          footer: {
            className: classes.footer,
          },
          columnMenu: {
            className: classes.columnMenu,
          },
          pagination: {
            className: classes.pagination,
          },
        }}
      />
    </div>
  );
}

export default DataTable;