import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { substitutionsSlice as slice } from '../slices/substitutions.slice';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setSubstitutionsCanAdd = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSubstitutionsCanAdd(data));
  }
};

export const setSubstitutionsCurrentChild = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSubstitutionsCurrentChild(data));
  }
};

export const setSubstitutionsDate = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSubstitutionsDate(data));
  }
};

export const setSubstitutionsFirstAvailableDate = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSubstitutionsFirstAvailableDate(data));
  }
};

export const setSubstitutionsList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSubstitutionsList(data));
  }
};

export const setSubstitutionsNewList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSubstitutionsNewList(data));
  }
};

export const addToSubstitutionsMonths = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addToSubstitutionsMonths(data));
  }
};

export const addToSubstitutionsList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addToSubstitutionsList(data));
  }
};

export const addSubstitutionsList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addSubstitutionsList(data));
  }
};

export const updateSubstitutionsList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.updateSubstitutionsList(data));
  }
};

export const deleteSubstitution = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.deleteSubstitution(data));
  }
};

export const setChildData = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setChildData(data));
  }
};

export const addToWatchdogsMonths = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addToWatchdogsMonths(data));
  }
};

export const addToWatchdogs = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addToWatchdogs(data));
  }
};

export const addWatchdogs = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addWatchdogs(data));
  }
};

export const updateWatchdogs = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.updateWatchdogs(data));
  }
};

export const deleteWatchdog = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.deleteWatchdog(data));
  }
};

export const clearSubstitutionsList = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.clearSubstitutionsList());
  }
};

export const clearSubstitutionsMonths = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.clearSubstitutionsMonths());
  }
};

export const clearWatchdogs = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.clearWatchdogs());
  }
};

export const clearWatchdogsMonths = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.clearWatchdogsMonths());
  }
};