import CloseButton from 'src/components/Buttons/CloseButton';
import config from 'src/constants/config';
import DateFormat from 'src/utils/dateFormat';
import Modal from '../../../utils/modal';
import moment from 'src/utils/moment';
import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import semver from 'semver';
import SVG from 'src/components/Images/SvgRenderer';
import useStyles from './style';
import versions from 'src/constants/versions';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { onlyUnique } from 'src/utils/useFunctions';
import { saveAs } from 'file-saver';
import { setAppData } from 'src/utils/useApp';
import { setChangelogModal } from '../../../store/actions/modals.actions';
import { testingParams } from 'src/utils/useTest';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const ChangelogModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation(["translations", "changelog", "suggestions"]);
  const dispatch = useAppDispatch();
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);  
  const userData = useAppSelector((state: any) => state.user);  
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
    };
    dispatch(setChangelogModal(settings));
    setAppData({changelogVersion: config.APP_CHANGELOG_VERSION});
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const isDeveloperMode = testingParams.developerMode;

  const handleDownloadLog = () => {
    let text = [];
    text.push("Twigsee WebApp Changelog");
    versions().map((item: any) => {
      text.push("");
      text.push("");
      text.push(`${t('version')} ${item.name} (${DateFormat(moment(item.date, "DD-MM-YYYY"), "default", languageData, t)})`);
      if(item.text) {
        text.push("");
        text.push(item.text);
      }
      config.APP_CHANGELOG_ROLES.map((role: any) => {
        const list = item.list[role];
        if(list) {
          text.push("");
          text.push(`— ${t(`role_${role}`)}`);
          list.map((listItem: any) => {
            text.push(`   • ${t(listItem, {ns: 'changelog'})}`);
          });
        }
      });
    });
    let blob = new Blob([text.join("\n")], {type: "text/plain;charset=utf-8"});
    let file_name = `twigsee_webapp_changelog_${moment().format("YYYY_MM_DD_HH_mm_ss")}.txt`;
    saveAs(blob, file_name);  
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "changelogModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p data-cy={isCypress() ? 'changelogModalTitle' : null}>{ modalsData.changelogModal.isAutoOpen ? t('changelog_what_is_new') : t('changelog_title')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <div className={classes.accordions}>
            {
              versions().map((item: any, key: any) => {
                const list = userData.userStatus === "loggedIn" ? item.list[getUserRole(userData.userObject.roleType)] : Object.keys(item.list).map((role: any) => { return item.list[role]; }).flat().filter(onlyUnique);
                if(userData.userStatus !== "loggedIn") {
                  list.sort((a: any) => { if(a.includes("nothing_new")) return -1; else return 1; });
                }
                return (
                  <Accordion className={classes.accordion} defaultExpanded={semver.satisfies(item.name, config.APP_VERSION)} key={`k_${key}`} data-cy={isCypress() ? `changelogModalAccordion${item.name}` : null} disabled={!list}>
                    <AccordionSummary className={classes.accordionSummary} expandIcon={<SVG src="chevron-down"/>}>
                      <span data-clarity-unmask="true">{item.name}</span>
                      <p data-clarity-unmask="true">{DateFormat(moment(item.date, "DD-MM-YYYY"), "default", languageData, t)}</p>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      {
                        item.text ? (
                          <p>
                            {item.text}
                          </p>
                        ) : null
                      }
                      {
                        list ? (
                          <ul className={classes.list}>
                            {list.map((listItem: any, lkey: any) => (
                              <li key={`k_${lkey}`}>
                                {listItem.includes("nothing_new") ? (<span>{t(listItem, {ns: 'changelog'})}</span>) : t(listItem, {ns: 'changelog'})}
                              </li>
                            ))}
                          </ul> 
                        ) : null
                      }
                    </AccordionDetails>
                  </Accordion>
                );
              })
            }
          </div>
          {
            isDeveloperMode ? (
              <NormalButton className={classes.downloadButton} onClick={handleDownloadLog} dataCy="changelogModalDownloadButton">
                {t('changelog_download')}
              </NormalButton>
            ) : null
          }
        </div>
      </div>
    </Modal>
  );
};

export default ChangelogModal;