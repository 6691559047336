import HttpService from '../http.service';

const listCalendar = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get('v2', `calendar${query ? `${query}`: ""}`, {}, {});
};

const createEvent = (payload: any) => {
  return HttpService.post('v2', `calendar`, payload, {}, {});
};

const editEvent = (eventID: any, payload: any) => {
  return HttpService.put('v2', `calendar/${eventID}`, payload, {}, {});
};

const deleteEvent = (eventID: any) => {
  return HttpService.remove('v2', `calendar/${eventID}`, {});
};

const uploadFile = (uploadURL: any, payload: any) => {
  return HttpService.post('v2', uploadURL, payload, {'Content-Type': 'application/json'}, {});
};

export {
  listCalendar,
  createEvent,
  editEvent,
  deleteEvent,
  uploadFile,
};