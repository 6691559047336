import HttpService from '../http.service';

const createOrder = (payload: any) => {
  return HttpService.post('v2', `order`, payload, {}, {});
};

const getOrder = (path?: any) => {
  return HttpService.get('v2', `order${path ? '?path=' + path : ''}`, {}, {});
};

export {
  createOrder,
  getOrder,
};