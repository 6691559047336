import React from 'react';
import SchoolsSelect from 'src/components/Selects/SchoolsSelect';
import useStyles from './style';
import { useAppSelector } from 'src/hooks/redux-hooks';

type SchoolSelectType = {
  onSelect: any;
};

const SchoolSelect: React.FunctionComponent<SchoolSelectType> = ({ onSelect }) => {

  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);

  const listSchools = dataData.schools;
  const listClasses = dataData.classes;
  const listChildren = dataData.children;

  const getSchools = () => {
    return listSchools.map((theSchool: any) => {
      return theSchool;
    }).filter((value: any) => value !== null);
  };

  const getChildren = () => {
    return listChildren.map((theChild: any) => {
      return theChild;
    }).filter((value: any) => value !== null);
  };

  const getClasses = () => {
    return listClasses.map((theClass: any) => {
      if(getChildren().filter((theChild: any) => theChild.classID.indexOf(theClass.classID) !== -1).length > 0) {
        return theClass;
      } else {
        return null;
      }
    }).filter((value: any) => value !== null);
  };

  const schoolsList = getSchools().map((theSchool: any) => {
    if(getClasses().filter((theClass: any) => theClass.schoolID === theSchool.schoolID).length > 0) {
      return theSchool;
    } else {
      return null;
    }
  }).filter((value: any) => value !== null);

  const handleChange = (_: any, value: any) => {
    onSelect(value.school.schoolID);
  };

  return (
    <div className={classes.schoolSelectWrapper}>
      <SchoolsSelect defaultSchools={schoolsList.map((item: any) => { return { schoolID: item.schoolID }; })} onClickSchool={handleChange} isAllowOnlyOneSchoolAtOnce={true} customClasses={{schoolsSelect: classes.schoolsSelect, container: classes.customContainer, topWrapper: classes.customTopWrapper}}/>
    </div>
  );
};

export default SchoolSelect;