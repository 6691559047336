import CalendarEventsItem from 'src/components/Items/CalendarEventsItem';
import moment from '../../../../../utils/moment';
import React, { useCallback, useMemo, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import theme from '../../../../../ui/theme';
import { compareDates } from '../../../../../utils/date';
import { createUseStyles } from 'react-jss';
import { getCalendarBackgroundColor } from 'src/utils/useFunctions';
import { isCypress } from '../../../../../utils/useCypress';
import { Tooltip } from '@mui/material';
import { useAppSelector } from '../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  getCalendarBackgroundColor: any,
}

const useStyles = createUseStyles((theme: any) => ({
  eventsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    width: 'calc(100% / 7)',
    height: '50px',
    backgroundColor: (props: Props) => props.getCalendarBackgroundColor,
    '&:nth-last-of-type(7)': {
      borderRadius: '0px 0px 0px 20px',
      [theme.breakpoints.down('md')]: {
        borderRadius: "0px",
      }, 
    },
    '&:nth-last-of-type(1)': {
      borderRadius: '0px 0px 20px 0px',
      [theme.breakpoints.down('md')]: {
        borderRadius: "0px",
      }, 
    },
  },
  today: {
    borderRadius: '100%',
    width: '28px',
    height: '28px',
    backgroundColor: theme.colors.grey[250],
    color: theme.colors.primaryBlue[500],
    fontWeight: '800',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '-4px auto',
  },
  date: {
    width: '100%',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'pointer',
    paddingTop: '10px',
    height: '20px',
    '&.empty': {
      color: '#bcbcbc',
      fontWeight: 'normal',
    },
  },
  eventsIcons: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    height: '20px',
  },
  type: {
    display: 'flex',
    width: '8px',
    height: '8px',
    '& > svg': {
      width: '8px',
      height: '8px',
    }
  },
}));

type DateItemType = {
  index?: any;
  date?: any;
};

const DateItem: React.FunctionComponent<DateItemType> = ({index, date}) => {

  const { t } = useTranslation();
  const browserData = useAppSelector((state: any) => state.browser);
  const calendarData = useAppSelector((state: any) => state.calendar);
  const configurationData = useAppSelector((state: any) => state.configuration);
  const allEvents = calendarData.events;
  const isLoading = calendarData.isLoading;

  const calendarEventTypes = useMemo(() => configurationData.configuration.calendarEventTypes, [configurationData.configuration.calendarEventTypes]);
  
  const getEvents = (allEvents: any, date: any) => {
    return allEvents.filter((item: any) => moment(date).isSameOrAfter(item.dateTimeFrom, 'day') && moment(date).isSameOrBefore(item.dateTimeTo, 'day'));
  };

  const events = useMemo(() => getEvents(allEvents, date), [allEvents, date]);

  const eventsRef: any = useRef(null);

  const [state, setState] = useStates({
    anchorEl: null,
    eventsCount: 1,
  });
  
  const classes = useStyles({
    getCalendarBackgroundColor: getCalendarBackgroundColor(date, index, theme),
  });

  const getCount = useCallback(() => {
    if(eventsRef.current) {
      const height = eventsRef.current.clientHeight - 16;
      const count = Math.floor(height / 34) - 1;
      setState("eventsCount", count < 1 ? 1 : count);
   } else {
    setState("eventsCount", 1);
   }
  }, [setState]);

  useEffect(() => {
    setTimeout(getCount, 100);
  }, [getCount], [browserData.height, isLoading]);

  useEffect(() => {
    setTimeout(getCount, 1000);
  }, [getCount], []);

  const handleClickMoreItems = (e: any) => {
    setState("anchorEl", eventsRef.current);
  };

  const handleCloseMoreItems = () => {
    setState("anchorEl", null);
  };

  return (
    <div className={classes.eventsWrapper} data-cy={isCypress() ? "calendarDayItem-" + moment(date).format("YYYY-MM-DD") : null} ref={eventsRef}>
      <p onClick={handleClickMoreItems} className={`${classes.date} ${events.length === 0 ? 'empty' : null}`}>
        <span className={compareDates(date, moment()) ? classes.today : ''} data-clarity-unmask="true">
          {date.date()}
        </span>
      </p>
      <div className={classes.eventsIcons} data-clarity-unmask="true">
        {
          calendarEventTypes.map((eventType: any, key: any) => {
            return events.filter((item: any,) => item.type === eventType.type).length > 0 ? (
              <Tooltip title={`${t(eventType.name)} (${events.filter((item: any) => item.type === eventType.type).length})`} placement='bottom' arrow key={`k_${key}`}>
                <span className={classes.type} style={{color: eventType.background}}>
                  <SVG src="circle"/>
                </span>
              </Tooltip>
            ) : null
          })
        }
      </div>
      <CalendarEventsItem anchorEl={state.anchorEl} onClose={handleCloseMoreItems} date={date} events={events}/>
    </div>
  );
};

export default DateItem;