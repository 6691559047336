import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
  },
  title: {
    fontSize: '18px',
    fontWeight: '500',
  },
  table: {
    '& .cellWrapper': {
      '&.header': {
        paddingRight: '32px',
      },
    },
  },
}));

export default useStyles;