import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  filterWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '32px',
    height: '100%',
  },
  classInputSelectWrapper: {
    maxWidth: '250px',
    minWidth: '250px',
  },
  classInputSelect: {
    maxHeight: '24px',
  },
  classInputChip: {
    height: '23px',
  },
}));

export default useStyles;