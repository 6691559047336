import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  reportViewSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
    gap: '16px',
    maxHeight: '100%',
    height: 'fit-content',
  },
  reportTypeWrapper: {
    overflow: 'auto',
    borderRadius: '24px 24px 16px 16px',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '400px',
  },
  search: {
    display: "flex",
    gap: '10px',
    justifyContent: "flex-start",
    backgroundColor: theme.colors.white,
    position: 'sticky',
    top: '0',
    overflow: 'hidden',
    width: 'calc(100% - 48px)',
    minHeight: '42px',
    padding: '24px',
    zIndex: '2',
    flexWrap: 'wrap',
    boxShadow: theme.shadows[2],
    '& > form': {
      '& > div': {
        '& > span': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  input: {
    width: '100%',
  },
  reportTypeItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    width: 'calc(100% - 48px)',
    backgroundColor: theme.colors.grey[100],
    padding: '18px 24px',
    flex: '1 1 auto',
  },
  radioInput: {
    '& > span': {
      padding: 'unset',
    }
  },
  reportTypeItem: {
    width: '100%',
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    cursor: 'pointer',
    transition: 'all 0.25s',
    '&:hover': {
      backgroundColor: theme.colors.grey[125],
    },
    '&:first-of-type': {
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    }, 
    '&:last-of-type': {
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
    },
  },
  reportTypeDetail: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'transparent',
    padding: '26px 24px',
    alignItems: 'center',
    '&.nonclickable': {
      cursor: 'auto !important',
    },
    '&.clickable': {
      cursor: 'pointer !important',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 12px',
    },
  },
  reportTypeTopBar: {
    display: 'flex',
    alignItems: 'center',
  },
  reportTypeInfo: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 90%',
  },
  reportTypeName: {
    color: theme.colors.grey[700],
    fontWeight: '700',
    fontSize: '18px',
    wordBreak: 'break-all',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  footer: {
    width: 'calc(100% - 16px)',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    height: '42px',
    backgroundColor: theme.colors.white,
    padding: '8px',
    '&:empty': {
      display: 'none',
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '50px',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    },
  },
}));

export default useStyles;