import ChildrenInput from 'src/components/Forms/ChildrenInput';
import ChildrenSelect from 'src/components/Selects/ChildrenSelect';
import CloseButton from 'src/components/Buttons/CloseButton';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import Select from 'src/components/Forms/Select';
import UsersInput from 'src/components/Forms/UsersInput';
import UsersSelect from 'src/components/Selects/UsersSelect';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { setCalendarFilterChildID, setCalendarFilterClassID, setCalendarFilterSchoolID, setCalendarFilterType, setCalendarFilterUserID } from 'src/store/actions/calendar.actions';
import { setCalendarFiltersModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "600px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    padding: '8px',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '8px',
  },
  select: {
    width: 'unset !important',
    flex: 'unset !important',
    '& > button': {
      '& > div': {
        '& > div': {
          '& > div': {
            '& > span': {
              '& > div': {
                '& > div': {
                  borderRadius: '0',
                },
              },
            },
          },
        },
      },
    },
  },
  popper: {
    '& > ul': {
      '& > li': {
        '& > div': {
          '& > div': {
            borderRadius: '0',
          },
        },
      },
    },
  },
  datepickers: {
    display: 'flex',
    gap: '8px',
  },
  fullDay: {
    marginLeft: 'auto',
  },
  count: {
    backgroundColor: theme.colors.white,
    color: '#EB4D63 !important',
    borderRadius: '100%',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px !important',
    lineHeight: '12px',
    fontWeight: '600',
  },
}));

const CalendarFiltersModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const calendarData = useAppSelector((state: any) => state.calendar);
  const configurationData = useAppSelector((state: any) => state.configuration);
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);

  const calendarEventTypes = useMemo(() => configurationData.configuration.calendarEventTypes, [configurationData.configuration.calendarEventTypes]);

  const userAccessSchools = userData.userAccessSchools;

  const filterSchoolID = calendarData.filterSchoolID;
  const filterClassID = calendarData.filterClassID;
  const filterChildID = calendarData.filterChildID;
  const filterUserID = calendarData.filterUserID;
  const filterType = calendarData.filterType;

  const listSchools = dataData.schools;
  const listClasses = dataData.classes;

  const listChildren = dataData.children;
  const listUsers = dataData.users;
  
  const [state, setState] = useStates({
    currentSchools: filterSchoolID.length !== 0 ? filterSchoolID : [],
    currentClasses: filterClassID ? filterClassID : [],
    currentChildren: filterChildID ? filterChildID : [],
    currentUsers: filterUserID ? filterUserID : [],
    currentTypes: filterType ? filterType : [],
  });
  
  const availableSchools = listSchools.filter((item: any) => userAccessSchools.calendar.includes(item.schoolID) && listClasses.filter((subItem: any) => subItem.schoolID === item.schoolID).length > 0).map((item: any) => { return item; });
  const schoolsID = state.currentSchools.length !== 0 ? state.currentSchools.map((item: any) => { return item.schoolID; }) : null;
  const availableClasses = schoolsID === null ? listClasses.filter((theclass: any) => !theclass.isArchived && theclass.active && theclass.childrenID.length > 0).map((item: any) => { return item; }) : listClasses.filter((theclass: any) => theclass.schoolsID.some((item: any) => schoolsID.includes(item)) && !theclass.isArchived && theclass.active && theclass.childrenID.length > 0).map((item: any) => { return item; });
  const classesID =  state.currentClasses.length !== 0 ? state.currentClasses.map((item: any) => { return item.classID; }) : null;
  const availableChildren = schoolsID === null ? listChildren : ((classesID && classesID.length > 0) ? listChildren.filter((child: any) => child.schoolsID.some((item: any) => schoolsID.includes(item)) && child.classesID.some((item: any) => classesID.includes(item))).map((item: any) => { return { childID: item.childID, classID: item.classID, schoolID: schoolsID }; }) : listChildren.filter((child: any) => child.schoolsID.some((item: any) => schoolsID.includes(item))).map((item: any) => { return { childID: item.childID }; }));
  const availableUsers = schoolsID === null ? listUsers.filter((user: any) => (user.roleType === 2 || user.roleType === 4) && user.userID > 0) : listUsers.filter((user: any) => user.schoolsID.some((item: any) => schoolsID.includes(item)) && (user.roleType === 2 || user.roleType === 4) && user.userID > 0);
  
  const isChanged = JSON.stringify(filterSchoolID) !== JSON.stringify(state.currentSchools) || JSON.stringify(filterClassID) !== JSON.stringify(state.currentClasses) || JSON.stringify(filterChildID) !== JSON.stringify(state.currentChildren) || JSON.stringify(filterUserID) !== JSON.stringify(state.currentUsers) || JSON.stringify(filterType) !== JSON.stringify(state.currentTypes);

  const onCloseModal = () => {
    const settings = {
      isOpen: false,
    };
    dispatch(setCalendarFiltersModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleChangeSchool = (value: any) => {
    setState("currentSchools", value);
    setState("currentClasses", []);
    setState("currentChildren", []);
    setState("currentUsers", []);
  };

  const handleChangeClass = (value: any) => {
    setState("currentClasses", value);
    setState("currentChildren", []);
  };

  const handleChangeChildren = (value: any) => {
    setState("currentChildren", value);
  };

  const handleChangeUsers = (value: any) => {
    setState("currentUsers", value);
  };

  const handleChangeType = (value: any) => {
    setState("currentTypes", value);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if(JSON.stringify(filterSchoolID) !== JSON.stringify(state.currentSchools)) {
      dispatch(setCalendarFilterSchoolID(state.currentSchools));
    }
    if(JSON.stringify(filterClassID) !== JSON.stringify(state.currentClasses)) {
      dispatch(setCalendarFilterClassID(state.currentClasses));
    }
    if(JSON.stringify(filterChildID) !== JSON.stringify(state.currentChildren)) {
      dispatch(setCalendarFilterChildID(state.currentChildren));
    }
    if(JSON.stringify(filterUserID) !== JSON.stringify(state.currentUsers)) {
      dispatch(setCalendarFilterUserID(state.currentUsers));
    }
    if(JSON.stringify(filterType) !== JSON.stringify(state.currentTypes)) {
      dispatch(setCalendarFilterType(state.currentTypes));
    }
    onCloseModal();
  };

  const handleClear = () => {
    setState("currentSchools", []);
    setState("currentClasses", []);
    setState("currentChildren", []);
    setState("currentUsers", []);
    setState("currentTypes", []);
  };

  const getTotalCount = useMemo(() => {
    let count = 0;
    if(state.currentSchools.length > 0) {
      count++;
    }
    if(state.currentClasses.length > 0) {
      count++;
    }
    if(state.currentChildren.length > 0) {
      count++;
    }
    if(state.currentUsers.length > 0) {
      count++;
    }
    if(state.currentTypes.length > 0) {
      count++;
    }
    return count;
  }, [state.currentChildren.length, state.currentClasses.length, state.currentSchools.length, state.currentTypes.length, state.currentUsers.length]);

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "calendarFiltersModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('calendar_filters')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <Select label={t('school')} isMultiple={true} className={classes.select} inputLabel={t("school")} items={availableSchools} selected={state.currentSchools} setSelected={handleChangeSchool} width={200} allowClear={true}/>
          <Select label={t('class')} isMultiple={true} className={classes.select} inputLabel={t("class")} items={availableClasses} selected={state.currentClasses} setSelected={handleChangeClass} width={200} allowClear={true}/>
          <ChildrenInput label={t('children')} selectLabel={t('children')} className={classes.select} selectedChildren={state.currentChildren.length > 0 ? state.currentChildren : []} setSelectedChildren={handleChangeChildren} isDeletable={false} onClick={() => setState("isChildrenSelectOpen", true)}/>
          {
            state.isChildrenSelectOpen ? (
              <ChildrenSelect
                selectedChildren={state.currentChildren}
                isInModal={true}
                isModalOpen={true}
                defaultClasses={(state.currentClasses && state.currentClasses.length !== 0) ? state.currentClasses.map((item: any) => { return {classID: item.classID, schoolID: schoolsID}; }) : availableClasses}
                defaultChildren={availableChildren}
                modalTitle="children"
                mode="select"
                modalAllowClose={true}
                modalAllowClear={true}
                modalAllowCancel={false}
                modalAllowNoChild={true}
                isAllowArchived={false}
                isAllowArchivedToggle={false}
                isSelectAll={true}
                isSelectInAllClass={true}
                isMultipleSelect={true}
                modalOnClose={() => setState("isChildrenSelectOpen", false)}
                modalOnSave={handleChangeChildren}
                modalAllowChildrenCount={true}
              />
            ) : null
          }
          {
            getUserRole(userData.userObject.roleType) !== "parent" ? (
              <>
              <UsersInput label={t('teachers')} selectLabel={t('teachers')} className={classes.select} selectedUsers={state.currentUsers} setSelectedUsers={handleChangeUsers} isDeletable={false} onClick={() => setState("isUsersSelectOpen", true)}/>
                {
                  state.isUsersSelectOpen ? (
                    <UsersSelect
                      selectedUsers={state.currentUsers}
                      isInModal={true}
                      isModalOpen={true}
                      defaultSchools={(state.currentSchools && state.currentSchools.length !== 0) ? schoolsID.map((item: any) => { return { schoolID: item }; }) : availableSchools}
                      defaultUsers={availableUsers}
                      modalTitle="teachers"
                      modalAllowClose={true}
                      modalAllowClear={true}
                      modalAllowCancel={false}
                      modalAllowNoUser={true}
                      isSelectAll={true}
                      isMultipleSelect={true}
                      mode='select'
                      modalOnClose={() => setState("isUsersSelectOpen", false)}
                      modalOnSave={handleChangeUsers}
                      modalAllowUsersCount={true}
                    />
                  ) : null
                }
              </>
            ) : null
          }
          <Select label={t('calendar_event_type')} isMultiple={true} useTranslate={true} className={classes.select} classNamePopper={classes.popper} inputLabel={t("calendar_event_type")} items={calendarEventTypes} selected={state.currentTypes} setSelected={handleChangeType} width={200} allowClear={true}/>
        </div>
        <div className={classes.footer}>
          {
            getTotalCount > 0 ? (
              <NormalButton buttonType="clear" startIcon={<span className={classes.count}>{getTotalCount}</span>} onClick={handleClear} dataCy="clearButton">
                {t("clear_selection")}
              </NormalButton>
            ) : null
          }
          <NormalButton buttonType="primary" onClick={handleSave} dataCy="saveButton" disabled={!isChanged}>
            {t("save")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};

export default CalendarFiltersModal;