import config from 'src/constants/config';
import moment from 'src/utils/moment';
import PopupModal from 'src/components/Modals/PopupModal';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { getQueryParams, trimCharacter } from 'src/utils/useFunctions';
import { getUserSetting } from 'src/utils/useUser';
import { setPopup } from 'src/store/actions/popups.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation } from 'react-router';
import { useStates } from 'src/utils/useState';
import { testingParams } from 'src/utils/useTest';

interface Props {
  children: any;
}

const PopupsController: React.FunctionComponent<Props> = (props:Props) => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const loadingData = useAppSelector((state: any) => state.loading);
  const popupsData = useAppSelector((state: any) => state.popups);
  const userData = useAppSelector((state: any) => state.user);
  const popupsService = useAppSelector((state: any) => state.services).popupsService;
  const popup = popupsData.popup;

  const [state, setState] = useStates({
    isFirstTimeLoaded: false,
    lastPopup: null,
  });

  const customPopupTime = parseInt(testingParams.popupTime) * 1000;

  const windowHandler: any = window;
  const appLocation = windowHandler.location;
  const pathName = trimCharacter(appLocation.pathname, "/");
  const hashName = trimCharacter(appLocation.hash, "#");

  const queryParams = getQueryParams(location);
  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;
  const enablePopups = queryParams.get("enablePopups");
  const isEnablePopups = enablePopups === "true" ? true : false;

  const popupPostPoned = getUserSetting(userData.userSettings, "customizations", ["app", "popup_postpone"]);
  const isPopupPostPoned = popupPostPoned ? moment(popupPostPoned).add(config.NEWS_POSTPONE_LIMIT,"minutes").isAfter(moment(),"minutes") : false;
  const isPopupEnabled = isEmbed ? isEnablePopups : true;

  const popupTime = (customPopupTime && config.APP_ENVIRONMENT !== "production") ? customPopupTime : (config.NEWS_NEXT_LIMIT * 60000);

  const loadPopupsAgain = () => {
    setTimeout(() => {
      setState("lastPopup", null);
      loadPopups();
    }, popupTime);
  };

  const loadPopups = useCallback(async () => {
    try {
      const result: any = await popupsService.listPopups({viewed: false});
      if(result && result.data) {
        const response = result.data[config.JSONLD_DATA];
        const count = result.data[config.JSONLD_COUNT];
        if(count > 0) {
          const firstPopup = response[0];
          const popupData = {...firstPopup, isMorePopups: count > 1};
          dispatch(setPopup(popupData));
          setState("lastPopup", popupData.id);
        }
        loadPopupsAgain();
        return true;              
      } else {
        loadPopupsAgain();
        return false;
      }
    } catch {
      loadPopupsAgain();
      return false;
    };
  }, [dispatch, popupsService, setState]);

  useEffect(() => {
    if(!state.isFirstTimeLoaded && (config.APP_INSTANT_ACCESS_PAGES.some((page: any) => pathName === page || pathName.startsWith(`${page}/`)) || config.APP_INSTANT_ACCESS_HASHES.some((hash: any) => hashName === hash) || (userData.userStatus !== "loggedIn" && loadingData.isLanguageLoaded) || (loadingData.isLanguageLoaded && loadingData.isUserLoaded && userData.userStatus === "loggedIn"))) {
      setState("isFirstTimeLoaded", true);
      loadPopups();
    }
  }, [loadPopups, hashName, pathName, loadingData.isUserLoaded, loadingData.isLanguageLoaded, userData.userStatus, state.isFirstTimeLoaded, setState], [hashName, pathName, loadingData.isUserLoaded, loadingData.isLanguageLoaded, userData.userStatus]);

  /*
  useEffect(() => {
    if(popup === null && state.lastPopup !== null) {
      setInterval(() => {
        loadPopups();
      }, customPopupTime ? customPopupTime : (config.NEWS_NEXT_LIMIT * 60000));
      setState("lastPopup", null);
    }
  }, [popup, state.lastPopup, loadPopups, setState], [popup]);

  */

  return (
    <>
      {
        (popup !== null && !isPopupPostPoned && isPopupEnabled) ? (
          <PopupModal/>
        ) : null
      }
      {props.children}
    </>
  );
};

PopupsController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default PopupsController;