import AuthenticatedImage from '../../Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import DateFormat from '../../../utils/dateFormat';
import Modal from '../../../utils/modal';
import moment from '../../../utils/moment';
import NormalButton from '../../Buttons/NormalButton';
import React, { useCallback, useMemo } from 'react';
import SVG from '../../Images/SvgRenderer';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { deleteSubstitution, setSubstitutionsCanAdd } from '../../../store/actions/substitutions.actions';
import { isCypress } from '../../../utils/useCypress';
import { isKey } from 'src/utils/useFunctions';
import { setExcuseNotesDetailModal, setSubstitutionsDetailModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  text: {
    '& a': {
      color: theme.colors.primaryBlue[500],
    },
  },
  body: {
    marginTop: "20px",
    position: 'relative',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
  },
  footer: {
    display: "flex",
    flexDirection: 'column',
    marginTop: "30px",
    gap: '15px',
    padding: "0 20px 20px 20px",
    '& > div': {
      display: 'flex',
      gap: '10px',
      width: '100%',
      justifyContent: "flex-end",
      '& > button > span': {
        zoom: '90%',
        [theme.breakpoints.down('sm')]: {
          zoom: '70%',
        },
      },
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: "0 25px",
    width: 'calc(100% - 50px)',
    flexDirection: 'row',
    '& + div': {
      marginTop: '16px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: theme.colors.grey[700],
    width: '200px',
    flex: '0 0 200px',
    '& > svg': {
      width: '18.5px',
      height: '18.5px',
      marginRight: '8px',  
    }, 
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '14px',
    color: '#9B9EBB',
    maxHeight: '300px',
    overflow: 'auto',
    gap: '8px',
  },
  footerInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    color: '#9B9EBB',
    paddingLeft: "25px",
    '& > p': {
      marginBottom: '0',
    },
  },
  childPhoto: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
    margin: '0px 5px',
    position: 'relative',
    '& > div': {
      backgroundColor: 'transparent',
      borderRadius: '50%',
      minWidth: '36px',
      minHeight: '36px',
      width: '36px',
      height: '36px',
      maxWidth: '36px',
      maxHeight: '36px',
    },
    '& > p': {
      fontSize: '14px',
      marginLeft: '16px',
      maxWidth: '100%',
      textAlign: 'center',
      marginBottom: '0',
      color: '#9B9EBB',
    },
  },
  nickname: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',  
  },
  tag: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
    padding: '2px 8px',
    width: 'fit-content',
    borderRadius: '10px',
    backgroundColor: '#EEEEEE',
    fontWeight: 'bold',
    marginTop: '8px',
    cursor: 'pointer',
  },
}));

const SubstitutionsDetailModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dataData = useAppSelector((state: any) => state.data);
  const substitutionsData = useAppSelector((state: any) => state.substitutions);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const substitutionsService = useAppSelector((state: any) => state.services).substitutionsService;

  const [state, setState] = useStates({
    canClick: true,
    isCustom: false,
    customData: [],
    isReady: false,
  });
  
  const substitutionID = modalsData.substitutionsDetailModal.substitutionID;
  const childID = modalsData.substitutionsDetailModal.childID;
  const readOnly = modalsData.substitutionsDetailModal.readOnly;
  const list = substitutionsData.list;
  const substitutionDataAll = useMemo(() => { return state.isCustom ? state.customData : (list.length > 0 && childID !== null && childID !== undefined) ? (list.filter((data: any) => data.childID === childID).length > 0 ? list.find((data: any) => data.childID === childID).dates : []) : []; }, [childID, list, state.customData, state.isCustom]);
  const substitutionData = useMemo(() => { return substitutionDataAll.length > 0 ? (substitutionDataAll.filter((data: any) => data.substitutionID === substitutionID).length === 0 ? {} : substitutionDataAll.find((data: any) => data.substitutionID === substitutionID)) : {}; }, [substitutionDataAll, substitutionID]);
  const childData = dataData.children.find((child: any) => child.childID === substitutionData.childID);
  const classData = substitutionData.classID ? dataData.classes.find((theClass: any) => theClass.classID === substitutionData.classID) : [];

  const onCloseModal = useCallback(() => {
    const settings = {
      isOpen: false,
      date: null,
      substitutionID: null,
      childID: null,
      readOnly: false,
    };
    dispatch(setSubstitutionsDetailModal(settings));
  }, [dispatch]);

  useEffect(() => {
    if(Object.keys(substitutionData).length === 0) {
      substitutionsService && substitutionsService.findSubstitution(substitutionID).then((result: any) => {
        if(result) {
          if(result.data) {
            if(result.data.substitution) {
              setState("customData", result.data.substitution);
              setState("isCustom", true);
              setState("isReady", true);
            } else {
              createNotification(t("substitution_failed_load"), "error");
              onCloseModal();
            }
          } else {
            createNotification(t("substitution_failed_load"), "error");
            onCloseModal();
          }
        } else {
          createNotification(t("substitution_failed_load"), "error");
          onCloseModal();
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("substitution_failed_load"), "error");
        onCloseModal();
      });
    } else {
      setState("isReady", true);
    }
  }, [onCloseModal, setState, substitutionData, substitutionID, substitutionsService, t], []);

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    if(state.canClick) {
      setState("canClick", false);
      dispatch(setSubstitutionsCanAdd(false));
      substitutionsService && substitutionsService.deleteSubstitution(substitutionData.substitutionID).then((result: any) => {
        if(result.status === 200) {
          createNotification(t("substitution_deleted"),"success");
          const settings = {
            substitutionID: substitutionData.substitutionID,
            childID: childData.childID,
          };
          dispatch(deleteSubstitution(settings));
          setState("canClick", true);
          onCloseModal();      
        } else {
          createNotification(t("substitution_not_deleted"),"error");
          setState("canClick", true);
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("substitution_not_deleted"),"error");
        setState("canClick", true);
      });
    }
  };

  const handleOpenExcuseNote = (absenceID: any) => {
    const settings = {
      isOpen: true,
      absenceID: parseInt(absenceID),
      childID: childID,
      type: "single",
      readOnly: true,
    };
    dispatch(setExcuseNotesDetailModal(settings));
  };
  
  return state.isReady ? (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      {
        Object.keys(substitutionData).length > 0 ? (
          <div className={classes.root} data-cy={isCypress() ? "substitutionDetailModal" : null}>
            <div className={classes.header}>
              <div className={classes.wrapper}>
                <p>
                  {t('substitution_detail')}
                  &nbsp;
                  {DateFormat(moment(substitutionData.date),"default", languageData, t)}
                  </p>
              </div>
              <CloseButton onClick={handleClose} dataCy="timesButton"/>
            </div>
            <div className={classes.body}>
              <div className={classes.item}>
                <span className={classes.title}>
                  <SVG src="user-circle-outlined"/>
                  {t('child')}
                </span>
                <div className={classes.childPhoto}>
                  <AuthenticatedImage thumbLink={childData.photo?.thumbLink}/>
                  <p className={classes.nickname}>{childData.displayName}</p>
                </div>
              </div>
              {
                substitutionData.classID ? (
                  <div className={classes.item}>
                    <span className={classes.title}>
                      <SVG src="class"/>
                      {t('class')}
                    </span>
                    <p className={classes.detail}>
                      {classData.name}
                    </p>
                  </div>
                ) : null
              }
              <div className={classes.item}>
                <p className={classes.title}>
                  <SVG src="calendar"/>
                  {t('date')}
                </p>
                <p className={classes.detail}>
                  {DateFormat(moment(substitutionData.date),"default", languageData, t)}
                </p>
              </div>
              <div className={classes.item}>
                <p className={classes.title}>
                  <SVG src="clock"/>
                  {t('part_of_day')}
                </p>
                <p className={classes.detail}>
                  {t(substitutionData.substitutionPartID === 1 ? "whole_day" : substitutionData.substitutionPartID === 2 ? "morning" : "afternoon" )}
                </p>
              </div>
              {
                (substitutionData.absenceID.length > 0 && !readOnly) ? (
                  <div className={classes.item}>
                    <p className={classes.title}>
                      <SVG src="calendar-excuse-notes"/>
                      {substitutionData.absenceID.length === 1 ? t('substitution_paired_excuse_note') : t('substitution_paired_excuse_notes')}
                    </p>
                    <p className={classes.detail}>
                      {
                        substitutionData.absenceID.length === 1 ? (
                          <span className={classes.tag} onClick={() => handleOpenExcuseNote(substitutionData.absenceID[0])}>{t('excuse_note')}</span>
                        ) : (
                          <>
                          {
                            substitutionData.absenceID.map((item: any, key: any) => (
                              <span className={classes.tag} onClick={() => handleOpenExcuseNote(item)} key={`k_${key}`}>{t('excuse_note')} {key + 1}.</span>
                            ))
                          }
                          </>
                        )
                      }
                    </p>
                  </div>
                ) : null
              }
              <div className={classes.footerInfo}>
                <p>{t('author')}: {substitutionData.authorName}</p>
                <p>{t('created')}: {DateFormat(moment(substitutionData.createdAt),"daymonthname", languageData, t)} &bull; {DateFormat(moment(substitutionData.createdAt),"time", languageData, t)}</p>
              </div>
            </div>
            {
              !readOnly ? (
                <div className={classes.footer}>
                {
                  (substitutionData.isEditable && !childData.isArchived) ? (
                    <div>
                      <NormalButton buttonType="primary" onClick={handleDelete} disabled={!state.canClick} dataCy="deleteAbsenceButton">
                        {t("delete_substitution")}
                      </NormalButton>
                    </div>
                  ) : null
                }
                </div>
              ) : (
              <div className={classes.footer}/>
              )
            }
          </div>
        ) : (
          <></>
        )
      }
    </Modal>
  ) : null;
};

export default SubstitutionsDetailModal;