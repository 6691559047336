import Button from '@mui/material/Button';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'src/utils/moment';
import React, { useCallback } from 'react';
import RenderChild from '../RenderChild';
import SVG from '../../../Images/SvgRenderer';
import theme from '../../../../ui/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createUseStyles } from 'react-jss';
import { getUserRole } from '../../../../utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { onlyUnique } from 'src/utils/useFunctions';
import { setExcuseNotesCurrentChildren, setExcuseNotesCurrentChildrenData, setExcuseNotesCurrentClass, setExcuseNotesDate, setExcuseNotesViewMode } from 'src/store/actions/excusenotes.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props {
  size: any;
  toggleIconColor: any;
};

const useStyles = createUseStyles((theme: any) => ({
  childrenWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  childrenListWrapper: {
    display: 'flex',
    padding: '13px 18px 13px',
    alignItems: 'flex-start',
    width: 'calc(100% - 36px)',
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 18px)',
      padding: '13px 9px 13px',
    },
  },
  childrenAllList: {
    display: 'flex',
    flexDirection: "row",
    flexWrap: 'wrap',
    gap: '5px',
    padding: '13px 18px 13px',
    alignItems: 'flex-start',
    width: 'calc(100% - 36px)',
    maxWidth: '100%',
    height: 'fit-content',
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 18px)',
      padding: '13px 9px 13px',
    },
  },
  childrenList: {
    display: 'flex',
    flexDirection: "row",
    flexWrap: 'wrap',
    gap: '5px',
    padding: '0px 18px 13px',
    alignItems: 'flex-start',
    width: 'calc(100% - 36px)',
    maxWidth: '100%',
    height: 'fit-content',
    backgroundColor: theme.colors.white,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 18px)',
      padding: '0px 9px 13px',
    },
  },
  childWrapper: {
    width: (props: Props) => (props.size + 30) + 'px',
    flex: (props: Props) => '0 0 ' + (props.size + 30) + 'px',  
  },
  attendanceDetail: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    minWidth: '100%',
    width: 'calc(100% - 48px)',
    padding: '0px 24px 13px',
    gap: '8px',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      '& svg': {
        width: '10px',
        height: '10px',
        marginRight: '2px',
      },    
    },
  },
  attendanceStatus: {
    fontWeight: 'bold',  
  },
  buttons: {
    display: 'flex',
    width: 'calc(100% - 48px)',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 24px',
  },
  toggleButton: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: '100%',
    height: '48px',
    minHeight: '48px',
    maxHeight: '48px',
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#E0E4F5',
    borderRadius: '16px',
    color: theme.colors.grey[650],
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'none',
    padding: '0',
    '& > svg': {
      marginLeft: '24px',
      width: '24px',
      height: '24px',
    },
    '& > span:not(.MuiTouchRipple-root)': {
      marginLeft: '18px',
      fontSize: '16px',
      fontWeight: '500',
      '& > span': {
        marginLeft: '8px',
        fontWeight: '400',
      },
    },
  },
  excuseNotesButton: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: '100%',
    height: '48px',
    minHeight: '48px',
    maxHeight: '48px',
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#E0E4F5',
    borderRadius: '16px',
    color: '#9B8AFF',
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'none',
    padding: '0',
    '& > svg': {
      marginLeft: '24px',
      width: '24px',
      height: '24px',
    },
    '& > span:not(.MuiTouchRipple-root)': {
      marginLeft: '18px',
      fontSize: '16px',
    },
  },
  icon: {
    marginRight: '24px',
    marginLeft: 'auto',
    height: '24px',
    '& > svg': {
      width: '24px',
      height: '24px',
      color: (props: Props) => props.toggleIconColor,
    },
  },
  chevron: {
    marginRight: '24px',
    marginLeft: 'auto',
    height: '24px',
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
}));

type RenderChildrenType = {
  state: any;
  setState: any;
  schoolData?: any;
  classData?: any;
};

const RenderChildren: React.FunctionComponent<RenderChildrenType> = ({
  state,
  setState,
  schoolData,
  classData,
}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sizes = {
    xs: 32,
    sm: 38,
    md: 40,
    lg: 48,
    xl: 54,
  };

  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const size = isXl ? sizes.xl : isLg ? sizes.lg : isMd ? sizes.md : isSm ? sizes.sm : isXs ? sizes.xs : 30;

  const nextPlannedStatus: any = {
    "none": "am",
    "am": "pm",
    "pm": "all",
    "all": "none",
  };

  const isSelectedChild = useMemo(() => (searchData: any) => {
    return state.selectedChildren.filter((item: any) => JSON.stringify(item) === JSON.stringify(searchData)).length !== 0;
  }, [state.selectedChildren]);

  const attendanceData = useAppSelector((state: any) => state.attendance);
  const userData = useAppSelector((state: any) => state.user);
  const classID = useMemo(() => (getUserRole(userData.userObject.roleType) === "parent" || state.displayMode === "simple") ? null : classData.classID, [classData, userData.userObject.roleType, state.displayMode]);
  const classInfo = useMemo(() => state.functions.getClassData(classID), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search]);
  const childrenList = useMemo(() => (getUserRole(userData.userObject.roleType) === "parent" || state.displayMode === "simple") ? state.functions.getChildren() : state.functions.getChildrenByClassID(classID), [classID, state.functions, userData.userObject.roleType, state.displayMode], [classID, state.functions, state.isShowArchived, state.search, userData.userObject.roleType, state.displayMode]);
  const totalChildren = useMemo(() => state.functions.getChildrenByClassID(classID), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search]);

  const totalSelectedChildren = useMemo(() => state.functions.getChildrenByClassID(classID).filter((item: any) => isSelectedChild(state.isSelectInAllClass ? { childID: item.childID } : { childID: item.childID, classID: classID })), [classID, isSelectedChild, state.functions, state.isSelectInAllClass], [classID, isSelectedChild, state.functions, state.isAllowArchived, state.search, state.isSelectInAllClass]);
  const totalPresentChildren = useMemo(() => totalSelectedChildren.length, [totalSelectedChildren.length]);
  
  const totalChildrenPlanned = useMemo(() => state.functions.getChildrenByClassID(classID).map((item: any) => { return state.functions.getChildDataByClass(item.childID, classID); }).filter((item: any) => item.planned !== "none"), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search]);
  const totalChildrenPlannedAll = useMemo(() => state.functions.getChildrenByClassID(classID).map((item: any) => { return state.functions.getChildDataByClass(item.childID, classID); }).filter((item: any) => item.planned === "all"), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search]);
  const totalChildrenPlannedAM = useMemo(() => state.functions.getChildrenByClassID(classID).map((item: any) => { return state.functions.getChildDataByClass(item.childID, classID); }).filter((item: any) => item.planned === "am"), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search]);
  const totalChildrenPlannedPM = useMemo(() => state.functions.getChildrenByClassID(classID).map((item: any) => { return state.functions.getChildDataByClass(item.childID, classID); }).filter((item: any) => item.planned === "pm"), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search]);
  const totalChildrenPlannedNone = useMemo(() => state.functions.getChildrenByClassID(classID).map((item: any) => { return state.functions.getChildDataByClass(item.childID, classID); }).filter((item: any) => item.planned === "none"), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search]);

  const getToggleStatus = () => {
    if(classInfo.attendanceMethod === "realtime") {
      if(totalChildren.length === totalSelectedChildren.length) return { status: "all", title: t("present"), icon: "circle", color: '#40B784' };
      else return { status: "none", title: t("default"), icon: "circle-outlined", color: theme.colors.grey[650] };
    } else {
      if(totalChildren.length === totalChildrenPlannedAll.length) return { status: "all", title: t("whole_day"), icon: "circle", color: '#40B784' };
      else if(totalChildren.length === totalChildrenPlannedAM.length) return { status: "am", title: t("morning"), icon: "circle-half-left", color: '#40B784' };
      else if(totalChildren.length === totalChildrenPlannedPM.length) return { status: "pm", title: t("afternoon"), icon: "circle-half-right", color: '#40B784' };
      else return { status: "none", title: t("default"), icon: "circle-outlined", color: theme.colors.grey[650] };
    }
  };

  const toggleStatus: any = getToggleStatus();

  const totalAttendanceChildren = useMemo(() => totalChildren.filter((item: any) => state.functions.getChildData(item.childID).classData && state.functions.getChildData(item.childID).classData.filter((clsItem: any) => clsItem.classID === classID).length !== 0 ? (state.functions.getChildData(item.childID).classData.find((clsItem: any) => clsItem.classID === classID).morningAttendanceStatusId || state.functions.getChildData(item.childID).classData.find((clsItem: any) => clsItem.classID === classID).afternoonAttendanceStatusId) : (state.functions.getChildData(item.childID).morningAttendanceStatusId || state.functions.getChildData(item.childID).afternoonAttendanceStatusId)).filter((item: any) => !isSelectedChild(state.isSelectInAllClass ? { childID: item.childID } : { childID: item.childID, classID: classID })), [classID, isSelectedChild, state.functions, state.isSelectInAllClass, totalChildren], [classID, isSelectedChild, state.functions, state.isAllowArchived, state.search, state.isSelectInAllClass, totalChildren]);
  const totalMissingCount = useMemo(() => totalChildren.length - totalSelectedChildren.length - totalAttendanceChildren.length, [totalAttendanceChildren.length, totalChildren.length, totalSelectedChildren.length]);

  const totalChildrenPresent = classInfo.attendanceMethod === "realtime" ? totalPresentChildren : totalChildrenPlanned.length;
  const totalChildrenMissing = classInfo.attendanceMethod === "realtime" ? totalMissingCount : totalChildrenPlannedNone.length;

  const classes = useStyles({
    size: size,
    toggleIconColor: toggleStatus.color,
  });

  const handleExcuseNotes = useCallback(() => {
    const childrenIDs = childrenList.map((item: any) => { return item.childID; }).filter(onlyUnique);
    dispatch(setExcuseNotesCurrentChildren(childrenIDs));
    dispatch(setExcuseNotesCurrentChildrenData(childrenList));
    dispatch(setExcuseNotesCurrentClass(classInfo));
    dispatch(setExcuseNotesDate(moment(attendanceData.date)));
    dispatch(setExcuseNotesViewMode('class'));
    navigate('/excuse-notes');
  }, [attendanceData.date, childrenList, classInfo, dispatch, navigate]);

  const statusDisplay = useMemo(() => (status: any) => {
    if(status.id === 1) return totalChildrenPresent === 0 ? false : true;
    else if(status.id === 5) return totalChildrenMissing === 0 ? false : true;
    else return status.count === 0 ? false : true;
  }, [totalChildrenPresent, totalChildrenMissing]);

  const statusCounter = useMemo(() => (status: any) => {
    if(status.id === 1) return totalChildrenPresent;
    else if(status.id === 5) return totalChildrenMissing;
    else return status.count;
  }, [totalChildrenPresent, totalChildrenMissing]);

  const handleToggleAll = useCallback((e: any) => {
    e.stopPropagation();
    e.preventDefault();
    let newSelectedChildren: any = classInfo.attendanceMethod === "planned" ? [] : [...state.selectedChildren];
    let selectData: any = [];
    totalChildren.forEach((child: any) => {
      const defaultChildData = state.functions.getChildDataByClass(child.childID, classID);
      let itemData = {
        school: schoolData,
        class: classData,
        child: child,
        childData: defaultChildData,
        classData: classInfo,
      };
      if(classInfo.attendanceMethod === "planned") {
        const newChild = state.isSelectInAllClass ? { childID: child.childID } : { childID: child.childID, classID: classID };
        newSelectedChildren = [...newSelectedChildren, newChild];
      } else {
        if(totalChildren.length === totalSelectedChildren.length) {
          newSelectedChildren = newSelectedChildren.filter((item: any) => JSON.stringify(item) !== JSON.stringify(state.isSelectInAllClass ? { childID: child.childID } : { childID: child.childID, classID: classID }));
        } else {
          const newChild = state.isSelectInAllClass ? { childID: child.childID } : { childID: child.childID, classID: classID };
          if(newSelectedChildren.filter((child: any) => JSON.stringify(child) === JSON.stringify(newChild)).length === 0) {
            newSelectedChildren = [...newSelectedChildren, newChild];
          }
        }
      }
      if(classInfo.attendanceMethod === "planned") {
        const newChildData = {
          ...defaultChildData,
          planned: nextPlannedStatus[toggleStatus.status],
        };
        itemData = {...itemData, childData: newChildData};
      }
      selectData = [...selectData, itemData];
    });
    setState("selectedChildren", newSelectedChildren);
    if(state.onClickSelectAll) state.onClickSelectAll(e, selectData, newSelectedChildren); 
  }, [classID, setState, state.functions, state.isSelectInAllClass, state.selectedChildren, state.validateAbsence, state.validateMultipleSchool, state.customCombineMessage, totalChildren, totalSelectedChildren.length, dispatch, t]);

  const childrenRender = useMemo(() => (
    <>
      {
        (getUserRole(userData.userObject.roleType) === "parent" || state.displayMode === "simple") ? (
          <div className={classes.childrenListWrapper}>
            <div className={`${classes.childrenAllList} ${state.customClasses ? (state.customClasses.childrenList ? state.customClasses.childrenList : "") : ""}`} data-cy={isCypress() ? 'childrenContainer' : null}>
              {
                childrenList.map((theChild: any, key: any) => (
                  <div className={classes.childWrapper} key={`k_${key}`}>
                    <RenderChild state={state} setState={setState} childData={theChild} size={size}/>   
                  </div>  
                ))
              }
            </div>
          </div>
        ) : (
          <div className={classes.childrenWrapper} data-cy={isCypress() ? 'childrenWrapper' : null}>
            {
              state.isShowAttendanceCount ? (
                <div className={classes.attendanceDetail}>
                {
                  classInfo.classAttendanceStatus ? classInfo.classAttendanceStatus.filter((status: any) => statusDisplay(status)).map((status: any, key: any) => (
                    <span className={classes.attendanceStatus} key={`k_${key}`} style={{color: status.color}} data-cy={isCypress() ? 'attendanceStatus' + status.id : null}>
                      <CircleIcon/>
                      {statusCounter(status)} {status.name}
                    </span>
                  )) : null
                }
                </div>
              ) : null
            }
            <div className={`${classes.childrenList} ${state.customClasses ? (state.customClasses.childrenList ? state.customClasses.childrenList : "") : ""}`} data-cy={isCypress() ? 'childrenContainer' : null}>
              {
                childrenList.map((theChild: any, key: any) => (
                  <div className={classes.childWrapper} key={`k_${key}`}>
                    <RenderChild state={state} setState={setState} schoolData={schoolData} classData={classData} childData={theChild} size={size}/>   
                  </div>  
                ))
              }
            </div>
            {
              state.mode === "attendance" ? (
                <div className={classes.buttons}>
                  <Button className={classes.excuseNotesButton} onClick={handleExcuseNotes} data-cy={isCypress() ? 'excuseNotesButton' : null}>
                    <SVG src="calendar-excuse-notes"/>
                    <span>{t('excuse_notes')}</span>
                    <span className={classes.chevron}>
                      <SVG src="chevron-right"/>
                    </span>
                  </Button>
                </div>
              ) : null
            }
          </div>
        )
      }
    </>
  ), [childrenList, classData, classInfo.classAttendanceStatus, classes.attendanceDetail, classes.attendanceStatus, classes.excuseNotesButton, classes.chevron, classes.childWrapper, classes.childrenList, classes.childrenListWrapper, classes.childrenAllList, classes.childrenWrapper, handleExcuseNotes, schoolData, setState, size, state, statusCounter, statusDisplay, t, userData.userObject.roleType]);

  return childrenRender;

}

export default RenderChildren;