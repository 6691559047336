import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  resultWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
    height: 'calc(100% - 32px)',
    '& > span': {
      fontSize: '20px',
      fontWeight: '700',
    },
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      minHeight: '16px',
    },
  },
  filtersWrapper: {
    width: 'calc(100% - 16px)',
    display: 'flex',
    gap: '8px',
    padding: '8px 0',
    alignItems: 'flex-start',
  },
  filters: {
    flex: '0 1 auto',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  select: {
    marginLeft: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
    '&:empty': {
      display: 'none',
    },
  },
  footer: {
    width: 'calc(100% - 16px)',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    height: '42px',
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    borderRadius: '16px',
    padding: '8px',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '50px',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
}));

export default useStyles;