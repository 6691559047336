import Banner from 'src/components/Layouts/Banner';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useRef } from 'react';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { saveUserSettings } from 'src/utils/useUser';
import { setTheme } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 60px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    padding: '20px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  banner: {
    width: 'calc(40% - 40px)',
    maxWidth: '100%',
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    fontSize: '13px',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 40px)',
      borderRadius: '0px',
    },
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    backgroundColor: theme.colors.grey[200],
    borderRadius: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.black,
    },
  },
  settings: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '80%',
    '& > button': {
      '&.disabled': {
        cursor: 'default',
        pointerEvents: 'none',
      },
      '& > span': {
        width: '100%',
      },
    },
  },
  experimental: {
    backgroundColor: theme.colors.systemRed[500],
    color: theme.colors.white,
    padding: '2px 4px',
    borderRadius: '8px',
    marginLeft: 'auto',
    fontSize: '12px',
  },
}));

const Theme: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);

  const [state, setState] = useStates({
    canContinue: true,
    theme: layoutData.theme,
  });

  const isSaving = useRef(false);

  const handleChange = async (value: any) => {
    if(isSaving.current === false && state.canContinue) {
      isSaving.current = true;
      const newValue = value;
      const result = await saveUserSettings(dispatch, userData, "customizations", ["theme"], newValue);
      if(result) {
        setState("theme", value);
        createNotification(t('app_theme_saved'), "success");
        dispatch(setTheme(value));
        isSaving.current = false;
      } else {
        createNotification(t('app_theme_failed'), "error");
        isSaving.current = false;
      }
    }
  };

  return (
    <>
    <div className={classes.box}>
      <div className={classes.buttons}>
        <NormalButton className={(!state.canContinue || state.theme === "light") ? "disabled" : null} buttonType="blackOutlined" onClick={() => handleChange("light")}>
          {t('app_theme_light')}
        </NormalButton>
        <NormalButton className={(!state.canContinue || state.theme === "dark") ? "disabled" : null} buttonType="black" onClick={() => handleChange("dark")}>
          {t('app_theme_dark')}
          <span className={classes.experimental}>
            {t('experimental')}
          </span>
        </NormalButton>
      </div>
    </div>
    <Banner className={classes.banner} icon={true} type='info'>
      {t(`app_theme_detail`)}
    </Banner>
    </>
  );
};

export default Theme;