import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';

interface Props {
  type?: any;
  timeout?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  notification: {
    position: 'relative',
    display: 'block',
    padding: '12px 24px',
    marginRight: '16px',
    borderRadius: '6px',
    fontSize: '18px',
    cursor: 'pointer',
    width: 'fit-content',
    backgroundColor: (props: Props) => {
      if(props.type === "default") return theme.colors.primaryBlue[500];
      else if(props.type === "info") return theme.colors.primaryBlue[100];
      else if(props.type === "success") return theme.colors.systemGreen[500];
      else if(props.type === "warning") return theme.colors.systemOrange[500];
      else if(props.type === "error") return theme.colors.systemRed[500];
      else return theme.colors.primaryBlue[500];     
    },
    color: (props: Props) => {
      if(props.type === "default") return theme.colors.white;
      else if(props.type === "info") return theme.colors.black;
      else if(props.type === "success") return theme.colors.white;
      else if(props.type === "warning") return theme.colors.white;
      else if(props.type === "error") return theme.colors.white;
      else return theme.colors.white;     
    },
  },
  counter: {
    display: 'block',
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    width: '100%',
    height: '5px',
  },
  bar: {
    display: 'block',
    height: '100%',
    backgroundColor: (props: Props) => {
      if(props.type === "default") return "rgba(255,255,255,0.5)";
      else if(props.type === "info") return "rgba(0,0,0,0.15)";
      else if(props.type === "success") return "rgba(255,255,255,0.5)";
      else if(props.type === "warning") return "rgba(255,255,255,0.5)";
      else if(props.type === "error") return "rgba(255,255,255,0.5)";
      else return "rgba(255,255,255,0.5)";     
    },
    transitionProperty: 'width',
    transitionDuration: (props: Props) => props.timeout + 'ms',
    transitionTimingFunction: 'linear',
    transitionDelay: '0s',
    maxWidth: '100%',
    width: '100%',
    borderRadius: '0 0 6px 6px',
    '&.active': {
      width :'0%',
    },  
  }, 
}));

type NativeNotificationType = {
  date: any;
  children: any;
  type: 'default' | 'info' | 'success' | 'warning' | 'error';
  timeout: any;
  cancelable?: boolean;
  id?: string,
  className?: any;
};

const NativeNotification: React.FunctionComponent<NativeNotificationType> = ({ date, children, type, timeout, cancelable, className }) => {
  
  const currentDate = Date.now();
  const expire = parseInt(date) + parseInt(timeout);
  const [state, setState] = useStates({
    isVisible: timeout === 0 ? true : (expire > currentDate ? true : false),
    isAnimated: false,
  });

  const classes = useStyles({
    timeout: timeout,
    type: type,
  });

  const handleOnClick = () => {
    if(cancelable)
    handleRemove();  
  };  
  
  const handleRemove = () => {
    setState("isVisible", false);
  };
  
  useEffect(() => {
    if(timeout && timeout !== 0) {
      setTimeout(function() {
        setState("isVisible", false);
      }, timeout);
      setState("isAnimated", true);
    }
  }, [timeout, setState], []);
  
  return state.isVisible ? (
    <div className={`${classes.notification} ${className ? className : ''}`} onClick={handleOnClick} data-cy={isCypress() ? `notification-${type}` : null}>
      {children}
      <div className={classes.counter}>
        <span className={`${classes.bar} ${state.isAnimated ? 'active' : null}`}/>
      </div>
    </div>  
  ) : null;
}

export default NativeNotification;