import { createUseStyles } from "react-jss";

interface Props {
  showFooter?: any;
};
  
const useStyles = createUseStyles((theme: any) => ({
  wizard: {
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    borderRadius: '24px',
    padding: '16px',
    width: 'calc(100% - 32px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: 'calc(100% - 32px)',
  },
  stepper: {
    padding: '8px 0',
    width: '100%',
    minHeight: '58px',
    flex: '1 0 auto',
  },
  contentWrapper: {
    display: 'flex',
    width: '100%',
    height: (props: Props) => {
      if(props.showFooter) return 'calc(100% - 156px)';
      else return 'calc(100% - 90px)';
    },
    flex: '1 1 auto',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  stepButton: {
    '&:not(.Mui-disabled)': {
      '& .MuiStepIcon-root': {
        '&:not(.Mui-active)': {
          color: theme.colors.grey[800],
        },
      },
      '& .MuiStepLabel-label': {
        '&:not(.Mui-active)': {
          color: theme.colors.grey[800],
          fontWeight: '500',
        },
      },
    },
    '& .MuiStepLabel-label': {
      '&.Mui-active': {
        fontWeight: '600',
      }
    },
    '& p': {
      fontSize: '12px',
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '42px',
    alignItems: 'center',
    gap: '16px',
  },
}));

export default useStyles;