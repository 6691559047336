import DateFormat from '../../../utils/dateFormat';
import DateItem from './DateItem';
import IconButton from 'src/components/Buttons/IconButton';
import moment from '../../../utils/moment';
import months from '../../../constants/months';
import Popover from '@mui/material/Popover';
import React, { useMemo } from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import { weekdaysMin } from '../../../constants/weekdays';
import { getUserSetting } from 'src/utils/useUser';
import { moveLastToFirstInArray } from 'src/utils/useFunctions';

interface Props {
  weekNumber?: boolean,
};

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%', 
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
  },
  datePickerInput: {
    position: "relative",
    borderRadius: "12px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    minWidth: 'calc(100% - 27px)',
    maxWidth: 'calc(100% - 27px)',
    height: "40px",
    backgroundColor: theme.colors.white,
    padding: "0px 10px 0px 15px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.colors.grey[325],
    '&:hover': {
      cursor: "pointer",
    },
    '&.disabled': {
      cursor: 'auto',
      backgroundColor: theme.colors.grey[75],
      color: theme.colors.grey[560],
      '& svg': {
        color: theme.colors.grey[560],
      },
    },
  },
  selectedDate: {
    marginBottom: '0 !important',
    fontSize: '14px',
    cursor: "pointer"
  },
  calendarWrapper: {
    '& .MuiPopover-paper': {
      borderRadius: '10px',
      boxShadow: theme.shadows[2],
    },
  },
  calendar: {
    position: "relative",
    width: (props: Props) => props.weekNumber ? "320px" : "280px",
    maxHeight: "500px",
    maxWidth: '100%',
    backgroundColor: theme.colors.white,
    zIndex: "9999",
    display: "flex",
    flexWrap: "wrap",
    borderRadius: '10px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    overflow: 'hidden',
  },
  calendarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '5px 20px',
    '& p': {
      marginBottom: '0',
      fontWeight: 'bold',
    }
  },
  weekdayHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#ececec',
    cursor: 'auto',
  },
  weekday: {
    width: 'calc(100% / 7)',
    padding: '5px 0',
    fontSize: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      width: '100%',
      textAlign: 'center',
      marginBottom: '0 !important',
      fontSize: '11px !important',
    },
  },
  dateWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  navigator: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '68px',
    '& svg': {
      color: theme.colors.primaryBlue[500],
      width: '20px',
      height: '20px',
    },
  },
}));

type DatePickerType = {
  label?: any;
  presetDate?: any;
  setDate?: any;
  presetDateRange?: any;
  weekNumber?: any;
  disabled?: any;
  dataCy?: any;
};

const DatePicker: React.FunctionComponent<DatePickerType> = ({ label, presetDate, setDate, weekNumber, disabled = false, dataCy }) => {

  const date = presetDate == null ? moment() : presetDate;
  const { t } = useTranslation();
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);

  const [state, setState] = useStates({
    currentYearMonth: moment(date ? date : ""),
    anchorEl: null
  });

  const classes = useStyles({ weekNumber });

  const firstDayInWeek = getUserSetting(userData.userSettings, "customizations", ["calendar", "first_day_in_week"]);
  const weekdays: any = firstDayInWeek === 0 ? moveLastToFirstInArray(weekdaysMin) : weekdaysMin;

  useEffect(() => {
    setState("currentYearMonth", moment(date));
  }, [presetDate, state.anchorEl, date, setState], [presetDate, state.anchorEl]);


  const firstDate = useMemo(() => {
    const startOfMonth = new Date(state.currentYearMonth.year(), state.currentYearMonth.month(), 0);
    return moment(startOfMonth).subtract(startOfMonth.getDay(), 'days');
  }, [state.currentYearMonth]);

  const handleClick = (e: any) => {
    if(!disabled) {
      setState("anchorEl", e.currentTarget);
    }
  };

  const handleClose = () => {
    if(!disabled) {
      setState("anchorEl", null);
    }
  };

  const handleNextMonth = (e: any) => {
    e.stopPropagation();
    setState("currentYearMonth", moment(state.currentYearMonth).add(1, 'months'));
  };

  const handleLastMonth = (e: any) => {
    e.stopPropagation();
    setState("currentYearMonth", moment(state.currentYearMonth).subtract(1, 'months'));
  };
  
  return (
    <>
      <div className={classes.wrapper}>
        {
          label ? (
            <label>
              {label}
            </label>
          ) : null
        }
        <div className={`${classes.datePickerInput} ${disabled ? 'disabled' : null}`} onClick={handleClick} data-cy={isCypress() ? dataCy : null}>
          <p className={classes.selectedDate} data-clarity-unmask="true">
            {
              (presetDate === null || date === null) ? (
                <span style={{color: "#787878"}}>{disabled ? t('not_allowed') : t('select_date')}</span>
              ) : (
                <>{DateFormat(moment(date).toString(),"default", languageData, t)}</>
              )
            }
          </p>
        </div>
      </div>
      <Popover className={classes.calendarWrapper} open={Boolean(state.anchorEl)} anchorEl={state.anchorEl} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} transformOrigin={{vertical: 'top', horizontal: 'right'}}>
        <div className={classes.calendar}>
          <div className={classes.calendarHeader}>
            <p data-clarity-unmask="true">{`${t(months[state.currentYearMonth.month()].toLowerCase())} ${state.currentYearMonth.year()}`}</p>
            <div className={classes.navigator}>
              <IconButton onClick={handleLastMonth} dataCy='prevMonthButton'>
                <SVG src="chevron-left"/>
              </IconButton>
              <IconButton onClick={handleNextMonth} dataCy='nextMonthButton'>
                <SVG src="chevron-right"/>
              </IconButton>
            </div>
          </div>
          <div className={classes.weekdayHeader}>
            {
              weekNumber ? (
                <div className={classes.weekday}>
                  <p>{t("week").substring(0,1)}</p>
                </div>
              ) : null
            }
            {
              weekdays.map((weekday: any, key: any) => (
                <div className={classes.weekday} key={`k_${key}`}>
                  <p data-clarity-unmask="true">{t(weekday.toLowerCase())}</p>
                </div>
              ))
            }
          </div>
          <div className={classes.dateWrapper} data-cy={isCypress() ? 'calendar' : null}>
            {
              new Array(weekNumber ? 48 : 42).fill('').map((_, key) => {
                const itemDate = weekNumber ? moment(firstDate).add(key % 8 === 0 ? key - Math.floor(key / 8) : key - Math.ceil(key / 8), 'days').add(firstDayInWeek, 'day') : moment(firstDate).add(key, 'days').add(firstDayInWeek, 'day');
                return (
                  <DateItem
                    index={key}
                    key={`k_${key}`}
                    date={itemDate}
                    setDate={setDate}
                    currentSelectedDate={date}
                    disabled={disabled}
                    weekNumberMode={weekNumber}
                    handleClose={handleClose}
                  />
                )
              })
            }
          </div>
        </div>
      </Popover>
    </>
  );
};

export default DatePicker;