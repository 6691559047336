import * as AuthService from '../../../services/auth.service';
import CheckboxInput from 'src/components/Forms/CheckboxInput';
import CloseButton from 'src/components/Buttons/CloseButton';
import Countdown from 'react-countdown';
import Input from 'src/components/Forms/Input';
import Modal from '../../../utils/modal';
import NormalButton from 'src/components/Buttons/NormalButton';
import QRCode from 'react-qr-code';
import React, { useCallback, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { formatRelativeSeconds, isKey } from 'src/utils/useFunctions';
import { getAppEnvironmentData } from 'src/utils/useApp';
import { isCypress } from '../../../utils/useCypress';
import { setSSOGetModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: '10px',
    backgroundColor: 'transparent',
    overflow: 'visible',
    margin: '20px auto',
    width: 'fit-content',
    maxWidth: 'calc(100% - 30px)',
    maxHeight: 'calc(100% - 30px)',
    outline: 'none',
  },
  qrWrapper: {
    width: 'auto',
    height: 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  qrCode: {
    position: 'relative',
    '& > svg': {
      padding: '16px',
      outline: 'none',
      maxWidth: '90vw',
      maxHeight: '90vh',
      borderRadius: '10px',
      backgroundColor: theme.colors.white,
    },
    '& > span': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.colors.grey[200],
      color: theme.colors.systemRed[500],
      borderRadius: '100%',
      height: '64px',
      width: '64px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > svg': {
        width: '48px',
        height: '48px',
      },
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  link: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    padding: '12px 24px 12px 24px',
    '& > span': {
      borderRadius: '10px',
      padding: '4px 8px',
      width: 'fit-content',
      maxWidth: 'calc(100% - 16px)',
      backgroundColor: theme.colors.primaryBlue[100],
      color: theme.colors.black,
      fontSize: '12px',
      margin: '0 auto 8px auto',
    },
  },
  input: {
    minWidth: '400px',
  },
  closeButton: {
    position: 'absolute',
    top: '-16px',
    right: '-24px',
    backgroundColor: theme.colors.white,
    zIndex: '2',
    transition: 'background-color 0.25s',
    boxShadow: theme.shadows[2],
    padding: '10px',
    '&:hover': {
      backgroundColor: theme.colors.grey[300],
    },
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  options: {
    display: 'flex',
    gap: '8px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px',
    backgroundColor: theme.colors.white,
    borderRadius: '10px',
    width: 'auto',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    },
  },
}));

const SSOGetModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const modalsData = useAppSelector((state: any) => state.modals);
  const inputRef: any = useRef(null);

  const type = modalsData.ssoGetModal.type;

  const [state, setState] = useStates({
    countdownDate: 0,
    value: '',
    isLoading: true,
    isLoaded: false,
    isExpired: false,
    isForceLogin: false,
    isUpdating: false,
  })
  
  const onCloseModal = useCallback(() => {
    const settings = {
      isOpen: false,
      type: '',
    };
    dispatch(setSSOGetModal(settings));
  }, [dispatch]);

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleGenerateCode = useCallback(() => {
    setState("isLoaded", false);
    setState("isLoading", true);
    setState("isUpdating", true);
    setState("isExpired", false);
    AuthService && AuthService.ssoGetToken("webapp").then((result: any) => {
      if(result && result.data) {
        const ssoToken = result.data.ssoToken;
        setState("value", `${getAppEnvironmentData().url}auth/sso/${ssoToken}`);
        setState("countdownDate", Date.now() + 59000);
        setState("isLoading", false);
        setState("isLoaded", true);
        setState("isUpdating", false);
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("sso_failed_generate_token"), "error");
      onCloseModal();
    });
  }, [setState, t, onCloseModal]);

  const RenderCountDown = ({ seconds, completed }: any) => {
    if(completed) {
      setState("isExpired", true);
      return (
        <NormalButton buttonType="primary" onClick={handleGenerateCode} disabled={state.isLoading}>{type === "qrcode" ? t('sso_generate_qr_code') : t('sso_generate_link')}</NormalButton>
      );
    } else {
      return (
        <span>{t(type === "qrcode" ? 'sso_code_expire' : 'sso_link_expire', {in: formatRelativeSeconds(seconds, t).toLowerCase()})}</span>
      );
    }
  };

  const handleCopyLink = () => {
    if(inputRef) {
      const input = inputRef.current;
      input.select();
      input.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(input.value);
    }
  };

  const handleOpenLink = () => {
    window.open(inputRef.current.value);
    setState("isExpired", true);
  };

  const handleIsForceLogin = () => {
    setState("isUpdating", true);
    setState("isForceLogin", !state.isForceLogin);
    setTimeout(() => {
      setState("isUpdating", false);
    }, 500);
  };

  useEffect(() => {
    handleGenerateCode();
  }, [handleGenerateCode], []);  

  return (
    <Modal
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} daty-cy={isCypress() ? "SSOGetModal" : null}>
        <div className={classes.qrWrapper}>
          {
            state.isLoaded ? (
              <>
                {
                  type === "qrcode" ? (
                    <div className={classes.qrCode}>
                      <QRCode size={256} value={state.value} viewBox={`0 0 256 256`}/>
                      {
                        state.isExpired ? (
                          <span>
                            <SVG src="exclamation-mark-circle"/>
                          </span>
                        ) : null
                      }
                      <CloseButton className={classes.closeButton} onClick={handleClose} dataCy="timesButton"/>
                    </div>
                  ) : (
                    <div className={classes.link}>
                      <div className={classes.header}>
                        <p>{t('sso_one_time_login')}</p>
                        <CloseButton onClick={handleClose} dataCy="timesButton"/> 
                      </div>
                      <Input prepend={state.isUpdating ? (<CircularProgress size={24}/>) : ''} className={classes.input} value={`${state.value}${state.isForceLogin ? '?forceLogin=true' : ''}`} readOnly={true} disabled={state.isExpired} customRefInput={inputRef}/>
                      <div className={classes.options}>
                        <CheckboxInput label={t('sso_force_login')} checked={state.isForceLogin} onChange={handleIsForceLogin} disabled={state.isExpired}/>
                      </div>
                      {
                        !state.isExpired ? (
                          <Countdown date={state.countdownDate} renderer={RenderCountDown}/>
                        ) : (
                          <span>{t('sso_link_expired')}</span>
                        )
                      }
                      <div className={classes.buttons}>
                        {
                          state.isExpired ? (
                            <NormalButton buttonType="primary" onClick={handleGenerateCode} disabled={state.isLoading}>{type === "qrcode" ? t('sso_generate_qr_code') : t('sso_generate_link')}</NormalButton>
                          ) : (
                            <>
                              <NormalButton buttonType='secondary' onClick={handleCopyLink} disabled={state.isExpired}>
                                {t('sso_copy_link')}
                              </NormalButton>
                              <NormalButton buttonType='primary' onClick={handleOpenLink} disabled={state.isExpired}>
                                {t('sso_open_link')}
                              </NormalButton>
                            </>
                          )
                        }
                      </div>
                    </div>
                  )
                }
              </>
            ) : (
              <div className={classes.loading}>
                <CircularProgress className={classes.spinner}/>
              </div>
            )
          }
        </div>
      </div>
    </Modal>
  );
};

export default SSOGetModal;